import { Arrow, TooltipArrowProps } from '@radix-ui/react-tooltip';

export function TooltipArrow(props: TooltipArrowProps) {
  return (
    <Arrow asChild {...props}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 31 11"
        preserveAspectRatio="none"
        className="default-tooltip-arrow"
      >
        <polygon points="-1,1 31,1 15,11" fill="#FFFFFF40" strokeWidth="1" />
        <polygon points="0,0 30,0 15,10" fill="currentColor" />
      </svg>
    </Arrow>
  );
}
