import { create } from 'zustand';
import { IBulkOrder } from '../../../types/apiTypes';

export interface AdvancedProgressStore {
  currentOrder?: IBulkOrder;
  setCurrentOrder: (order: IBulkOrder | undefined) => void;

  isWaitingForConfirmation: boolean;
  setIsWaitingForConfirmation: (isWaitingForConfirmation: boolean) => void;

  isCanceled: boolean;
  setIsCanceled: (isCanceled: boolean) => void;

  reset: () => void;
}

export const advancedProgressStore = create<AdvancedProgressStore>(set => ({
  currentOrder: undefined,
  setCurrentOrder: order => set({ currentOrder: order }),

  isWaitingForConfirmation: false,
  setIsWaitingForConfirmation: isWaitingForConfirmation =>
    set({ isWaitingForConfirmation }),

  isCanceled: false,
  setIsCanceled: isCanceled => set({ isCanceled }),

  reset: () => set({ currentOrder: undefined, isCanceled: false }),
}));

export const useAdvancedProgressStore = advancedProgressStore;
