import { useEffect, useRef } from 'react';
import { useConnect } from '@starknet-react/core';
import { useStarknetAccount } from '@/starknet/hooks/account';

export const useEagerStarknetConnect = () => {
  const { connectors, connectAsync } = useConnect();
  const { isConnected } = useStarknetAccount();
  const tried = useRef(false);

  useEffect(() => {
    const provider = sessionStorage.getItem('starknet-provider');
    const connector = connectors.find(item => item.id === provider);
    if (connector && !tried.current) {
      tried.current = true;
      const isAvail = connector.available();
      if (isAvail) {
        connectAsync({ connector }).catch(() => (tried.current = true));
      } else {
        tried.current = true;
      }
    }
  }, [connectors]);

  useEffect(() => {
    if (!tried.current && isConnected) {
      tried.current = true;
    }
  }, [isConnected]);

  return tried.current;
};
