import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  Root,
} from '@radix-ui/react-dialog';
import './styles.css';
import twitter from '@/Assets/Icons/twitter2.png';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import { useWindowSize } from 'usehooks-ts';

interface WelcomeDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  points: number;
}

export function WelcomeDialog({
  points,
  open,
  onOpenChange,
}: WelcomeDialogProps) {
  const { width } = useWindowSize();

  return (
    <Root open={open} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogOverlay className="retro-points-dialog--overlay" />
        <DialogContent className="retro-points-dialog">
          <div className="retro-points-dialog--badge">
            WELCOME TO RETRO POINTS
          </div>
          <div className="retro-points-dialog--heading">
            You have <br />
            <RetroBridgerPointsIcon
              width={width > 768 ? 47 : 35}
              height={width > 768 ? 25 : 18}
              className="align-baseline"
            />{' '}
            {points} Retro Points
          </div>
          <div className="retro-points-dialog--text">
            You already have {points} Retro Points for your activity on
            RetroBridge. Make a Retweet of Retro Points launch to claim your
            rewards.
          </div>
          <a
            href="https://twitter.com/intent/retweet?tweet_id=1844342416670593133"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => onOpenChange(false)}
            className="btnGradient retro-points-dialog--action"
          >
            Retweet on{' '}
            <img src={twitter} width={14} height={14} alt="Twitter" />
          </a>
        </DialogContent>
      </DialogPortal>
    </Root>
  );
}
