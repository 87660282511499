import { SVGAttributes } from 'react';

export function PowerIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.71 3.87332C11.444 4.60762 11.9439 5.54308 12.1463 6.56143C12.3488 7.57979 12.2447 8.6353 11.8473 9.59451C11.4499 10.5537 10.777 11.3736 9.91364 11.9504C9.05032 12.5272 8.03535 12.835 6.99707 12.835C5.95879 12.835 4.94383 12.5272 4.08051 11.9504C3.21719 11.3736 2.54428 10.5537 2.14687 9.59451C1.74946 8.6353 1.64539 7.57979 1.84782 6.56143C2.05026 5.54308 2.5501 4.60762 3.28416 3.87332"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 1.16666V6.99999"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
