import { SVGAttributes, useId } from 'react';

export function RetroBridgerPointsIcon({
  color,
  ...props
}: SVGAttributes<SVGElement>) {
  const id = useId();
  const filterId = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="15"
      viewBox="0 0 29 15"
      fill="none"
      {...props}
    >
      <g filter={!color ? `url(#${filterId})` : undefined}>
        <path
          d="M26.0045 14.9167C27.1985 14.9167 28.1835 13.9432 28.0009 12.7632C27.8294 11.6548 27.5257 10.5683 27.0945 9.52732C26.3867 7.81866 25.3494 6.26614 24.0416 4.95838C22.7339 3.65063 21.1813 2.61327 19.4727 1.90552C17.764 1.19777 15.9327 0.833496 14.0833 0.833496C12.2338 0.833496 10.4025 1.19777 8.69382 1.90552C6.98517 2.61327 5.43264 3.65063 4.12489 4.95839C2.81714 6.26614 1.77977 7.81866 1.07202 9.52732C0.640838 10.5683 0.33714 11.6548 0.165631 12.7632C-0.0169604 13.9432 0.967972 14.9167 2.16203 14.9167C3.35608 14.9167 4.30019 13.9372 4.56256 12.7724C4.68469 12.2301 4.85321 11.698 5.06693 11.1821C5.55737 9.99802 6.27623 8.92218 7.18245 8.01595C8.08868 7.10973 9.16453 6.39087 10.3486 5.90042C11.5326 5.40998 12.8017 5.15755 14.0833 5.15755C15.3648 5.15755 16.6339 5.40998 17.8179 5.90042C19.002 6.39087 20.0778 7.10972 20.984 8.01595C21.8903 8.92218 22.6091 9.99802 23.0996 11.1821C23.3133 11.698 23.4818 12.2301 23.6039 12.7724C23.8663 13.9372 24.8104 14.9167 26.0045 14.9167Z"
          fill={color ? color : `url(#${id})`}
        />
      </g>
      <defs>
        <filter
          id={filterId}
          x="0.143555"
          y="0.0157588"
          width="27.8789"
          height="15.9436"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.22661" />
          <feGaussianBlur stdDeviation="0.521307" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.76 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_12541_2483"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.908597" />
          <feGaussianBlur stdDeviation="0.408869" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.581661 0 0 0 0 0.465478 0 0 0 0 0.828548 0 0 0 0.61 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_12541_2483"
            result="effect2_innerShadow_12541_2483"
          />
        </filter>
        <linearGradient
          id={id}
          x1="4.12369e-07"
          y1="9.98761"
          x2="28.1665"
          y2="12.1001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#927FFF" />
          <stop offset="0.54" stopColor="#EDBAFF" />
          <stop offset="1" stopColor="#F2BCFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
