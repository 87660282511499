import { SVGAttributes } from 'react';

export function TriangleDown(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M7 9.33325L3.96891 5.83325L10.0311 5.83325L7 9.33325Z"
        fill="white"
      />
    </svg>
  );
}
