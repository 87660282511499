import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setCookie } from '../../utils/cookies';
import { stage } from '../../Config/ApiConfig';

export const InitialUserApiKey = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) return;

    const apiKey = searchParams.get('utm_source');

    if (apiKey) {
      if (stage === 'production') {
        window.gtag('event', 'page_view', {
          page_location: window.location.href,
          track_id: apiKey, // Send the track_id as a custom parameter
        });
      }
      setCookie('api_key', apiKey);
      setSearchParams(params => {
        params.delete('utm_source');
        return params;
      });
    }

    return () => {
      isFirstRender.current = false;
    };
  }, [searchParams]);

  return null;
};
