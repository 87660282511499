import { useTimeDifference } from '@/hooks/useTimeDifference';
import { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import clsx from 'clsx';

interface PhaseItemProps {
  children?: ReactNode;
  href?: string;
  target?: string;
  thumbnailSrc?: string;
  backgroundSrc?: string;
  startDate?: string;
  endDate?: string;
}

export function PhaseItem({
  children,
  href,
  target,
  thumbnailSrc,
  backgroundSrc,
  startDate,
  endDate,
}: PhaseItemProps) {
  const timeToEnd = useTimeDifference(
    startDate && endDate ? new Date(endDate).getTime() : 0,
    !!startDate && !!endDate
  );

  const endsIn = useMemo(() => {
    if (timeToEnd.days > 0) {
      return `${timeToEnd.days}d ${timeToEnd.hours}h`;
    }
    if (timeToEnd.hours > 0) {
      return `${timeToEnd.hours}h ${timeToEnd.minutes}m`;
    }
    if (+timeToEnd.minutes > 0) {
      return `${+timeToEnd.minutes}m`;
    }
    return 'less than a minute';
  }, [timeToEnd.days, timeToEnd.hours, timeToEnd.minutes]);

  const timeProgress = useMemo(() => {
    if (!endDate || !startDate) {
      return 0;
    }

    const ADVENTURE_DURATION =
      new Date(endDate).getTime() - new Date(startDate).getTime();

    const currentProgress = new Date(endDate).getTime() - Date.now();
    const totalProgress = ADVENTURE_DURATION;
    return Math.max(100 - (currentProgress / totalProgress) * 100, 0);
  }, [timeToEnd.minutes]);

  return (
    <Link
      to={href ? href : '#'}
      target={target}
      className={clsx(
        'notifications-menu--journey-item',
        !href && 'cursor-default'
      )}
      style={{
        backgroundImage: `url(${backgroundSrc})`,
      }}
    >
      <div className="notifications-menu--journey-item--content">
        {children}
        {startDate && endDate && (
          <div className="notifications-menu--journey-item--progress">
            <div>Ends in {endsIn}</div>
            <div className="notifications-menu--journey-item--progress-bar">
              <div
                className="notifications-menu--journey-item--progress-bar--fill"
                style={{ width: `${timeProgress}%` }}
              />
            </div>
          </div>
        )}
      </div>
      {thumbnailSrc && (
        <div className="notifications-menu--journey-item--thumbnail">
          <img src={thumbnailSrc} draggable={false} alt="" />
        </div>
      )}
    </Link>
  );
}
