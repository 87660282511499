import { mainnetNetworks } from '@/constants/mock_networks.constants';
import { useNetworksStore } from '@/stores/networks.store';
import dayjs from 'dayjs';
import { intersectionBy } from 'lodash';
import { useMemo } from 'react';
import { NewDestinationItem } from '../NewDestinationItem';

export function NewDestinationsTab() {
  const [networks] = useNetworksStore(s => [s.networks]);

  const filteredNetworks = useMemo(() => {
    const newNetworks = mainnetNetworks.filter(
      network => !!network.is_new_destination
    );

    return intersectionBy(networks, newNetworks, 'chainId').sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, [networks]);

  return (
    <div className="notifications-menu--group">
      <div className="notifications-menu--group-heading">New Destinations</div>
      {filteredNetworks.map(network => (
        <NewDestinationItem key={network.id} chainId={network.chainId}>
          {dayjs(network?.created_at).isAfter(dayjs()) ? (
            <>
              Bridging to {network.name} will be available{' '}
              <span className="text-nowrap">next week on RetroBridge</span>
            </>
          ) : (
            <>
              Bridging to {network.name} is now available{' '}
              <span className="text-nowrap">on RetroBridge</span>
            </>
          )}
        </NewDestinationItem>
      ))}
    </div>
  );
}
