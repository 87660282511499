import { ArrowRight } from '@/Assets/Icons/ArrowRight';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import { ROUTES } from '@/constants/routes.constants';
import { PhaseItem } from '../JourneyItem';
import RetroPointsBg1 from '../assets/RetroPointsBg1.png';
import RetroPointsBg2 from '../assets/RetroPointsBg2.png';
import RetroPointsBg3 from '../assets/RetroPointsBg3.png';
import './styles.css';

interface PhasesBlockProps {
  showSunrise?: boolean;
}

export function PhasesBlock({ showSunrise = true }: PhasesBlockProps) {
  return (
    <div className="notifications-menu--group">
      <div className="notifications-menu--group-heading">RetroPoints</div>
      <PhaseItem
        href={ROUTES.QUESTS_PAGE_ACTIVITIES}
        target="_blank"
        backgroundSrc={RetroPointsBg1}
      >
        <div>
          Claim <span className="text-gradient">Free</span>{' '}
          <RetroBridgerPointsIcon
            width={24}
            height={16}
            className="align-top"
          />{' '}
          Retro Points by playing RetroBridger Game
        </div>
        <button className="quests-banner--action mt-2">
          Play now <ArrowRight color="#F7C9FF" />
        </button>
      </PhaseItem>
      <PhaseItem
        href={ROUTES.QUESTS_PAGE_PHASES}
        target="_blank"
        backgroundSrc={RetroPointsBg2}
      >
        Increase your <strong className="text-gradient">Multiplier (X)</strong>{' '}
        by completing Phases, Adventures and Increasing your RetroBridger Level
      </PhaseItem>
      <PhaseItem backgroundSrc={RetroPointsBg3}>
        The Retro Bridge fee is now a flat{' '}
        <strong
          className="text-gradient"
          style={{
            backgroundImage: 'linear-gradient(90deg, #B19EFF 0%, #F7C9FF 100%)',
          }}
        >
          $1.99
        </strong>{' '}
        for every transaction across all networks
      </PhaseItem>
    </div>
  );
}
