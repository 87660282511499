import { SVGAttributes, useId } from 'react';

export function RocketGradientIcon(props: SVGAttributes<SVGElement>) {
  const id = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M4.141 8.88008C3.92707 8.66624 3.84651 8.77369 3.79893 8.82131C2.56707 10.0532 1.47344 13.0255 2.05159 12.7352C4.15631 11.6784 4.507 11.993 5.73882 10.7612C5.78644 10.7136 5.89595 10.6351 5.6758 10.4149L4.141 8.88008Z"
          fill="url(#paint0_linear_17077_77642)"
        />
        <path
          d="M10.782 8.86359C10.7262 8.68593 10.8431 8.60032 10.8924 8.54719C13.6112 5.61186 15.3392 1.70108 14.0587 0.420493C12.7849 -0.85319 8.99471 0.925238 6.06019 3.62447C6.00213 3.67794 5.8877 3.7792 5.68299 3.73646L4.76871 3.51875C4.51486 3.45838 4.15618 3.55986 3.97167 3.74437L0.616867 7.09913C0.432352 7.28368 0.492301 7.46782 0.7501 7.50829L3.17652 7.88961C3.43432 7.93016 3.7962 7.81233 3.98067 7.62777C3.98067 7.62777 4.10587 7.50223 4.23165 7.62798C4.92562 8.32187 6.21521 9.61145 6.87637 10.2727C7.00779 10.404 6.88192 10.529 6.88192 10.529C6.69736 10.7136 6.57957 11.0754 6.62012 11.3332L7.0014 13.7596C7.04191 14.0175 7.22605 14.0774 7.41056 13.8929L10.7654 10.538C10.9499 10.3535 11.0514 9.99491 10.9909 9.74107L10.782 8.86359ZM10.0309 4.52942C9.46269 3.96119 9.46269 3.04001 10.0309 2.47187C10.5991 1.90364 11.5203 1.90364 12.0886 2.47187C12.6567 3.03997 12.6567 3.96119 12.0886 4.52938C11.5203 5.09761 10.5991 5.09761 10.0309 4.52942Z"
          fill="url(#paint1_linear_17077_77642)"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <linearGradient
          id="paint0_linear_17077_77642"
          x1="1.04448"
          y1="12.6677"
          x2="3.52193"
          y2="16.519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAD0FF" />
          <stop offset="1" stopColor="#B297FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17077_77642"
          x1="4.50278"
          y1="1.64979"
          x2="13.7014"
          y2="15.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAD0FF" />
          <stop offset="1" stopColor="#B297FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
