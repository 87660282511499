import { create, useStore } from 'zustand';
import {
  IBulkOrderWithType,
  ICurrency,
  IOrder,
  ITransactionFee,
} from '../types/apiTypes';

export type WalletStore = {
  skip: number;
  /**
   * @deprecated
   */
  receiveAmount: string;
  receiverWallet: string;
  currentOrder: IOrder | null;
  orders: IOrder[];
  setOrders: (orders: IOrder[]) => void;
  setReceiverWallet: (receiverWallet: string) => void;
  address: string;
  setCurrentOrder: (currentOrder: IOrder) => void;
  setSkip: (skip: number) => void;
  currencyPair: ICurrency[];
  setCurrencyPair: (currencyPair: ICurrency[]) => void;
  clearStore: () => void;

  allCurrencyPairs: ICurrency[];
  setAllCurrencyPairs: (currencyPairs: ICurrency[]) => void;

  transactionCurrencyFee: ITransactionFee | null;
  settransactionCurrencyFee: (value: ITransactionFee | null) => void;

  ongoingOrders: (IOrder | IBulkOrderWithType)[];
  addOngoingOrder: (
    order: IOrder | IBulkOrderWithType,
    shouldUpdate?: boolean
  ) => void;
  setOngoingOrder: (
    orderId: IOrder['id'] | IBulkOrderWithType['id'],
    order: IOrder | IBulkOrderWithType | undefined
  ) => void;
  clearOngoingOrder: () => void;
  setOngoingOrders: (orders: (IOrder | IBulkOrderWithType)[]) => void;

  NFTDiscount: {
    nftName: string;
    networkFee: number;
  } | null;

  setNFTDiscount: (nftDiscount: WalletStore['NFTDiscount']) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const CLEARED_STORE_VALUES = {
  amount: '',
  address: '',
  currentOrder: null,
  currencyFromId: '',
  currencyFromList: [],
  currencyPair: [],
  loading: false,
  networkFrom: '',
  networkTo: '',
  networksList: [],
  privKey: '',
  receiveAmount: '',
  skip: 0,
  transactionToken: null,
  walletTo: '',
  receiverWallet: '',
  orders: [],
  canceled: false,
  transactionCurrencyFee: null,
  allCurrencyPairs: [],
};

export const walletStore = create<WalletStore>()((set, get) => ({
  address: '',
  currentOrder: null,
  currencyPair: [],
  receiveAmount: '',
  skip: 0,
  receiverWallet: '',
  orders: [],
  transactionCurrencyFee: null,
  allCurrencyPairs: [],
  ongoingOrders: [],
  NFTDiscount: null,
  settransactionCurrencyFee: (value: ITransactionFee | null) =>
    set({ transactionCurrencyFee: value }),
  setOrders: orders => set({ orders }),
  setCurrentOrder: (currentOrder: IOrder) => set({ currentOrder }),
  setReceiverWallet: (receiverWallet: string) => set({ receiverWallet }),
  setCurrencyPair: currencyPair => set({ currencyPair }),
  setSkip: (skip: number) => set({ skip }),
  setReceiveAmount: (receiveAmount: string) => set({ receiveAmount }),
  clearStore: () => set(CLEARED_STORE_VALUES),
  setAllCurrencyPairs: (currencyPairs: ICurrency[]) =>
    set({ allCurrencyPairs: currencyPairs }),
  addOngoingOrder: (order, shouldUpdate = false) => {
    const orderFromStore = get().ongoingOrders.find(o => o.id === order.id);

    if (orderFromStore) {
      if (!shouldUpdate) return;

      get().setOngoingOrder(order.id, order);
      return;
    }

    const res = [order, ...get().ongoingOrders];
    set({
      ongoingOrders: res,
    });
  },
  setOngoingOrder: (orderId, order) => {
    const orders = get().ongoingOrders;

    if (!order) {
      const res = orders.filter(o => o.id !== orderId);
      set({ ongoingOrders: res });
    } else {
      const res = orders.map(o => (o.id === orderId ? order : o));
      set({ ongoingOrders: res });
    }
  },
  clearOngoingOrder: () => {
    set({ ongoingOrders: [] });
  },
  setOngoingOrders: orders => {
    set({ ongoingOrders: orders });
  },

  setNFTDiscount: nftDiscount => set({ NFTDiscount: nftDiscount }),

  isLoading: false,
  setIsLoading: isLoading => set({ isLoading }),
}));

export const useWalletStore = () => useStore(walletStore);
