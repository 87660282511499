import { NotificationIcon } from '@/Assets/Icons/Notification';
import { NotificationOutlinedIcon } from '@/Assets/Icons/NotificationOutlined';
import { NetworkTypes } from '@/providers/web3Provider';
import { retroPointsService } from '@/services/retroPoints';
import { useAppStore } from '@/stores/app.store';
import { useAuthStore } from '@/stores/auth.store';
import {
  PopoverContent,
  PopoverPortal,
  Root as PopoverRoot,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import {
  TabsContent,
  TabsList,
  Root as TabsRoot,
  TabsTrigger,
} from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { useWindowSize } from 'usehooks-ts';
import { Profile } from './Profile';
import './styles.css';
import { ActivitiesTab } from './tabs/ActivitiesTab';
import { NewDestinationsTab } from './tabs/NewDestinationsTab';

export enum NotificationTab {
  Activities = 'Activities',
  NewDestinations = 'New Destinations',
}

export function NotificationsPopover() {
  const { width } = useWindowSize();
  const [selectedTab, setSelectedTab] = useState(NotificationTab.Activities);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdvancedOpen] = useAppStore(s => [s.isAdvancedOpen]);
  const { pathname } = useLocation();
  const evmToken = useAuthStore(s => s.tokens?.[NetworkTypes.EVM]);

  useEffect(() => {
    if (width && width <= 993) {
      handleOpenChange(false);
    } else {
      handleOpenChange(
        !isAdvancedOpen && !pathname.startsWith('/progress/advanced')
      );
    }
  }, [isAdvancedOpen, pathname, width]);

  useEffect(() => {
    if (!evmToken) {
      return;
    }
    retroPointsService.userInfo();
  }, [evmToken]);

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);

    if (!isOpen) {
      setSelectedTab(NotificationTab.Activities);
    }
  };

  return (
    <TabsRoot
      value={selectedTab}
      onValueChange={tab => setSelectedTab(tab as NotificationTab)}
    >
      <PopoverRoot open={isOpen} onOpenChange={handleOpenChange}>
        <PopoverTrigger className="notifications-menu--trigger">
          {isOpen ? <NotificationIcon /> : <NotificationOutlinedIcon />}
        </PopoverTrigger>
        <AnimatePresence initial={false}>
          {isOpen && (
            <PopoverPortal forceMount>
              <PopoverContent
                style={{ maxHeight: 'calc(100vh - 184px)' }}
                align="start"
                collisionPadding={18}
                sideOffset={47}
                asChild
                onInteractOutside={e => {
                  e.preventDefault();

                  const walletPopovers =
                    document.querySelectorAll('.wallet-popover');

                  if (
                    e.target &&
                    Array.from(walletPopovers).some(el =>
                      el.contains(e.target as Node)
                    )
                  ) {
                    handleOpenChange(false);
                  }
                }}
                onOpenAutoFocus={e => e.preventDefault()}
              >
                <div className="notifications-menu">
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    className="notifications-menu-top"
                  >
                    <Profile />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    className="notifications-menu-body"
                  >
                    <TabsList
                      className={clsx(
                        'notifications-menu--tabs',
                        selectedTab === NotificationTab.Activities && '_01',
                        selectedTab === NotificationTab.NewDestinations && '_02'
                      )}
                    >
                      <TabsTrigger
                        className="notifications-menu--tabs-trigger"
                        value={NotificationTab.Activities}
                      >
                        {NotificationTab.Activities}
                      </TabsTrigger>
                      <TabsTrigger
                        className="notifications-menu--tabs-trigger"
                        value={NotificationTab.NewDestinations}
                      >
                        {NotificationTab.NewDestinations}
                      </TabsTrigger>
                    </TabsList>
                    <SimpleBar
                      style={{
                        height: '100%',
                        maxHeight: 'calc(100vh - 482px)',
                        outline: 'none',
                        paddingRight: '10px',
                        marginRight: '2px',
                      }}
                    >
                      <TabsContent value={NotificationTab.Activities}>
                        <ActivitiesTab />
                      </TabsContent>
                      <TabsContent value={NotificationTab.NewDestinations}>
                        <NewDestinationsTab />
                      </TabsContent>
                    </SimpleBar>
                  </motion.div>
                </div>
              </PopoverContent>
            </PopoverPortal>
          )}
        </AnimatePresence>
      </PopoverRoot>
    </TabsRoot>
  );
}
