import { SVGAttributes } from 'react';

export function Progress(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M7.00027 3.91486C6.43189 3.91486 5.9707 3.45408 5.9707 2.88531V1.02979C5.9707 0.461422 6.43149 0.000244141 7.00027 0.000244141C7.56906 0.000244141 8.02984 0.461022 8.02984 1.02979V2.88531C8.02984 3.45368 7.56906 3.91486 7.00027 3.91486Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M9.91229 5.11787C9.64869 5.11787 9.3855 5.01748 9.1843 4.81629C8.78232 4.41431 8.78232 3.76274 9.1843 3.36036L10.4947 2.05002C10.8967 1.64804 11.5482 1.64804 11.9506 2.05002C12.3526 2.452 12.3526 3.10397 11.9506 3.50595L10.6403 4.81629C10.4391 5.01708 10.1759 5.11787 9.91229 5.11787Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M12.9702 8.02932H11.1175C10.5491 8.02932 10.0879 7.56854 10.0879 6.99977C10.0879 6.43099 10.5487 5.97021 11.1175 5.97021H12.9702C13.5386 5.97021 13.9998 6.43099 13.9998 6.99977C13.9998 7.56854 13.539 8.02932 12.9702 8.02932Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M11.2226 12.2512C10.9591 12.2512 10.6959 12.1508 10.4947 11.9496L9.1843 10.6393C8.78232 10.2373 8.78232 9.5853 9.1843 9.18332C9.58629 8.78134 10.2379 8.78134 10.6403 9.18332L11.9506 10.4937C12.3526 10.8956 12.3526 11.5472 11.9506 11.9496C11.7494 12.1504 11.4862 12.2512 11.2226 12.2512Z"
        fill="currentColor"
      />
      <path
        opacity="0.6"
        d="M7.00027 13.9999C6.43189 13.9999 5.9707 13.5392 5.9707 12.9704V11.1177C5.9707 10.5493 6.43149 10.0881 7.00027 10.0881C7.56906 10.0881 8.02984 10.5489 8.02984 11.1177V12.9704C8.02984 13.5388 7.56906 13.9999 7.00027 13.9999Z"
        fill="currentColor"
      />
      <path
        opacity="0.7"
        d="M2.77752 12.2512C2.51392 12.2512 2.25073 12.1508 2.04954 11.9496C1.64755 11.5477 1.64755 10.8957 2.04954 10.4937L3.3619 9.18137C3.76389 8.77939 4.41547 8.77939 4.81746 9.18137C5.21945 9.58335 5.21945 10.2353 4.81746 10.6373L3.5051 11.9496C3.3039 12.1504 3.04071 12.2512 2.77712 12.2512H2.77752Z"
        fill="currentColor"
      />
      <path
        opacity="0.8"
        d="M2.88232 8.02932H1.02957C0.461186 8.02932 0 7.56854 0 6.99977C0 6.43099 0.460786 5.97021 1.02957 5.97021H2.88232C3.4507 5.97021 3.91188 6.43099 3.91188 6.99977C3.91188 7.56854 3.4511 8.02932 2.88232 8.02932Z"
        fill="currentColor"
      />
      <path
        opacity="0.85"
        d="M4.08788 5.11787C3.82429 5.11787 3.56109 5.01748 3.3599 4.81629L2.04954 3.50595C1.64755 3.10397 1.64755 2.452 2.04954 2.05002C2.45153 1.64804 3.10311 1.64804 3.5055 2.05002L4.81586 3.36036C5.21784 3.76234 5.21784 4.41391 4.81586 4.81629C4.61466 5.01708 4.35147 5.11787 4.08788 5.11787Z"
        fill="currentColor"
      />
    </svg>
  );
}
