import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes.constants';
import './styles.css';

export function MobileMenuNetworkSwitch() {
  return (
    <div className="mobile-menu--network-tabs mainnet">
      <Link to="" className="mobile-menu--network-tabs-item">
        Mainnet
      </Link>
      <Link
        to={ROUTES.TESTNET_SEND_PAGE}
        className="mobile-menu--network-tabs-item"
      >
        Testnet
      </Link>
    </div>
  );
}
