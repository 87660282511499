import discord from '@/Assets/Icons/discord.svg';
import plusOutline from '@/Assets/Icons/plus-outline.svg';
import smallWhiteLoader from '@/Assets/Icons/smallWhiteLoader.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { Loader } from '@/components/Loader';
import { HOME_PAGE, ROUTES } from '@/constants/routes.constants';
import { MINUTE } from '@/constants/time';
import { api } from '@/services/api';
import { phaseService } from '@/services/phaseApi';
import { useAppStore } from '@/stores/app.store';
import { usePhaseBannerStore } from '@/stores/phaseBanner.store';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { useWalletStore } from '@/stores/wallet.store';
import { OrderStatuses, OrderType } from '@/types/enums';
import { exponentNumberToString } from '@/utils/numbers';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './ProgressPage.css';
import { LoadingCircles } from './components/LoadingCircles';
import { NetworkLoaderItem } from './components/NetworkLoaderItem';
import StatusForm from './components/StatusForm';
import { DAppBanner } from './components/StatusForm/components/DAppBanner';
import { useAccount as useEVMAccount } from 'wagmi';
import { Phase } from '@/types/phases';
import { retroPointsService } from '@/services/retroPoints';

const exhaustiveCheck = (param: never | undefined) => {
  throw new Error(`Unhandled param: ${param}`);
};

function ProgressForm() {
  const [isSourceAnimationFinished, setIsSourceAnimationFinished] =
    useState(false);
  const [isTransitionAnimationFinished, setIsTransitionAnimationFinished] =
    useState(false);
  const [isDestinationAnimationFinished, setIsDestinationAnimationFinished] =
    useState(false);

  const { address: EVMAddress } = useEVMAccount();
  const { currentOrder, setCurrentOrder } = useWalletStore();
  const [
    isCancelled,
    isWaitingForConfirmation,
    setIsCancelled,
    isDisabled,
    setIsWaitingForConfirmation,
  ] = useAppStore(s => [
    s.isCancelled,
    s.isWaitingForConfirmation,
    s.setIsCancelled,
    s.isDisabled,
    s.setIsWaitingForConfirmation,
  ]);

  const [isLoading] = usePhaseBannerStore(s => [s.isLoading]);

  const { prices } = usePriceUpdater();

  const { orderId } = useParams();
  const navigate = useNavigate();

  const status = currentOrder?.status;

  const statusClass = useMemo(() => {
    if (!status) return 'unknown';
    switch (status) {
      case OrderStatuses.PENDING:
      case OrderStatuses.WAIT_DEPOSIT:
      case OrderStatuses.REJECTED:
      case OrderStatuses.EXPIRED:
      case OrderStatuses.DEPOSIT_FAILED:
        return 'transY0';
      case OrderStatuses.DEPOSITED:
        return 'trans50';
      case OrderStatuses.SEND_FAILED:
      case OrderStatuses.SENDING:
      case OrderStatuses.SENT:
      case OrderStatuses.COMPLETE:
        return 'transY100';
      default:
        return exhaustiveCheck(status);
    }
  }, [status]);

  const [hasDelay, setHasDelay] = useState(false);

  useEffect(() => {
    if (isDisabled) return;

    setHasDelay(false);

    const delay = setTimeout(() => {
      setHasDelay(true);
    }, 3 * MINUTE);

    return () => clearTimeout(delay);
  }, [statusClass, isDisabled]);

  const isDetectionDelay =
    hasDelay &&
    statusClass === 'transY0' &&
    status !== OrderStatuses.REJECTED &&
    status !== OrderStatuses.EXPIRED &&
    status !== OrderStatuses.DEPOSIT_FAILED;

  const isTransferDelay = hasDelay && statusClass === 'transY100';

  useEffect(() => {
    if (orderId) {
      api.getOrderById(orderId).then(data => {
        if (!data) {
          navigate(HOME_PAGE);
          clearInterval(id);
          setIsCancelled(false);
          return;
        }

        setCurrentOrder(data);

        if (
          data?.status === OrderStatuses.COMPLETE ||
          data?.status === OrderStatuses.REJECTED ||
          data?.status === OrderStatuses.EXPIRED
        ) {
          clearInterval(id);
          setIsWaitingForConfirmation(false);
          setIsDestinationAnimationFinished(true);
          setIsCancelled(false);
          retroPointsService.userInfo();
        }
      });

      const id = setInterval(async () => {
        const data = await api.getOrderById(orderId);
        if (
          data?.status !== OrderStatuses.COMPLETE &&
          data?.status !== OrderStatuses.REJECTED &&
          data?.status !== OrderStatuses.EXPIRED &&
          data?.status !== OrderStatuses.DEPOSIT_FAILED
        ) {
          if (data) {
            setCurrentOrder(data);
          }
        } else {
          setIsWaitingForConfirmation(false);
          setIsCancelled(false);
          retroPointsService.userInfo();
        }
      }, 2000);

      return () => clearInterval(id);
    } else {
      navigate(HOME_PAGE);
    }
  }, [orderId, navigate, setCurrentOrder]);

  const amountInUsd = useMemo(() => {
    if (!currentOrder) {
      return 0;
    }
    return (
      getPrice(currentOrder.currency_in.symbol, prices).usd *
      currentOrder.amount_in
    );
  }, [currentOrder, prices]);

  const amountOutUsd = useMemo(() => {
    if (!currentOrder) {
      return 0;
    }
    return (
      getPrice(currentOrder.currency_out.symbol, prices).usd *
      currentOrder.amount_out
    );
  }, [currentOrder, prices]);

  useEffect(() => {
    if (
      !EVMAddress ||
      !currentOrder?.currency_out.symbol ||
      currentOrder.status !== OrderStatuses.COMPLETE
    )
      return;

    let phase = Phase.Sunrise;

    if (currentOrder?.type === OrderType.SWAP) {
      phase = Phase.Midday;
    }

    phaseService.getPhaseBannerContent(
      EVMAddress,
      currentOrder?.currency_out.symbol,
      phase
    );
  }, [
    EVMAddress,
    currentOrder?.status,
    currentOrder?.currency_out.symbol,
    currentOrder?.type,
  ]);

  if (!currentOrder || isLoading) {
    return (
      <div className="formBg defaultRadius formContainer gradient-border-mask mt-0 center-container">
        <Form className="transitionHeight formBody text-light bg-opacity-25 h-100 defaultRadius align-items-center">
          <div className="transactionInfoDableDiv">
            <Loader />
          </div>
        </Form>
      </div>
    );
  }

  if (
    (currentOrder.status === OrderStatuses.COMPLETE &&
      isDestinationAnimationFinished) ||
    currentOrder.status === OrderStatuses.REJECTED ||
    currentOrder.status === OrderStatuses.EXPIRED ||
    currentOrder.status === OrderStatuses.DEPOSIT_FAILED ||
    isCancelled
  ) {
    return <StatusForm />;
  }

  return (
    <div className="formBg defaultRadius formContainer gradient-border-mask mt-0">
      <Form className="transitionHeight progressFormBody text-light bg-opacity-25 h-100 defaultRadius align-items-center">
        <div className="transferInfoTitle">
          {isWaitingForConfirmation
            ? 'Awaiting transfer confirmation'
            : 'Transfer Progress'}
        </div>

        {isWaitingForConfirmation ? (
          <p className="transactionConfirmationDescription">
            Please, confirm this transaction on your wallet..
          </p>
        ) : (
          <p className="transfer-expected-brigde-time">
            <span>Expected time to bridge:</span>
            <span>~ 1 min</span>
          </p>
        )}

        <div
          className={`transferInfoWrapper ${
            isWaitingForConfirmation ? 'transferInfoWrapper__loading' : ''
          }`}
        >
          <div className="transferInfoContent">
            <div className="transferInfoItem">
              <NetworkLoaderItem
                isPaused={isWaitingForConfirmation}
                status={
                  isDetectionDelay
                    ? 'delay'
                    : statusClass === 'transY0'
                      ? 'progress'
                      : 'filled'
                }
                networkSrc={
                  BASE_URL +
                  currentOrder.currency_in.contract.network.network_image_url
                }
                network={currentOrder.currency_in.contract.network.name ?? ''}
                wallet={currentOrder.wallet_sender!}
                amount={`${currentOrder.amount_in}`}
                amountUsd={`${amountInUsd}`}
                coinSymbol={currentOrder.currency_in.symbol ?? ''}
                imgSrc={BASE_URL + currentOrder.currency_in.image_url}
                withoutLoader={isWaitingForConfirmation}
                blockScan={
                  currentOrder.currency_in.contract.network.blockscan_url
                }
                transaction={currentOrder.transaction_in}
                duration={5}
                onFinish={() => setIsSourceAnimationFinished(true)}
              />
            </div>

            {isWaitingForConfirmation ? (
              <div className="transactionAwaitingLoaderWrapper">
                <div className="transactionAwaitingLoader">
                  <img src={smallWhiteLoader} alt="" />
                </div>
              </div>
            ) : (
              <div className="loadingCirclesWrapper">
                <LoadingCircles
                  isPaused={!isSourceAnimationFinished}
                  status={
                    statusClass === 'transY0'
                      ? 'progress'
                      : statusClass === 'trans50'
                        ? 'progress'
                        : 'filled'
                  }
                  onFinish={() => setIsTransitionAnimationFinished(true)}
                />
              </div>
            )}

            <div className="transferInfoItem">
              <NetworkLoaderItem
                isPaused={
                  !isSourceAnimationFinished || !isTransitionAnimationFinished
                }
                status={
                  isTransferDelay
                    ? 'delay'
                    : statusClass === 'transY100'
                      ? 'progress'
                      : 'filled'
                }
                networkSrc={
                  BASE_URL +
                  currentOrder.currency_out.contract.network.network_image_url
                }
                network={currentOrder.currency_out.contract.network.name ?? ''}
                wallet={currentOrder.wallet_receiver ?? ''}
                amount={exponentNumberToString(currentOrder.amount_out)}
                amountUsd={`${amountOutUsd}`}
                coinSymbol={currentOrder.currency_out.symbol}
                imgSrc={BASE_URL + currentOrder.currency_out.image_url}
                withoutLoader={isWaitingForConfirmation}
                transaction={currentOrder.transaction_out}
                blockScan={
                  currentOrder.currency_out.contract.network.blockscan_url
                }
                onFinish={() => setIsDestinationAnimationFinished(true)}
              />
            </div>
          </div>
        </div>

        <AnimatePresence>
          {isTransferDelay && (
            <motion.div
              initial={{ opacity: 0, height: 0, marginBottom: 0 }}
              animate={{ opacity: 1, height: 'auto', marginBottom: 24 }}
              exit={{ opacity: 0, height: 0, marginBottom: 0 }}
              style={{ overflow: 'hidden' }}
              className="delayMessageWrapper"
            >
              <div className="delayMessage">
                <div className="delayTitle">
                  Bridging Is Experiencing Slight Delay...
                </div>
                <div className="delayDescription">
                  If you don't receive it within 10 minutes, please reach out to
                  support for quick resolution.
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isDetectionDelay && (
            <motion.div
              initial={{ opacity: 0, height: 0, marginBottom: 0 }}
              animate={{ opacity: 1, height: 'auto', marginBottom: 24 }}
              exit={{ opacity: 0, height: 0, marginBottom: 0 }}
              className="delayMessageWrapper"
            >
              <div className="delayMessage">
                <div className="delayTitle">
                  Detection Is Experiencing Slight Delay...
                </div>
                <div className="delayDescription">
                  If you don't receive it within 10 minutes, please reach out to
                  support for quick resolution.
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {!isWaitingForConfirmation && (
          <DAppBanner network={currentOrder.currency_out.contract.network} />
        )}

        {!isWaitingForConfirmation && (
          <>
            <div className="progressActionWrapper">
              <Link
                to={ROUTES.SEND_PAGE}
                className="d-flex justify-content-center align-items-center btn btnGradient w-100"
              >
                <img src={plusOutline} alt="" />
                Create Another Transfer
              </Link>
            </div>
            <div className="progressActionWrapper mt-3">
              <a
                href="http://discord.gg/retrobridge"
                target="_blank"
                rel="noopener noreferrer"
                className="progressAction"
              >
                <img src={discord} alt="" />
                Support
              </a>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}

export default ProgressForm;
