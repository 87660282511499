import { create } from 'zustand';
import {
  IBulkOrder,
  IBulkOrderWithType,
  IOrder,
} from '../../../../types/apiTypes';
import { OrderStatuses } from '../../../../types/enums';

interface HistoryPopoverStore {
  ignoredOrders: (IOrder['id'] | IBulkOrderWithType['id'])[];
  activeOrders: (IOrder | IBulkOrderWithType)[];
  recentOrders: (IOrder | IBulkOrderWithType)[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setActiveOrders: (orders: (IOrder | IBulkOrderWithType)[]) => void;
  setRecentOrders: (orders: (IOrder | IBulkOrderWithType)[]) => void;
  setOrder: (
    orderId: IOrder['id'] | IBulkOrderWithType['id'],
    order?: IOrder | IBulkOrderWithType
  ) => void;
  setIgnoredOrders: (
    orders: (IOrder['id'] | IBulkOrderWithType['id'])[]
  ) => void;
  addIgnoredOrder: (orderId: IOrder['id'] | IBulkOrder['id']) => void;
}

export const myHistoryPopoverStore = create<HistoryPopoverStore>(
  (set, get) => ({
    ignoredOrders: [],
    activeOrders: [],
    recentOrders: [],
    isOpen: false,
    setIsOpen: isOpen => set({ isOpen }),
    setActiveOrders: orders => set({ activeOrders: orders }),
    setRecentOrders: orders => set({ recentOrders: orders }),
    setOrder: (orderId, order) => {
      const type =
        order?.status === OrderStatuses.COMPLETE
          ? 'recentOrders'
          : 'activeOrders';
      const orders = get()[type];
      const activeOrders = get().activeOrders;
      const recentOrders = get().recentOrders;

      const isOrderInActiveStore = activeOrders.some(o => o.id === orderId);
      const isOrderInRecentStore = recentOrders.some(o => o.id === orderId);

      if (!order) {
        set({ [type]: orders.filter(o => o.id !== orderId).slice(0, 3) });
      } else if (isOrderInActiveStore && type === 'recentOrders') {
        set({
          activeOrders: activeOrders.filter(o => o.id !== orderId),
          recentOrders: [
            order,
            ...recentOrders.filter(o => o.id !== orderId).slice(0, 2),
          ],
        });
      } else if (isOrderInRecentStore && type === 'activeOrders') {
        set({
          activeOrders: [
            order,
            ...activeOrders.filter(o => o.id !== orderId).slice(0, 2),
          ],
          recentOrders: recentOrders.filter(o => o.id !== orderId),
        });
      } else {
        set({
          [type]: [order, ...orders.filter(o => o.id !== orderId)].slice(0, 3),
        });
      }
    },
    setIgnoredOrders: orders => set({ ignoredOrders: orders }),
    addIgnoredOrder: orderId =>
      set({ ignoredOrders: [...get().ignoredOrders, orderId] }),
  })
);

export const useMyHistoryPopoverStore = myHistoryPopoverStore;
