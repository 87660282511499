import * as Tooltip from '@radix-ui/react-tooltip';
import { ReactNode } from 'react';
import balanceTokens from '@/Assets/images/balanceTokens.png';
import { BalanceTooltipBg } from './BalanceTooltipBg';

interface BalanceTooltipProps {
  children?: ReactNode;
}

export function BalanceTooltip({ children }: BalanceTooltipProps) {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="top"
            align="end"
            alignOffset={-30}
            avoidCollisions={false}
            sideOffset={22}
            asChild
          >
            <div className="network-balance-info-tooltip">
              <BalanceTooltipBg className="network-balance-info-tooltip-bg" />
              Select <img src={balanceTokens} alt="" /> token to bridge to view
              its balance
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
