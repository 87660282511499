import clsx from 'clsx';
import { ImgHTMLAttributes, useState } from 'react';
import './styles.css';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  withSmoothLoading?: boolean;
}

export function Image({ withSmoothLoading, className, ...props }: ImageProps) {
  const [imgsPreloaded, setImgsPreloaded] = useState(false);

  return (
    <img
      alt=""
      className={clsx(
        className,
        withSmoothLoading && 'with-smooth-loading',
        withSmoothLoading && imgsPreloaded && 'loaded'
      )}
      onLoad={() => setImgsPreloaded(true)}
      {...props}
    />
  );
}
