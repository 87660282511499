import { SVGAttributes, useId } from 'react';

export function DappsIcon({ color, ...props }: SVGAttributes<SVGElement>) {
  const id = useId();
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.98901 9.01099C2.98901 5.13893 6.12794 2 10 2C13.8721 2 17.011 5.13893 17.011 9.01099C17.011 12.8831 13.8721 16.022 10 16.022C6.12794 16.022 2.98901 12.8831 2.98901 9.01099ZM10 0C5.02337 0 0.989014 4.03436 0.989014 9.01099C0.989014 13.9876 5.02337 18.022 10 18.022C14.9766 18.022 19.011 13.9876 19.011 9.01099C19.011 4.03436 14.9766 0 10 0ZM13.7791 4.86365C13.8177 4.84601 13.8599 4.83746 13.9024 4.83866C13.9482 4.83991 13.993 4.85245 14.0328 4.87517C14.0726 4.89789 14.1061 4.93009 14.1305 4.9689C14.1548 5.00771 14.1692 5.05193 14.1724 5.09764C14.1755 5.14335 14.1673 5.18913 14.1485 5.23091L11.6434 10.7936C11.6149 10.8581 11.5628 10.9094 11.4978 10.937L6.21445 13.1612C6.16352 13.1829 6.10728 13.1889 6.05291 13.1784C5.99854 13.168 5.94853 13.1416 5.90927 13.1025C5.87 13.0635 5.84328 13.0137 5.83251 12.9594C5.82175 12.9051 5.82743 12.8488 5.84883 12.7977L8.07464 7.51214C8.10185 7.44746 8.1525 7.39546 8.21644 7.36654L13.7791 4.86365ZM11.2512 9.01105C11.2512 8.32304 10.687 7.75879 9.99894 7.75879C9.31093 7.75879 8.74831 8.32304 8.74831 9.01105C8.74831 9.69906 9.31093 10.2639 9.99894 10.2639C10.687 10.2639 11.2512 9.69906 11.2512 9.01105Z"
        fill={color ? color : `url(#${id})`}
      />
      <defs>
        <linearGradient
          id={`${id}`}
          x1="5.49451"
          y1="1.35165"
          x2="16.3077"
          y2="18.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAD0FF" />
          <stop offset="1" stopColor="#B297FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
