import { Provider, formatUnits } from 'fuels';

export const getFuelBalance = async (
  account: string,
  provider: Provider,
  currencyAddress?: string,
  currencyDecimals?: number
) => {
  if (!provider || !currencyAddress || !currencyDecimals) {
    return '0';
  }

  const balance = await provider.getBalance(account, currencyAddress);
  return formatUnits(balance, currencyDecimals);
};
