export function FeeTooltipBg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="172"
      height="64"
      viewBox="0 0 172 64"
      fill="none"
      className="fee-tooltip-bg"
    >
      <mask id="path-1-inside-1_6467_3539" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.37258 0 0 5.37258 0 12V42C0 48.6274 5.37258 54 12 54H16.2265L22 64L27.7735 54H160C166.627 54 172 48.6274 172 42V12C172 5.37258 166.627 0 160 0H12Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.37258 0 0 5.37258 0 12V42C0 48.6274 5.37258 54 12 54H16.2265L22 64L27.7735 54H160C166.627 54 172 48.6274 172 42V12C172 5.37258 166.627 0 160 0H12Z"
        fill="#272632"
      />
      <path
        d="M16.2265 54L16.6595 53.75L16.5152 53.5H16.2265V54ZM22 64L21.567 64.25L22 65L22.433 64.25L22 64ZM27.7735 54V53.5H27.4848L27.3405 53.75L27.7735 54ZM0.5 12C0.5 5.64873 5.64873 0.5 12 0.5V-0.5C5.09644 -0.5 -0.5 5.09644 -0.5 12H0.5ZM0.5 42V12H-0.5V42H0.5ZM12 53.5C5.64872 53.5 0.5 48.3513 0.5 42H-0.5C-0.5 48.9036 5.09644 54.5 12 54.5V53.5ZM16.2265 53.5H12V54.5H16.2265V53.5ZM22.433 63.75L16.6595 53.75L15.7935 54.25L21.567 64.25L22.433 63.75ZM27.3405 53.75L21.567 63.75L22.433 64.25L28.2065 54.25L27.3405 53.75ZM160 53.5H27.7735V54.5H160V53.5ZM171.5 42C171.5 48.3513 166.351 53.5 160 53.5V54.5C166.904 54.5 172.5 48.9036 172.5 42H171.5ZM171.5 12V42H172.5V12H171.5ZM160 0.5C166.351 0.5 171.5 5.64873 171.5 12H172.5C172.5 5.09644 166.904 -0.5 160 -0.5V0.5ZM12 0.5H160V-0.5H12V0.5Z"
        fill="#A39DC7"
        fillOpacity="0.5"
        mask="url(#path-1-inside-1_6467_3539)"
      />
    </svg>
  );
}
