import { ILevel } from '@/types/quests';

import level1Thumbnail from '@/Assets/images/quests/level1/thumbnail.png';
import level2Thumbnail from '@/Assets/images/quests/level2/thumbnail.png';
import level3Thumbnail from '@/Assets/images/quests/level3/thumbnail.png';
import level4Thumbnail from '@/Assets/images/quests/level4/thumbnail.png';

import level1ProfileImage from '@/Assets/images/quests/level1/profileImage.png';
import level2ProfileImage from '@/Assets/images/quests/level2/profileImage.png';
import level3ProfileImage from '@/Assets/images/quests/level3/profileImage.png';
import level4ProfileImage from '@/Assets/images/quests/level4/profileImage.png';

import level1ProfileBackground from '@/Assets/images/quests/level1/background.png';
import level1ProfileMobileBackground from '@/Assets/images/quests/level1/backgroundMobile.png';
import level2ProfileBackground from '@/Assets/images/quests/level2/background.png';
import level2ProfileMobileBackground from '@/Assets/images/quests/level2/backgroundMobile.png';
import level3ProfileBackground from '@/Assets/images/quests/level3/background.png';
import level3ProfileMobileBackground from '@/Assets/images/quests/level3/backgroundMobile.png';
import level4ProfileBackground from '@/Assets/images/quests/level4/background.png';
import level4ProfileMobileBackground from '@/Assets/images/quests/level4/backgroundMobile.png';

export const levels: ILevel[] = [
  {
    name: 'Pioneer',
    thumbnail: level1Thumbnail,

    profileImage: level1ProfileImage,
    profileBackground: level1ProfileBackground,
    profileBackgroundMobile: level1ProfileMobileBackground,

    amountOfBridges: 10,
    multiplier: 1,
  },
  {
    name: 'Enthusiast',
    thumbnail: level2Thumbnail,

    profileImage: level2ProfileImage,
    profileBackground: level2ProfileBackground,
    profileBackgroundMobile: level2ProfileMobileBackground,

    amountOfBridges: 10,
    multiplier: 2,
  },
  {
    name: 'Universal',
    thumbnail: level3Thumbnail,

    profileImage: level3ProfileImage,
    profileBackground: level3ProfileBackground,
    profileBackgroundMobile: level3ProfileMobileBackground,

    amountOfBridges: 25,
    multiplier: 3,
  },
  {
    name: 'Legendary ',
    thumbnail: level4Thumbnail,

    profileImage: level4ProfileImage,
    profileBackground: level4ProfileBackground,
    profileBackgroundMobile: level4ProfileMobileBackground,

    amountOfBridges: 50,
    multiplier: 5,
  },
];
