import { useMemo } from 'react';
import { BASE_URL } from '@/Config/ApiConfig';
import { IBulkOrder } from '@/types/apiTypes';
import { NetworkInfo } from '../../../../ProgressPage/components/NetworkInfo';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';

interface ProgressOrderItemProps {
  order: IBulkOrder['receiving_data'][0];
}

export function ProgressOrderItem({ order }: ProgressOrderItemProps) {
  const { prices } = usePriceUpdater();

  const amountInUSD = useMemo(() => {
    if (!order?.currency?.symbol) {
      return 0;
    }

    return getPrice(order.currency.symbol, prices).usd * +order.amount_out;
  }, [order?.currency?.symbol, order?.amount_out, prices]);

  return (
    <NetworkInfo
      netwSrc={BASE_URL + order.currency.contract.network.network_image_url}
      netw={order.currency.contract.network.name ?? ''}
      wallet={order.wallet_receiver!}
      amount={`${order.amount_out}`}
      amountUsd={`${amountInUSD}`}
      coinSimbol={order?.currency.symbol ?? ''}
      imgSrc={BASE_URL + order.currency.image_url}
    />
  );
}
