import { SVGAttributes } from 'react';

export function TickWithGradient(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      {...props}
    >
      <circle
        cx="9.55273"
        cy="9"
        r="9"
        fill="url(#paint0_linear_12676_41984)"
      />
      <path
        d="M13.1521 6.30005L8.20215 11.25L5.95215 9.00005"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12676_41984"
          x1="3.04394"
          y1="-6.85647e-08"
          x2="16.0615"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFF9B" />
          <stop offset="1" stopColor="#1E7121" />
        </linearGradient>
      </defs>
    </svg>
  );
}
