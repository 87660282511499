import { ethers } from 'ethers';
import { Erc20Contract } from '../contracts/erc20Contract.service';

export const getErc20BalanceHelper = async (
  contractAddress: string,
  userAddress: string,
  provider: ethers.Signer | ethers.providers.Provider
) => {
  const instance = new Erc20Contract(contractAddress, provider);
  const balance = await instance.balanceOf(userAddress);

  return balance;
};
