export function Completed() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <circle cx="7" cy="7" r="7" fill="#7CFFC8" />
      <path
        d="M10.3327 4.5L5.74935 9.08333L3.66602 7"
        stroke="#2B4C48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
