import { SVGAttributes } from 'react';

export function TickIcon({
  color = 'currentColor',
  ...props
}: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M10 2.99976L4.5 8.49976L2 5.99976"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
