import {
  BrowserRouter,
  BrowserRouterProps,
  HashRouter,
  HashRouterProps,
} from 'react-router-dom';

type STAGES = 'development' | 'production' | 'staging';

const stages: Record<
  STAGES,
  (params: BrowserRouterProps | HashRouterProps) => JSX.Element
> = {
  development: HashRouter,
  production: BrowserRouter,
  staging: BrowserRouter,
};

const stage = process.env.REACT_APP_STAGE || 'production';

export const AppRouter = stages[stage as STAGES];
