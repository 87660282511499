const devUrl =
  process.env.REACT_APP_DEV_API_URL ||
  'https://backend-dev-bridge.up.railway.app';
const stagingUrl = 'https://staging-backend.retrobridge.io';
const prodUrl = 'https://backend.retrobridge.io';

export type STAGES = 'development' | 'production' | 'staging';

const stages: Record<STAGES, string> = {
  development: devUrl,
  staging: stagingUrl,
  production: prodUrl,
};

export const stage = (process.env.REACT_APP_STAGE as STAGES) || 'production';

export const BASE_URL = stages[stage];
// export const BASE_URL = devUrl;

const phaseApiStages: Record<STAGES, string> = {
  development: 'https://journey-dev.up.railway.app',
  staging: 'https://api-journey-stage.retrobridge.dev',
  production: 'https://api-journey.retrobridge.io',
};

export const PHASES_API_URL = phaseApiStages[stage];

const bulkApiStages: Record<STAGES, string> = {
  development:
    process.env.REACT_APP_BULK_DEV_API_URL || 'https://bulk-dev.up.railway.app',
  staging: 'https://bulk-api.retrobridge.dev',
  production: 'https://bulk-api.retrobridge.io',
};

export const BULK_API_URL = bulkApiStages[stage];

export const testnetApiStages: Record<STAGES, string> = {
  production: 'https://testnet.backend.retrobridge.io',
  staging: 'https://testnet.backend.retrobridge.dev',
  development: 'https://testnet.backend.retrobridge.dev',
};

export const TESTNET_API_URL = testnetApiStages[stage];
