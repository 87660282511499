import { NetworkTypes } from '../providers/web3Provider';
import { IOrder } from '../types/apiTypes';

export const getTimeSpendByOrder = (order: IOrder, byType = true) => {
  if (byType) {
    return getSpendTimeByNetworkType(order);
  }

  //create time calculation func
  return getSpendTimeByNetworkType(order);
};

export function calculateExecutedTime(milliseconds: number) {
  if (isNaN(milliseconds) || milliseconds < 0) {
    return '1 min';
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  if (minutes > 0 && seconds > 0) {
    return `${minutes} min ${seconds} sec`;
  } else if (minutes > 0) {
    return `${minutes} min`;
  } else {
    return `${seconds} sec`;
  }
}

//TODO
//currently used everywhere, leave this function only in Progress.tsx component and create order time spend func by dates
const getSpendTimeByNetworkType = (order: IOrder) => {
  const isFromStark =
    order.currency_in.contract.network.network_type === NetworkTypes.STARKNET;
  const isToStark =
    order.currency_out.contract.network.network_type === NetworkTypes.STARKNET;

  if (isFromStark || isToStark) {
    return 2;
  }

  return 1;
};
