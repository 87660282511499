import { ethers } from 'ethers';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { getErc20BalanceHelper } from './getErc20BalanceHelper';

export const getEvmBalance = async (
  account: string,
  rpc_url?: string,
  currencyAddress?: string,
  currencyDecimals?: number
) => {
  if (!rpc_url || !currencyAddress || !currencyDecimals) {
    return '0';
  }

  const provider = new ethers.providers.JsonRpcProvider(rpc_url);

  if (currencyAddress === ethers.constants.AddressZero) {
    const balance = await provider.getBalance(account);
    const parsedBalance = formatEther(balance);

    return parsedBalance;
  } else {
    const tokenBalance = await getErc20BalanceHelper(
      currencyAddress,
      account,
      provider
    );

    if (tokenBalance) {
      return formatUnits(tokenBalance!, currencyDecimals);
    } else {
      return '0';
    }
  }
};
