import { levels } from '@/constants/quests/levels';

export const calcCurrentLevel = (totalBridges: number) => {
  const level =
    levels.findIndex(level => level.amountOfBridges > totalBridges) || 0;

  if (level === 0) {
    return 0;
  } else if (levels[levels.length - 1].amountOfBridges <= totalBridges) {
    return levels.length - 1;
  } else {
    return level - 1;
  }
};
