import { useMemo } from 'react';
import { NetworkTypes } from '@/providers/web3Provider';
import { useConnectedIcon } from './useConnectedIcon';
import { useTonConnectedIcon } from './useTonConnectedIcon';
import { useStarknetConnectedIcon } from '@/starknet/hooks/useConnectedIcon';
import { useSolanaConnectedIcon } from './useSolanaConnectedIcon';
import { useConnectedTronIcon } from '@/tron/hooks/useConnectedTronIcon';
import { useConnectedFuelIcon } from '@/fuel/hooks/useConnectedFuelIcon';

export const useConnectedIconByNetworkType = (
  network_type: NetworkTypes | undefined
) => {
  const evmIcon = useConnectedIcon();
  const tonIcon = useTonConnectedIcon();
  const starknetIcon = useStarknetConnectedIcon();
  const solanaIcon = useSolanaConnectedIcon();
  const tronIcon = useConnectedTronIcon();
  const fuelIcon = useConnectedFuelIcon();

  const icon = useMemo(() => {
    switch (network_type) {
      case NetworkTypes.EVM:
      case NetworkTypes.ZK_SYNC_ERA:
        return evmIcon;
      case NetworkTypes.STARKNET:
        return starknetIcon;
      case NetworkTypes.TON:
        return tonIcon;
      case NetworkTypes.SOLANA:
        return solanaIcon;
      case NetworkTypes.TRON:
        return tronIcon;
      case NetworkTypes.FUEL:
        return fuelIcon;
      default:
        return '';
    }
  }, [
    network_type,
    evmIcon,
    tonIcon,
    starknetIcon,
    solanaIcon,
    tronIcon,
    fuelIcon,
  ]);

  return icon;
};
