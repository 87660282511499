import { SVGAttributes } from 'react';

export function Trashcan(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      {...props}
    >
      <path
        d="M6 4H7.33333H18"
        stroke="#FF9EB6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33334 4V2.66667C9.33334 2.31305 9.47382 1.97391 9.72387 1.72386C9.97392 1.47381 10.3131 1.33333 10.6667 1.33333H13.3333C13.687 1.33333 14.0261 1.47381 14.2762 1.72386C14.5262 1.97391 14.6667 2.31305 14.6667 2.66667V4M16.6667 4V13.3333C16.6667 13.687 16.5262 14.0261 16.2762 14.2761C16.0261 14.5262 15.687 14.6667 15.3333 14.6667H8.66668C8.31305 14.6667 7.97392 14.5262 7.72387 14.2761C7.47382 14.0261 7.33334 13.687 7.33334 13.3333V4H16.6667Z"
        stroke="#FF9EB6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 7.33333V11.3333"
        stroke="#FF9EB6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 7.33333V11.3333"
        stroke="#FF9EB6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
