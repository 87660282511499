import { NetworkTypes } from '@/providers/web3Provider';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface AuthStore {
  tokens?: Record<string, string | undefined>;
  setTokens: (tokens: Record<NetworkTypes, string>) => void;

  setToken: (network: NetworkTypes, token: string | undefined) => void;

  resetTokens: () => void;
}

export const authStore = create(
  persist<AuthStore>(
    set => ({
      tokens: {},
      setTokens: tokens => set({ tokens }),

      setToken: (network, token) =>
        set(state => ({
          tokens: {
            ...state.tokens,
            [network]: token,
          },
        })),

      resetTokens: () => set({ tokens: {} }),
    }),
    {
      name: 'auth-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
      partialize: state =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => ['tokens'].includes(key))
        ) as AuthStore,
    }
  )
);

export const useAuthStore = authStore;
