import { Journey, Phase, PhaseTask } from '@/types/phases';
import { create } from 'zustand';

interface QuestsStore {
  journeys: Journey[];
  setJourneys: (journeys: Journey[]) => void;
  addJourney: (journey: Journey) => void;

  journeyStatuses: Record<
    Phase[0],
    | {
        isNFTEligible?: boolean;
        isNFTClaimed?: boolean;
        NFTClaimedNetwork?: string | null;
      }
    | undefined
  >;
  setJourneyStatuses: (
    phase: Phase[0],
    statuses: {
      isNFTEligible: boolean;
      isNFTClaimed: boolean;
      NFTClaimedNetwork: string | null;
    }
  ) => void;

  tasks: Record<Phase[0], PhaseTask[] | undefined>;
  setTasks: (phase: Phase[0], tasks: PhaseTask[]) => void;

  setTaskStatus: (phase: Phase, taskId: number, status: boolean) => void;
}

export const questsPhasesStore = create<QuestsStore>((set, get) => ({
  journeys: [],
  setJourneys: journeys => set({ journeys }),
  addJourney: journey =>
    set(state => ({ journeys: [...state.journeys, journey] })),

  journeyStatuses: {},
  setJourneyStatuses: (phase, statuses) =>
    set(state => ({
      journeyStatuses: { ...state.journeyStatuses, [phase]: statuses },
    })),

  tasks: {},
  setTasks: (phase, tasks) =>
    set(state => ({ tasks: { ...state.tasks, [phase]: tasks } })),

  setTaskStatus: (phase, taskId, status) =>
    set(state => ({
      tasks: {
        ...state.tasks,
        [phase]: state.tasks[phase]?.map(task => {
          if (task.id === taskId) {
            return { ...task, isCompleted: status };
          }

          return task;
        }),
      },
    })),
}));

export const useQuestsPhasesStore = questsPhasesStore;
