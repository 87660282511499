import { UseContractReadResult } from '@starknet-react/core';
import { uint256 } from 'starknet';

export interface UseBalanceReadResult
  extends Omit<UseContractReadResult, 'data'> {
  data?: {
    decimals: number;
    formatted: string;
    symbol: string;
    value: ReturnType<typeof uint256.uint256ToBN>;
  };
}

export const balanceABIFragment = [
  {
    members: [
      {
        name: 'low',
        offset: 0,
        type: 'felt',
      },
      {
        name: 'high',
        offset: 1,
        type: 'felt',
      },
    ],
    name: 'Uint256',
    size: 2,
    type: 'struct',
  },
  {
    name: 'balanceOf',
    type: 'function',
    inputs: [
      {
        name: 'account',
        type: 'felt',
      },
    ],
    outputs: [
      {
        name: 'balance',
        type: 'Uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        name: 'symbol',
        type: 'felt',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [
      {
        name: 'decimals',
        type: 'felt',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    name: 'transfer',
    type: 'function',
    inputs: [
      {
        name: 'recipient',
        type: 'felt',
      },
      {
        name: 'amount',
        type: 'Uint256',
      },
    ],
    outputs: [
      {
        name: 'success',
        type: 'felt',
      },
    ],
  },
];
