import { Address } from 'fuels';

export const isValidFuelAddress = (address?: string) => {
  if (!address) return false;
  try {
    const checksumAddress = Address.fromB256(address).toChecksum();
    return Address.isChecksumValid(checksumAddress);
  } catch (e) {
    return false;
  }
};
