import { SVGAttributes, useId } from 'react';

export function Faucet({ color, ...props }: SVGAttributes<SVGElement>) {
  const id = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3258 3.89262H3.14028C2.96534 3.89262 2.82354 3.77107 2.82354 3.62113V2.26368C2.82354 2.11373 2.96534 1.99219 3.14028 1.99219H12.3258C12.5007 1.99219 12.6425 2.11373 12.6425 2.26368V3.62113C12.6425 3.77107 12.5007 3.89262 12.3258 3.89262ZM6.30772 5.15959V4.30951V3.89268H3.77378V5.15959H3.77376H3.14028C2.96534 5.15959 2.82354 5.31941 2.82354 5.51658V15.4434H2.19003C2.0151 15.4434 1.87329 15.5756 1.87329 15.7387V16.6245C1.87329 16.7876 2.0151 16.9198 2.19003 16.9198H10.7421C10.917 16.9198 11.0588 16.7876 11.0588 16.6245V15.7387C11.0588 15.5756 10.917 15.4434 10.7421 15.4434H7.258V9.80035C7.258 9.72352 7.23646 9.65235 7.19981 9.59409H12.0091V10.5443C12.0091 10.7192 12.1509 10.861 12.3258 10.861H14.543C14.7179 10.861 14.8598 10.7192 14.8598 10.5443V9.28348L14.8598 9.27735V8.58052C14.8598 7.56755 14.0357 6.74342 13.0227 6.74342H7.25791V5.47633C7.25791 5.30139 7.1161 5.15959 6.94116 5.15959H6.30772ZM13.434 14.4504C12.692 14.4504 12.0882 13.8463 12.0882 13.1038C12.0882 12.6449 12.4193 12.2384 12.7395 11.8453C12.8744 11.6797 13.0018 11.5232 13.092 11.3785C13.1647 11.2616 13.2926 11.1906 13.4303 11.1905H13.4304C13.568 11.1905 13.6959 11.2615 13.7687 11.3782C13.8614 11.5269 13.9915 11.6845 14.1292 11.8514L14.1292 11.8514L14.1292 11.8514L14.1292 11.8514L14.1299 11.8522L14.13 11.8523C14.4498 12.2399 14.7806 12.6407 14.7806 13.1038C14.7806 13.8463 14.1765 14.4504 13.434 14.4504Z"
        fill={color ? color : `url(#${id})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0881 13.1038C12.0881 13.8463 12.6919 14.4504 13.4339 14.4504C14.1764 14.4504 14.7805 13.8463 14.7805 13.1038C14.7805 12.6407 14.4497 12.2399 14.1299 11.8523L14.1298 11.8522L14.1291 11.8514C13.9914 11.6845 13.8613 11.5269 13.7686 11.3782C13.6958 11.2615 13.5679 11.1906 13.4303 11.1906H13.4302C13.2925 11.1906 13.1646 11.2616 13.0919 11.3785C13.0017 11.5232 12.8743 11.6797 12.7394 11.8453C12.4192 12.2384 12.0881 12.6449 12.0881 13.1038Z"
        fill="#ffffff"
      />
      <defs xmlns="http://www.w3.org/2000/svg">
        <linearGradient
          id={id}
          x1="1.54418"
          y1="1.27944"
          x2="7.6019"
          y2="16.9198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E7C9FF" />
          <stop offset="1.0001" stopColor="#9C9AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
