import { SVGAttributes } from 'react';

export function CopyIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      {...props}
    >
      <path
        d="M10.5 4.5H6C5.44772 4.5 5 4.94772 5 5.5V10C5 10.5523 5.44772 11 6 11H10.5C11.0523 11 11.5 10.5523 11.5 10V5.5C11.5 4.94772 11.0523 4.5 10.5 4.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.5H2.5C2.23478 7.5 1.98043 7.39464 1.79289 7.20711C1.60536 7.01957 1.5 6.76522 1.5 6.5V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H7C7.26522 1 7.51957 1.10536 7.70711 1.29289C7.89464 1.48043 8 1.73478 8 2V2.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
