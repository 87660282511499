import { ROUTES } from '@/constants/routes.constants';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { MobileLinkItem } from './MobileLinkItem';
import './styles.css';
import { motion } from 'framer-motion';
import { CartIcon } from '@/Assets/Icons/Cart';

interface NavigationItem {
  href: string;
  content: ReactNode;
  className?: string;
}

const navigationItems: NavigationItem[] = [
  {
    href: ROUTES.QUESTS_PAGE,
    content: <span className="quests-navbar--item-text">Retro Points</span>,
  },
  {
    href: ROUTES.QUESTS_PAGE_PHASES,
    content: <span className="quests-navbar--item-text">Phases</span>,
  },
  {
    href: ROUTES.QUESTS_PAGE_ADVENTURES,
    content: <span className="quests-navbar--item-text">Adventures</span>,
  },
  {
    href: ROUTES.QUESTS_PAGE_ACTIVITIES,
    className: 'px-5',
    content: (
      <>
        <span className="quests-navbar--item-text">Activities</span>{' '}
        <span className="quests-navbar--item-badge">free points</span>
      </>
    ),
  },
  {
    href: ROUTES.QUESTS_PAGE_MARKET,
    content: (
      <span className="quests-navbar--item-text">
        Retro Market
        <CartIcon className="ms-2" />
      </span>
    ),
  },
];

export function Navbar() {
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const page = navigationItems.findIndex(item => item.href.endsWith(pathname));

  if (width < 768) {
    return (
      <div className="overflow-hidden">
        <motion.nav
          drag="x"
          draggable
          dragConstraints={{ left: 0, right: 0 }}
          dragSnapToOrigin
          dragMomentum={false}
          dragElastic={0.2}
          className="quests-navbar"
          id="quests-navbar"
          onDragEnd={(e, info) => {
            const direction = -info?.offset?.x > 0 ? 1 : -1;

            const newPage = page + direction;

            if (
              Math.abs(info.offset.x) > 100 &&
              newPage >= 0 &&
              newPage < navigationItems.length
            ) {
              if (
                navigationItems[newPage].href.startsWith(
                  window.location.origin
                ) ||
                !navigationItems[newPage].href.startsWith('http')
              ) {
                navigate(
                  navigationItems[newPage].href.replace(
                    window.location.origin,
                    ''
                  )
                );
              }
            }
          }}
        >
          {navigationItems.map((item, i) => (
            <MobileLinkItem
              to={item.href}
              currentPage={page}
              page={i}
              className={clsx('quests-navbar--item', item.className)}
              key={item.href}
            >
              {item.content}
            </MobileLinkItem>
          ))}
        </motion.nav>
      </div>
    );
  }

  return (
    <nav className="quests-navbar">
      {navigationItems.map(item => (
        <Link
          to={item.href}
          className={clsx(
            'quests-navbar--item',
            item.href.endsWith(pathname) ? 'active' : '',
            item.className
          )}
          key={item.href}
        >
          {item.content}
        </Link>
      ))}
    </nav>
  );
}
