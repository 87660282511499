import { UIWallet } from '@tonconnect/ui-react';
import { tobiFunTON } from './customConnectors/tobiFunTON';
import { stage, STAGES } from '@/Config/ApiConfig';

const tonManifests: Record<STAGES, string> = {
  development: `${window.location.origin}/tonconnect-manifest.json`,
  staging: 'https://api.npoint.io/b5aedf2364de6a9c12ba',
  production: 'https://retrobridge.io/tonconnect-manifest.json',
};

export const tonManifestUrl = tonManifests[stage];
export const additionalTONWallets: UIWallet[] = [tobiFunTON];
