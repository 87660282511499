import { Link } from 'react-router-dom';
import { ExternalIcon } from '@/Assets/Icons/External';
import { ROUTES } from '@/constants/routes.constants';
import './styles.css';

export function NetworkSwitch() {
  return (
    <div className="network-switch mainnet">
      <button className="network-switch--item">Mainnet</button>
      <Link
        to={ROUTES.TESTNET_SEND_PAGE}
        target="_blank"
        className="network-switch--item"
      >
        Testnet & Faucet <ExternalIcon />
      </Link>
    </div>
  );
}
