import { rpcStore } from '@/stores/rpc.store';
import { INetwork } from '@/types/apiTypes';
import { RPCItem } from '@/types/rpc';

export const getRPCByNetwork = async (network?: INetwork): Promise<RPCItem> => {
  let { isLoading } = rpcStore.getState();
  const { RPCs: initialRPCs } = rpcStore.getState();

  while (isLoading && initialRPCs.length <= 0) {
    await new Promise(resolve => setTimeout(resolve, 100));
    ({ isLoading } = rpcStore.getState());
  }

  const { RPCs } = rpcStore.getState();
  const rpcItem = RPCs.find(item => item.id === network?.id);

  if (!rpcItem) {
    throw new Error(
      `Failed to get mock network: ${network?.name} ${network?.id}`
    );
  }

  return rpcItem;
};
