import clsx from 'clsx';
import { AnimationProps, motion, useWillChange } from 'framer-motion';
import { useMemo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

interface MobileLinkItemProps extends LinkProps {
  page: number;
  currentPage: number;
}

export function MobileLinkItem({
  page,
  currentPage,
  ...props
}: MobileLinkItemProps) {
  const willChange = useWillChange();
  const { pathname } = useLocation();

  const styles = useMemo<AnimationProps['animate']>(() => {
    if (page === currentPage) {
      return {
        position: 'relative',
        visibility: 'visible',
        zIndex: 1,
      };
    }

    if (page - 1 === currentPage) {
      return {
        position: 'absolute',
        x: 'calc(50vw - 50% - 20px)',
        scale: 12 / 14,
        visibility: 'visible',
      };
    }

    if (page + 1 === currentPage) {
      return {
        position: 'absolute',
        x: 'calc(-50vw + 50% + 20px)',
        scale: 12 / 14,
        visibility: 'visible',
      };
    }

    return {
      position: 'absolute',
      x: currentPage > page ? '-100vw' : '100vw',
      opacity: 0,
      // scale: 12 / 14,
      transitionEnd: {
        visibility: 'hidden',
      },
    };
  }, [page, currentPage]);

  return (
    <motion.div
      key={page}
      animate={styles}
      style={{ willChange }}
      transition={{
        duration: 0.2,
      }}
    >
      <Link
        to={props.to}
        className={clsx(
          'quests-navbar--item',
          props.to.toString().endsWith(pathname) ? 'active' : '',
          props.className
        )}
      >
        {props.children}
      </Link>
    </motion.div>
  );
}
