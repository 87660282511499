import { useCallback } from 'react';
import { Id, toast } from 'react-toastify';
import { ReferralToast } from '../../components/ReferralToast';

export const showReferralToast = (referralAddress: string): Id =>
  toast.success(<ReferralToast referralAddress={referralAddress} />, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    icon: false,
    draggable: true,
    closeButton: false,
    progress: undefined,
    theme: 'dark',
    style: {
      background: 'transparent',
      boxShadow: 'none',
    },
    toastId: 'referral-toast',
  });

export const useReferralToast = () => {
  const showReferralToastPrivate = useCallback(
    (referralAddress: string): Id => showReferralToast(referralAddress),
    []
  );

  return {
    showReferralToast: showReferralToastPrivate,
  };
};
