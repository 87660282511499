import { Faucet } from '@/Assets/Icons/Faucet';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import { DappsIcon } from '@/Assets/Icons/dapps';
import retroIcon from '@/Assets/Icons/retroIcon.svg';
import { ROUTES } from '@/constants/routes.constants';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu';
import './styles.css';

interface HeaderProps {
  variant?: 'default' | 'light';
}

export function Header({ variant = 'default' }: HeaderProps) {
  return (
    <header className="header">
      <Link to={ROUTES.LANDING_PAGE}>
        <img
          src={retroIcon}
          alt="logo"
          style={{ width: '170px', height: '40px' }}
        />
      </Link>

      <nav className="header-nav">
        <Link to={ROUTES.DAPP_EXPLORER_PAGE} className="header-nav-item">
          {variant === 'default' && <DappsIcon />}
          {variant === 'light' && <DappsIcon color="#fff" />}
          Dapps Explorer
        </Link>
        <Link to={ROUTES.TESTNET_SEND_PAGE} className="header-nav-item">
          {variant === 'default' && <Faucet />}
          {variant === 'light' && <Faucet color="#fff" />}
          Testnets & Faucet
        </Link>
        <Link to={ROUTES.QUESTS_PAGE} className="header-nav-item">
          <RetroBridgerPointsIcon width={18} height={13} />
          Retro Points
        </Link>
        <Link
          to={ROUTES.SEND_PAGE}
          className="headerBtn header-nav-item fw-bold"
        >
          Launch App
        </Link>
      </nav>
      <div className="d-flex align-items-center ms-auto d-lg-none">
        <Link
          to={ROUTES.SEND_PAGE}
          className="btnGradient header-nav-item--btn"
        >
          App
        </Link>
        <MobileMenu />
      </div>
    </header>
  );
}
