import './styles.css';

export const Loader = () => {
  return (
    <div className="loader">
      <div className="circle one"></div>
      <div className="circle two"></div>
      <div className="circle three"></div>
    </div>
  );
};
