import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import clock from '@/Assets/Icons/clock.svg';
import close from '@/Assets/Icons/close.svg';

import { IOrderInfo } from '@/types/apiTypes';
import '../../Explorer.css';

import dayjs from 'dayjs';

import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '@/Config/ApiConfig';
import { Loader } from '@/components/Loader';
import { maxPlatformDecimals } from '@/constants/numbers';
import { GET_POPUP_PARAMS } from '@/constants/popup.constants';
import { EXPLORER_PAGE, HOME_PAGE } from '@/constants/routes.constants';
import useGetParams from '@/hooks/useGetParams';
import { api } from '@/services/api';
import { OrderStatuses } from '@/types/enums';
import { shortAddress } from '@/utils';
import { decimalSlicer, exponentNumberToString } from '@/utils/numbers';
import ExplorerModalInfoBlock from '../ExplorerModalInfoBlock';
import AmountBlock from '../AmountBlock';
import { ModalInfoField } from '../ModalInfoField';

import { calculateExecutedTime } from '@/utils/getTimeSpendByOrder';
import { isOneDomain } from '@/Config';

function ExplorerModal({ show }: { show: boolean }) {
  const [modalMarginTop, setModalMarginTop] = useState('10%');
  const stopEvent = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
  };

  const [order, setOrder] = useState<IOrderInfo | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    if (window.history.length <= 2) {
      navigate(isOneDomain ? EXPLORER_PAGE : HOME_PAGE);
    } else {
      const newUrlParams = new URLSearchParams(location.search);
      newUrlParams.delete(GET_POPUP_PARAMS.orderId);
      newUrlParams.delete(GET_POPUP_PARAMS.popup);

      navigate(location.pathname + newUrlParams.toString());
    }
  };
  const orderId = useGetParams(GET_POPUP_PARAMS.orderId);

  const orderFetcher = useCallback(async (orderId: string) => {
    try {
      const fetched = await api.getOrderById(orderId);
      if (fetched) {
        return setOrder(fetched);
      }
      navigate(HOME_PAGE);
    } catch (error) {
      console.error(error);
      navigate(HOME_PAGE);
    }
  }, []);

  useEffect(() => {
    if (!orderId) {
      navigate(HOME_PAGE);
    } else {
      orderFetcher(orderId);
      const id = setInterval(async () => {
        const fetched = await api.getOrderById(orderId);
        if (fetched) {
          setOrder(fetched);
        }
      }, 2000);

      return () => clearInterval(id);
    }
  }, [orderId, navigate]);

  const amountIn = useMemo(() => {
    if (!order?.amount_in) {
      return '';
    }

    return decimalSlicer(
      exponentNumberToString(order.amount_in),
      maxPlatformDecimals
    );
  }, [order]);

  const amountOut = useMemo(() => {
    if (!order?.amount_out) {
      return '';
    }

    return decimalSlicer(
      exponentNumberToString(order.amount_out),
      maxPlatformDecimals
    );
  }, [order]);

  const handleMarginTop = () => {
    const windowHeight = window.innerHeight;
    if (windowHeight > 900) {
      setModalMarginTop('40%');
    } else if (windowHeight < 900 && windowHeight > 800) {
      setModalMarginTop('15%');
    } else if (windowHeight < 800 && windowHeight > 750) {
      setModalMarginTop('5%');
    } else {
      setModalMarginTop('1%');
    }
  };

  useEffect(() => {
    handleMarginTop();
  }, []);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body
        onClick={handleClose}
        className=" explorerModal bg-transparent px-0"
      >
        <div
          onClick={stopEvent}
          style={{ marginTop: modalMarginTop, zIndex: 11 }}
          className=" explorerFormBg  defaultRadius mt-5 mainSendModal"
        >
          <Form className="formBody explorer-form-pb gradient-border-mask text-light explorer-modal-p-x  p-3 defaultRadius align-items-center px-3 px-lg-5 position-relative">
            <>
              {!order ? (
                <div className=" explorerModal center">
                  <Loader />
                </div>
              ) : (
                <>
                  <Button
                    onClick={handleClose}
                    variant="link"
                    className="closeBtn"
                  >
                    <img src={close} alt="" className="closeIcon" />
                  </Button>
                  <div
                    className="d-flex flex-column position-relative"
                    style={{ zIndex: 10 }}
                  >
                    <div className="d-flex  align-items-center justify-content-center explorerModalTitle mb-3">
                      {order?.id
                        ? `Transaction #${shortAddress(order.id) ?? ''}`
                        : 'Transaction'}
                    </div>
                    <div className="center">
                      <div className="linkBtn time-section-bg text-nowrap rounded p-2 ">
                        <img className="clockIcon" src={clock} alt="" />
                        {`${dayjs(order?.createdAt ?? '').format(
                          'dddd, HH:mm'
                        )}`}
                      </div>
                    </div>

                    <div className="transferInfoSubtitle mb-2">From</div>
                    <ModalInfoField
                      netwAddress={order?.wallet_sender ?? ''}
                      netwName={
                        order?.currency_in?.contract?.network?.name ?? ''
                      }
                      networkUrl={
                        BASE_URL +
                        order?.currency_in?.contract?.network?.network_image_url
                      }
                      scanLink={
                        order?.currency_in?.contract?.network?.blockscan_url ??
                        ''
                      }
                      txHash={order?.transaction_in}
                    />
                    <div className="transferInfoSubtitle mb-2">To</div>
                    <ModalInfoField
                      netwAddress={order?.wallet_receiver ?? ''}
                      netwName={
                        order?.currency_out?.contract?.network.name ?? ''
                      }
                      networkUrl={
                        BASE_URL +
                        order?.currency_out?.contract?.network
                          ?.network_image_url
                      }
                      scanLink={
                        order?.currency_out?.contract?.network?.blockscan_url ??
                        ''
                      }
                      txHash={order?.transaction_out}
                    />
                    <AmountBlock
                      currencyInIcon={BASE_URL + order?.currency_in?.image_url}
                      currencyOutIcon={
                        BASE_URL + order?.currency_out?.image_url
                      }
                      amountIn={amountIn}
                      amountOut={amountOut}
                    />
                    <ExplorerModalInfoBlock
                      status={order?.status ?? OrderStatuses.PENDING}
                      fee={order?.retrobridge_fee ?? 0}
                      feeInUSD={order?.retrobridge_fee_in_usd ?? 0}
                      swapFee={order?.swap_fee ?? 0}
                      swapFeeUsd={order?.swap_fee_in_usd ?? 0}
                      destinationFee={order?.blockchain_fee ?? 0}
                      time={calculateExecutedTime(order.execution_time)}
                      currency={order?.currency_out?.symbol ?? ''}
                      destinationCurrency={
                        order?.currency_out?.contract.network.token_symbol
                      }
                      holderOfNFT={order?.holder_nft}
                      originalPrice={order?.original_price}
                    />
                  </div>
                </>
              )}
            </>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ExplorerModal;
