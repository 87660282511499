import { useStarknetAccount } from '@/starknet/hooks/account';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { useAccount as useEVMAccount } from 'wagmi';
import { BASE_URL, BULK_API_URL, stage } from '../../Config/ApiConfig';
import { useSSEBroadcast } from '../../hooks/useSSEBroadcast';
import { useWalletStore } from '../../stores/wallet.store';
import { IBulkOrderWithType, IOrder } from '../../types/apiTypes';
import { BulkOrderStatuses, OrderStatuses } from '../../types/enums';
import { bridgeRoutes } from '../../utils/getRoutes';
import { useMyHistoryPopoverStore } from '../Header/components/MyHistoryPopover/store';
import { fetchOrders } from '../Header/components/MyHistoryPopover/utils';
import { useTransactionNotificationStore } from './store';
import './styles.css';

export function TransactionTrackingNotification() {
  const [isOpen, isHidden, setIsOpen] = useTransactionNotificationStore(s => [
    s.isOpen,
    s.isHidden,
    s.setIsOpen,
  ]);
  const [isPopoverOpen, addIgnoredOrder, setOrder] = useMyHistoryPopoverStore(
    state => [state.isOpen, state.addIgnoredOrder, state.setOrder]
  );
  const { pathname } = useLocation();
  const { isConnected, address } = useEVMAccount();
  const { ongoingOrders, clearOngoingOrder, addOngoingOrder, setOngoingOrder } =
    useWalletStore();
  const { address: starknetAddress } = useStarknetAccount();

  const shouldConnect =
    bridgeRoutes.includes(window.location.host) || stage === 'development';

  useSSEBroadcast({
    url:
      shouldConnect && (address || starknetAddress)
        ? BASE_URL +
          `/api/orders/session_open?wallet_address=${
            address || starknetAddress
          }`
        : null,
    listeners: {
      order_updated: e => {
        try {
          const order: IOrder = e as IOrder;

          if (
            order.status === OrderStatuses.REJECTED ||
            order.status === OrderStatuses.EXPIRED ||
            order.status === OrderStatuses.DEPOSIT_FAILED
          ) {
            setOngoingOrder(order.id, undefined);
            setOrder(order.id, undefined);
          } else {
            addOngoingOrder(order, true);
            setOrder(order.id, order);
          }
        } catch (error) {
          console.warn(error);
        }
      },
    },
  });

  useSSEBroadcast({
    channel: 'RetroBridgeBulk',
    url:
      shouldConnect && (address || starknetAddress)
        ? BULK_API_URL +
          `/api/bulk_order/session/session_open?wallet_address=${
            address || starknetAddress
          }`
        : null,
    listeners: {
      order_updated: e => {
        try {
          const order: IBulkOrderWithType = e as IBulkOrderWithType;

          if (
            order.status === BulkOrderStatuses.REJECTED ||
            order.status === BulkOrderStatuses.EXPIRED ||
            order.status === BulkOrderStatuses.DEPOSIT_FAILED
          ) {
            setOngoingOrder(order.id, undefined);
            setOrder(order.id, undefined);
          } else {
            addOngoingOrder(order, true);
            setOrder(order.id, order);
          }
        } catch (error) {
          console.warn(error);
        }
      },
    },
  });

  useEffectOnce(() => {
    fetchOrders(address || starknetAddress);
  });

  useEffect(() => {
    fetchOrders(address || starknetAddress);
  }, [address, starknetAddress]);

  useEffect(() => {
    if (isPopoverOpen) {
      ongoingOrders.forEach(o => addIgnoredOrder(o.id));
      clearOngoingOrder();
    }
  }, [isPopoverOpen]);

  useLayoutEffect(() => {
    if (
      bridgeRoutes.includes(window.location.host) ||
      pathname.startsWith('/send') ||
      pathname.startsWith('/progress')
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [pathname]);

  if (!isOpen || isPopoverOpen || !isConnected || ongoingOrders.length <= 0)
    return null;

  return null;
}
