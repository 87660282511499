import { FueletWalletConnector, FuelWalletConnector } from '@fuels/connectors';

export const FUEL_ICONS: Record<string, string> = {
  [new FuelWalletConnector().name]: 'fuel',
  [new FueletWalletConnector().name]: 'fuelet',
};

export const getFuelConnectorIcon = (connector?: string) => {
  return connector && FUEL_ICONS[connector]
    ? require(`@/Assets/images/wallets/${FUEL_ICONS[connector]}.png`)
    : require('@/Assets/images/wallets/fuel.png');
};
