import { stage, STAGES } from '@/Config/ApiConfig';
import { constants } from 'starknet';

const appChainIds: Record<STAGES, constants.StarknetChainId> = {
  development: constants.StarknetChainId.SN_SEPOLIA,
  production: constants.StarknetChainId.SN_MAIN,
  staging: constants.StarknetChainId.SN_MAIN,
};

export const getAppStarknetChanId = () => {
  return appChainIds[stage];
};
