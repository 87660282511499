import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import close from '@/Assets/Icons/close-icon.svg';
import documentSearch from '@/Assets/Icons/documentSearchGradient.svg';
import history from '@/Assets/Icons/history.svg';
import empty from '@/Assets/Icons/waitTable.svg';
import { ROUTES } from '@/constants/routes.constants';
import { useWindowResize } from '@/hooks/useWindowResize';
import { TransactionItem } from '../../../TransactionTrackingNotification/TransactionItem';
import { useMyHistoryPopoverStore } from './store';
import './styles.css';
import { OrderType } from '@/types/enums';
import { AdvancedTransactionItem } from '../../../TransactionTrackingNotification/AdvancedTransactionItem';

export function MyHistoryPopover() {
  const [isPopoverOpen, setIsPopoverOpen, activeOrders, recentOrders] =
    useMyHistoryPopoverStore(state => [
      state.isOpen,
      state.setIsOpen,
      state.activeOrders,
      state.recentOrders,
    ]);
  const [isOpen, setIsOpen] = useState(isPopoverOpen);
  const [tab, setTab] = useState<'active' | 'recent'>('active');
  const { width } = useWindowResize();

  useEffect(() => {
    handleOpenChange(false);
  }, [width]);

  const handleOpenChange = (val: boolean) => {
    setIsOpen(val);
    setIsPopoverOpen(val);
    if (!val) {
      setTab('active');
    }
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={handleOpenChange}>
      <Popover.Trigger asChild>
        <button className="header-mobile-history account-section-bg d-sm-flex align-items-center ms-2 rounded-start noRoundLeft position-relative">
          <div className="d-flex position-relative">
            {(!isOpen || width > 992) && <img src={history} alt="" />}
            {isOpen && width < 992 && (
              <img src={close} className="close" alt="" />
            )}
            {activeOrders.length > 0 && (
              <div className="header-mobile-history--count">
                {activeOrders.length}
              </div>
            )}
          </div>
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align="end"
          sideOffset={8}
          className="my-history-popover"
        >
          <Popover.Close className="my-history-popover--close">
            <img src={close} alt="" />
          </Popover.Close>
          <div className="my-history-popover--headline">
            Active transfers ({activeOrders.length})
          </div>
          <div className="my-history-popover--tabs">
            <button
              className={clsx(
                'my-history-popover--tab',
                tab === 'active' && 'active'
              )}
              onClick={() => setTab('active')}
            >
              Active ({activeOrders.length})
            </button>
            <button
              className={clsx(
                'my-history-popover--tab',
                tab === 'recent' && 'active'
              )}
              onClick={() => setTab('recent')}
            >
              Recent ({recentOrders.length})
            </button>
          </div>
          <div
            className={clsx(
              'my-history-popover--content',
              tab === 'recent' && 'recent'
            )}
          >
            {tab === 'active' &&
              activeOrders.length > 0 &&
              activeOrders.map(order =>
                order.type === OrderType.STANDARD ? (
                  <TransactionItem
                    order={order}
                    key={order.id}
                    variant="popover"
                  />
                ) : (
                  <AdvancedTransactionItem
                    order={order}
                    key={order.id}
                    variant="popover"
                  />
                )
              )}
            {tab === 'active' && activeOrders.length <= 0 && (
              <div className="my-history-popover--empty">
                <img src={empty} alt="" />
                <div>No active transactions</div>
              </div>
            )}
            {tab === 'recent' &&
              recentOrders.length > 0 &&
              recentOrders.map(order =>
                order.type === OrderType.STANDARD ? (
                  <TransactionItem
                    order={order}
                    key={order.id}
                    variant="popover"
                  />
                ) : (
                  <AdvancedTransactionItem
                    order={order}
                    key={order.id}
                    variant="popover"
                  />
                )
              )}
            {tab === 'recent' && recentOrders.length <= 0 && (
              <div className="my-history-popover--empty">
                <img src={empty} alt="" />
                <div>No recent transactions</div>
              </div>
            )}
          </div>
          {tab === 'recent' && (
            <Popover.Close asChild>
              <Link
                className="my-history-popover--action"
                to={ROUTES.EXPLORER_PAGE + '?myHistory'}
                target="_blank"
              >
                <img src={documentSearch} alt="" />
                Check full transaction history
              </Link>
            </Popover.Close>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
