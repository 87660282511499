import { SVGAttributes } from 'react';

export function Compass(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.01099C2 5.13893 5.13893 2 9.01099 2C12.8831 2 16.022 5.13893 16.022 9.01099C16.022 12.8831 12.8831 16.022 9.01099 16.022C5.13893 16.022 2 12.8831 2 9.01099ZM9.01099 0C4.03436 0 0 4.03436 0 9.01099C0 13.9876 4.03436 18.022 9.01099 18.022C13.9876 18.022 18.022 13.9876 18.022 9.01099C18.022 4.03436 13.9876 0 9.01099 0ZM12.7901 4.86365C12.8287 4.84601 12.8709 4.83746 12.9134 4.83866C12.9592 4.83991 13.004 4.85245 13.0438 4.87517C13.0836 4.89789 13.1171 4.93009 13.1415 4.9689C13.1658 5.00771 13.1802 5.05193 13.1833 5.09764C13.1865 5.14335 13.1783 5.18913 13.1595 5.23091L10.6544 10.7936C10.6259 10.8581 10.5738 10.9094 10.5088 10.937L5.22544 13.1612C5.17451 13.1829 5.11826 13.1889 5.0639 13.1784C5.00953 13.168 4.95952 13.1416 4.92025 13.1025C4.88099 13.0635 4.85427 13.0137 4.8435 12.9594C4.83273 12.9051 4.83841 12.8488 4.85981 12.7977L7.08563 7.51214C7.11284 7.44746 7.16349 7.39546 7.22743 7.36654L12.7901 4.86365ZM10.2622 9.01105C10.2622 8.32304 9.69794 7.75879 9.00993 7.75879C8.32192 7.75879 7.7593 8.32304 7.7593 9.01105C7.7593 9.69906 8.32192 10.2639 9.00993 10.2639C9.69794 10.2639 10.2622 9.69906 10.2622 9.01105Z"
        fill="white"
      />
    </svg>
  );
}
