import { stage, STAGES } from '@/Config/ApiConfig';
import { WALLET_CONNECT_PROJECT_ID } from '@/providers/rainbow';
import { Chain, mainnet, sepolia } from '@starknet-react/chains';
import {
  publicProvider,
  StarknetConfig as StarknetProvider,
} from '@starknet-react/core';
import React from 'react';
import { ArgentMobileConnector } from 'starknetkit/argentMobile';
import { InjectedConnector } from 'starknetkit/injected';
import { WebWalletConnector } from 'starknetkit/webwallet';
import { TransactionManagerProvider } from './transaction';

export * from './transaction';

const starknetChains: Record<STAGES, Chain[]> = {
  development: [sepolia],
  staging: [mainnet],
  production: [mainnet],
};

export const chains = starknetChains[stage];

export const connectors = [
  new InjectedConnector({ options: { id: 'argentX', name: 'Argent X' } }),
  new InjectedConnector({ options: { id: 'braavos', name: 'Braavos' } }),
  new WebWalletConnector({ url: 'https://web.argent.xyz' }),
  new ArgentMobileConnector({
    dappName: 'RetroBridge',
    projectId: WALLET_CONNECT_PROJECT_ID,
  }),
  new InjectedConnector({
    options: {
      id: 'okxwallet',
      name: 'OKX Wallet',
    },
  }),
];

/** Arguments for `StarknetConfig`. */
export type StarknetConfigProps = {
  children: React.ReactNode;
};

/** Root component that manages the state of all starknet-react hooks. */
export function StarknetConfig({ children, ...config }: StarknetConfigProps) {
  return (
    <StarknetProvider
      chains={chains}
      provider={publicProvider()}
      connectors={connectors}
    >
      <TransactionManagerProvider>{children}</TransactionManagerProvider>
    </StarknetProvider>
  );
}
