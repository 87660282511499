import { SVGAttributes } from 'react';

export function QuestionIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M7.0005 0C3.13579 0 0.000488281 3.13135 0.000488281 6.99632C0.000488281 10.865 3.13579 14 7.00047 14C10.8673 14 13.9999 10.865 13.9999 6.99632C14 3.13135 10.8673 0 7.0005 0ZM7.49932 10.8913C7.33675 11.036 7.14833 11.1087 6.93489 11.1087C6.71406 11.1087 6.52143 11.0373 6.357 10.8942C6.19232 10.7514 6.10981 10.5514 6.10981 10.2944C6.10981 10.0665 6.18966 9.87464 6.34883 9.71916C6.50801 9.56369 6.70326 9.48595 6.93489 9.48595C7.16282 9.48595 7.35467 9.56369 7.51041 9.71916C7.66589 9.87464 7.74389 10.0665 7.74389 10.2944C7.7436 10.5477 7.66216 10.7467 7.49932 10.8913ZM9.52654 5.86557C9.40163 6.0972 9.25328 6.29693 9.08121 6.46534C8.90965 6.63372 8.60109 6.91673 8.15573 7.31464C8.03293 7.4269 7.93412 7.52545 7.86008 7.61029C7.78603 7.69542 7.73069 7.77313 7.69458 7.84377C7.65821 7.91437 7.63029 7.98501 7.61052 8.05562C7.59074 8.12597 7.56098 8.2501 7.52066 8.42743C7.45216 8.80374 7.23687 8.99186 6.87505 8.99186C6.68689 8.99186 6.52879 8.93046 6.39992 8.8074C6.27158 8.68434 6.20755 8.50173 6.20755 8.2593C6.20755 7.95548 6.25472 7.69221 6.3488 7.46956C6.44236 7.24688 6.56753 7.05163 6.723 6.88325C6.87874 6.71487 7.08849 6.5151 7.35279 6.28347C7.58442 6.08083 7.75177 5.92801 7.85479 5.82495C7.95808 5.72167 8.0448 5.60678 8.11514 5.48028C8.18604 5.35352 8.22083 5.21623 8.22083 5.06789C8.22083 4.77829 8.11359 4.53428 7.89804 4.33533C7.68275 4.13638 7.40499 4.03676 7.06479 4.03676C6.66663 4.03676 6.3736 4.13716 6.18544 4.33796C5.99729 4.53875 5.83841 4.83441 5.70795 5.22521C5.58464 5.6342 5.35116 5.83866 5.00778 5.83866C4.80514 5.83866 4.63413 5.76725 4.49473 5.62441C4.35559 5.48158 4.28602 5.32691 4.28602 5.16038C4.28602 4.81674 4.39644 4.46837 4.61701 4.11553C4.83785 3.76269 5.15986 3.47044 5.58331 3.23907C6.00649 3.00744 6.50061 2.89148 7.06479 2.89148C7.58945 2.89148 8.05245 2.98845 8.45404 3.18215C8.85564 3.37555 9.16605 3.63882 9.38503 3.97189C9.60376 4.30469 9.71336 4.66652 9.71336 5.05732C9.71388 5.36435 9.65144 5.63394 9.52654 5.86557Z"
        fill="currentColor"
      />
    </svg>
  );
}
