import { INetwork } from '@/types/apiTypes';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface ENSStore {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  /**
   * Default ENS names for each chain
   */
  defaultDomain: Record<INetwork['name'], string | undefined>;
  setDefaultDomain: (networkName: INetwork['name'], domain: string) => void;

  /**
   * List of ENS domains for each chain
   */
  domains: Record<INetwork['name'], string[] | undefined>;
  setDomains: (networkName: INetwork['name'], domains: string[]) => void;

  /**
   * Reset the store
   */
  resetStore: () => void;
}

export const ensStore = create(
  persist<ENSStore>(
    (set, get) => ({
      isLoading: true,
      setIsLoading: (isLoading: boolean) => set({ isLoading }),

      defaultDomain: {},
      setDefaultDomain: (networkName, domain) =>
        set(state => ({
          defaultDomain: {
            ...state.defaultDomain,
            [networkName]: domain,
          },
        })),

      domains: {},
      setDomains: (networkName, domains) =>
        set(state => ({
          domains: {
            ...state.domains,
            [networkName]: domains,
          },
        })),

      resetStore: () =>
        set({
          defaultDomain: {},
          domains: {},
        }),
    }),
    {
      name: 'retrobridge-ens-store',
      storage: createJSONStorage(() => localStorage),
      partialize: state =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) =>
            ['defaultDomain'].includes(key)
          )
        ) as ENSStore,
    }
  )
);

export const useENSStore = ensStore;
