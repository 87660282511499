import { Chain } from 'viem';

export const arbitrumOne: Chain = {
  id: 42161,
  name: 'Arbitrum One',
  nativeCurrency: {
    decimals: 18,
    name: 'Arbitrum One',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://arbitrum.llamarpc.com'] },
    default: { http: ['https://arbitrum.llamarpc.com'] },
  },
  blockExplorers: {
    default: { name: 'Arbiscan', url: 'https://arbiscan.io/' },
    etherscan: { name: 'Arbiscan', url: 'https://arbiscan.io/' },
  },
  testnet: false,
};

export const mode: Chain = {
  id: 34443,
  name: 'Mode',
  nativeCurrency: {
    decimals: 18,
    name: 'Mode',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.mode.network'] },
    default: { http: ['https://mainnet.mode.network'] },
  },
  blockExplorers: {
    default: { name: 'Mode blockscout', url: 'https://explorer.mode.network/' },
  },
  testnet: false,
};

export const zetaChain: Chain = {
  id: 7000,
  name: 'Zeta Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'Zeta Chain',
    symbol: 'ZETA',
  },
  rpcUrls: {
    public: { http: ['https://zetachain-evm.blockpi.network/v1/rpc/public'] },
    default: { http: ['https://zetachain-evm.blockpi.network/v1/rpc/public'] },
  },
  blockExplorers: {
    default: {
      name: 'Zeta explorer',
      url: 'https://zetachain.blockscout.com/',
    },
  },
  testnet: false,
};

export const blast: Chain = {
  id: 81457,
  name: 'Blast',
  nativeCurrency: {
    decimals: 18,
    name: 'Blast',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.blast.io'] },
    default: { http: ['https://rpc.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://blastscan.io/' },
  },
  testnet: false,
};
export const blastTestnet: Chain = {
  id: 168587773,
  name: 'Blast Sepolia Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Blast',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://blast-sepolia.drpc.org'] },
    default: { http: ['https://blast-sepolia.drpc.org'] },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://sepolia.blastscan.io/' },
  },
  testnet: true,
};

export const zkLinkNova: Chain = {
  id: 810180,
  name: 'zkLink Nova',
  nativeCurrency: {
    decimals: 18,
    name: 'zkLink Nova',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.zklink.io'] },
    default: { http: ['https://rpc.zklink.io'] },
  },
  blockExplorers: {
    default: { name: 'zkLinkNovascan', url: 'https://explorer.zklink.io/' },
  },
  testnet: false,
};

export const rariChainTestnet: Chain = {
  id: 1918988905,
  name: 'RARI Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'RARI Testnet',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://testnet.rpc.rarichain.org/http'] },
    default: { http: ['https://testnet.rpc.rarichain.org/http'] },
  },
  blockExplorers: {
    default: {
      name: 'rarichain',
      url: 'https://testnet.explorer.rarichain.org/',
    },
  },
  testnet: true,
};

export const rariChain: Chain = {
  id: 1380012617,
  name: 'RARI Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'RARI Chain',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.rpc.rarichain.org/http'] },
    default: { http: ['https://mainnet.rpc.rarichain.org/http'] },
  },
  blockExplorers: {
    default: {
      name: 'rarichain',
      url: 'https://mainnet.explorer.rarichain.org/',
    },
  },
  testnet: false,
};

export const xLayer: Chain = {
  id: 196,
  name: 'X Layer',
  nativeCurrency: {
    decimals: 18,
    name: 'X Layer',
    symbol: 'OKB',
  },
  rpcUrls: {
    public: { http: ['https://xlayerrpc.okx.com'] },
    default: { http: ['https://xlayerrpc.okx.com'] },
  },
  blockExplorers: {
    default: {
      name: 'xLayerscan',
      url: 'https://www.okx.com/web3/explorer/xlayer/',
    },
  },
  testnet: false,
};

export const taiko: Chain = {
  id: 167000,
  name: 'Taiko',
  nativeCurrency: {
    decimals: 18,
    name: 'Taiko',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.taiko.xyz'] },
    default: { http: ['https://rpc.taiko.xyz'] },
  },
  blockExplorers: {
    default: { name: 'Taikoscan', url: 'https://taikoscan.io/' },
  },
  testnet: false,
};

export const taikoHekla: Chain = {
  id: 167009,
  name: 'Taiko Hekla',
  nativeCurrency: {
    decimals: 18,
    name: 'Taiko Hekla',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.hekla.taiko.xyz'] },
    default: { http: ['https://rpc.hekla.taiko.xyz'] },
  },
  blockExplorers: {
    default: {
      name: 'Taiko Hekla Explorer',
      url: 'https://hekla.taikoscan.network',
    },
  },
  testnet: true,
};

export const mintChain: Chain = {
  id: 185,
  name: 'Mint Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'Mint Chain',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.mintchain.io'] },
    default: { http: ['https://rpc.mintchain.io'] },
  },
  blockExplorers: {
    default: { name: 'Mintscan', url: 'https://explorer.mintchain.io/' },
  },
  testnet: false,
};

export const merlin: Chain = {
  id: 4200,
  name: 'Merlin',
  nativeCurrency: {
    decimals: 8,
    name: 'Merlin',
    symbol: 'BTC',
  },
  rpcUrls: {
    public: { http: ['https://rpc.merlinchain.io'] },
    default: { http: ['https://rpc.merlinchain.io'] },
  },
  blockExplorers: {
    default: { name: 'Merlinscan', url: 'https://scan.merlinchain.io/' },
  },
  testnet: false,
};

export const b2_network: Chain = {
  id: 223,
  name: 'B² Network',
  nativeCurrency: {
    decimals: 8,
    name: 'B² Network',
    symbol: 'BTC',
  },
  rpcUrls: {
    public: { http: ['https://rpc.bsquared.network'] },
    default: { http: ['https://rpc.bsquared.network'] },
  },
  blockExplorers: {
    default: {
      name: 'Bsquaredscan',
      url: 'https://explorer.bsquared.network/',
    },
  },
  testnet: false,
};

export const bob: Chain = {
  id: 60808,
  name: 'BOB',
  nativeCurrency: {
    decimals: 18,
    name: 'BOB',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.gobob.xyz'] },
    default: { http: ['https://rpc.gobob.xyz'] },
  },
  blockExplorers: {
    default: { name: 'Bobscan', url: 'https://explorer.gobob.xyz/' },
  },
  testnet: false,
};

export const bitlayer: Chain = {
  id: 200901,
  name: 'Bitlayer',
  nativeCurrency: {
    decimals: 8,
    name: 'Bitlayer',
    symbol: 'BTC',
  },
  rpcUrls: {
    public: { http: ['https://rpc.bitlayer.org'] },
    default: { http: ['https://rpc.bitlayer.org'] },
  },
  blockExplorers: {
    default: { name: 'Bitlayerscan', url: 'https://www.btrscan.com/' },
  },
  testnet: false,
};

export const zircuit: Chain = {
  id: 48900,
  name: 'Zircuit',
  nativeCurrency: {
    decimals: 18,
    name: 'Zircuit',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://zircuit-mainnet.drpc.org'] },
    default: { http: ['https://zircuit-mainnet.drpc.org'] },
  },
  blockExplorers: {
    default: { name: 'Zircuitscan', url: 'https://explorer.zircuit.com/' },
  },
  testnet: false,
};

export const ape_chain: Chain = {
  id: 33139,
  name: 'ApeChain',
  nativeCurrency: {
    decimals: 18,
    name: 'ApeChain',
    symbol: 'APE',
  },
  rpcUrls: {
    public: { http: ['https://rpc.apechain.com/http'] },
    default: { http: ['https://rpc.apechain.com/http'] },
  },
  blockExplorers: {
    default: { name: 'ApeChain', url: 'https://apescan.io/' },
  },
  testnet: false,
};

export const world_chain: Chain = {
  id: 480,
  name: 'World Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'World Chain',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://worldchain-mainnet.g.alchemy.com/public'] },
    default: { http: ['https://worldchain-mainnet.g.alchemy.com/public'] },
  },
  blockExplorers: {
    default: { name: 'worldscan', url: 'https://worldscan.org/' },
  },
  testnet: false,
};

export const morph: Chain = {
  id: 2818,
  name: 'Morph',
  nativeCurrency: {
    decimals: 18,
    name: 'Morph',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.morphl2.io'] },
    default: { http: ['https://rpc.morphl2.io'] },
  },
  blockExplorers: {
    default: { name: 'morphscan', url: 'https://explorer.morphl2.io/' },
  },
  testnet: false,
};
