export const OutlinedPlusInCircle = ({ ...props }) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.00004 15.1667C11.6819 15.1667 14.6667 12.1819 14.6667 8.5C14.6667 4.8181 11.6819 1.83334 8.00004 1.83334C4.31814 1.83334 1.33337 4.8181 1.33337 8.5C1.33337 12.1819 4.31814 15.1667 8.00004 15.1667Z"
      stroke="#E5C4FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 5.83334V11.1667"
      stroke="#E5C4FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33337 8.5H10.6667"
      stroke="#E5C4FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
