import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';

export const MINUTE = 1000 * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const AVG_ORDER_EXECUTION_TIME_MS = 1.5 * MINUTE;

dayjs.extend(duration);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
