import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import solana from '@/Assets/images/wallets/solana.png';
import starknet from '@/Assets/images/wallets/starknet.png';
import ton from '@/Assets/images/wallets/ton.png';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import { useConnectedIcon } from '@/hooks/useConnectedIcon';
import { useSolanaConnectedIcon } from '@/hooks/useSolanaConnectedIcon';
import { useTonConnectedIcon } from '@/hooks/useTonConnectedIcon';
import { useStarknetConnectedIcon } from '@/starknet/hooks/useConnectedIcon';
import { useConnectedTronIcon } from '@/tron/hooks/useConnectedTronIcon';
import {
  PopoverAnchor,
  Root as PopoverRoot,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useAccount as useStarkentAccount } from '@starknet-react/core';
import { useTonAddress } from '@tonconnect/ui-react';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useState } from 'react';
import { useAccount as useEVMAccount } from 'wagmi';
import { ConnectedWalletPopoverMenu } from './Menu';
import './styles.css';
import { formatAddress } from '@/utils';
import { useFuelWallet } from '@/fuel/hooks/useFuelWallet';
import { useConnectedFuelIcon } from '@/fuel/hooks/useConnectedFuelIcon';

export function ConnectedWalletPopover() {
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const EVMConnectedIcon = useConnectedIcon();
  const { address: EVMAddress } = useEVMAccount();

  const { address: starknetAddress } = useStarkentAccount();
  const starknetIcon = useStarknetConnectedIcon();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const solanaIcon = useSolanaConnectedIcon();
  const { address: tronAddress } = useTronWallet();
  const { address: fuelAddress } = useFuelWallet();
  const fuelIcon = useConnectedFuelIcon();
  const tronIcon = useConnectedTronIcon();
  const tonAddress = useTonAddress();
  const tonIcon = useTonConnectedIcon();

  const nonEvmAddress =
    starknetAddress ||
    solanaAddress?.toBase58() ||
    tronAddress ||
    tonAddress ||
    fuelAddress;
  const isAllNonEvmConnected =
    starknetAddress &&
    solanaAddress &&
    tronAddress &&
    tonAddress &&
    fuelAddress;

  return (
    <>
      <div className="explorer-wallet-group">
        <PopoverRoot>
          {EVMAddress && (
            <PopoverAnchor className="explorer-wallet-group--item">
              <div className="explorer-wallet-group--label">EVM Wallet</div>
              <PopoverTrigger className="explorer-wallet-group--value">
                <img
                  src={EVMConnectedIcon}
                  className="explorer-wallet-group--logo"
                  alt=""
                />
                {formatAddress(EVMAddress, 4, 3)}
                <ArrowDownIcon width={18} height={18} />
              </PopoverTrigger>
            </PopoverAnchor>
          )}
          <ConnectedWalletPopoverMenu variant="evm" />
        </PopoverRoot>
        {(nonEvmAddress || EVMAddress) && (
          <>
            <div className="explorer-wallet-group--divider d-none d-md-block" />
            <PopoverRoot>
              <PopoverAnchor className="explorer-wallet-group--item">
                <div className="explorer-wallet-group--label">
                  Non-EVM Wallet
                </div>
                {!nonEvmAddress && (
                  <button
                    className="explorer-wallet-group--connect-button"
                    onClick={() => setIsConnectModalOpen(true)}
                  >
                    Connect
                    <div className="d-flex align-items-center">
                      <img
                        src={solana}
                        className="wallet-popover--trigger-icon z-3"
                        alt=""
                      />
                      <img
                        src={ton}
                        className="wallet-popover--trigger-icon z-2"
                        alt=""
                      />
                      <img
                        src={starknet}
                        className="wallet-popover--trigger-icon z-1"
                        alt=""
                      />
                    </div>
                  </button>
                )}
                {nonEvmAddress && (
                  <div className="d-flex align-items-center gap-2">
                    <PopoverTrigger className="explorer-wallet-group--value">
                      <div className="d-flex align-items-center">
                        {starknetAddress && (
                          <div
                            className="wallet-popover--trigger-icon"
                            style={{
                              zIndex: 4,
                            }}
                          >
                            <img src={starknetIcon} alt="" />
                          </div>
                        )}
                        {tonAddress && (
                          <div
                            className="wallet-popover--trigger-icon"
                            style={{
                              zIndex: 3,
                            }}
                          >
                            <img src={tonIcon} alt="" />
                          </div>
                        )}
                        {solanaAddress && (
                          <div
                            className="wallet-popover--trigger-icon"
                            style={{
                              zIndex: 2,
                            }}
                          >
                            <img src={solanaIcon} alt="" />
                          </div>
                        )}
                        {fuelAddress && (
                          <div
                            className="wallet-popover--trigger-icon"
                            style={{
                              zIndex: 1,
                            }}
                          >
                            <img src={fuelIcon} alt="" />
                          </div>
                        )}
                        {tronAddress && (
                          <div
                            className="wallet-popover--trigger-icon"
                            style={{
                              zIndex: 0,
                            }}
                          >
                            <img src={tronIcon} alt="" />
                          </div>
                        )}
                      </div>
                      {formatAddress(nonEvmAddress, 4, 3)}
                      <ArrowDownIcon width={18} height={18} />
                    </PopoverTrigger>
                    {(!isAllNonEvmConnected || !EVMAddress) && (
                      <button
                        className="explorer-wallet-group--connect-additional"
                        onClick={() => setIsConnectModalOpen(true)}
                      >
                        +
                        <div className="d-flex align-items-center">
                          {!EVMAddress && (
                            <div
                              className="wallet-popover--trigger-icon"
                              style={{
                                zIndex: 5,
                              }}
                            >
                              <img src={EVMConnectedIcon} alt="" />
                            </div>
                          )}
                          {!starknetAddress && (
                            <div
                              className="wallet-popover--trigger-icon"
                              style={{
                                zIndex: 4,
                              }}
                            >
                              <img src={starknetIcon} alt="" />
                            </div>
                          )}
                          {!tonAddress && (
                            <div
                              className="wallet-popover--trigger-icon"
                              style={{
                                zIndex: 3,
                              }}
                            >
                              <img src={tonIcon} alt="" />
                            </div>
                          )}
                          {!solanaAddress && (
                            <div
                              className="wallet-popover--trigger-icon"
                              style={{
                                zIndex: 2,
                              }}
                            >
                              <img src={solanaIcon} alt="" />
                            </div>
                          )}
                          {!fuelAddress && (
                            <div
                              className="wallet-popover--trigger-icon"
                              style={{
                                zIndex: 1,
                              }}
                            >
                              <img src={fuelIcon} alt="" />
                            </div>
                          )}
                          {!tronAddress && (
                            <div
                              className="wallet-popover--trigger-icon"
                              style={{
                                zIndex: 0,
                              }}
                            >
                              <img src={tronIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </button>
                    )}
                  </div>
                )}
              </PopoverAnchor>
              <ConnectedWalletPopoverMenu variant="non-evm" />
            </PopoverRoot>
          </>
        )}
      </div>
      <ConnectWalletModalWithDisabled
        openModal={isConnectModalOpen}
        setOpenModal={setIsConnectModalOpen}
        onlyDisconnectedNetworkTypes
      />
    </>
  );
}
