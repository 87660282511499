import tonIcon from '@/Assets/images/wallets/ton.png';
import { useTonConnectModal } from '@tonconnect/ui-react';
import { OptionsProps } from '..';

export function TONOptions({ onOpenChange }: OptionsProps) {
  const tonConnectModal = useTonConnectModal();

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">TON wallets</div>
      <div className="connect-wallet--group-grid">
        <button
          className="connect-wallet--group-item w-100"
          onClick={() => {
            onOpenChange(false);
            tonConnectModal.open();
          }}
        >
          <img src={tonIcon} style={{ height: '24px', width: '24px' }} alt="" />
          TON Connect
        </button>
      </div>
    </div>
  );
}
