export const GET_POPUP_PARAMS = {
  popup: 'popup',
  orderId: 'orderId',
};

export const GET_POPUP_TYPE = {
  popup: {
    order: 'order',
  },
};
