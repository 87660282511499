import { SVGAttributes, useId } from 'react';

export function CartIcon(props: SVGAttributes<SVGElement>) {
  const gradientId = useId();
  const clipId = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      {...props}
    >
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M14.4 7.84L16 2.24C16.08 2.03 16 1.82 15.84 1.61C15.68 1.47 15.44 1.4 15.2 1.4H4.16L4 0.63C3.92 0.28 3.6 0 3.2 0H0.8C0.32 0 0 0.28 0 0.7C0 1.12 0.32 1.4 0.8 1.4H2.48L3.84 9.94C3.04 10.29 2.4 10.99 2.4 11.9C2.4 13.09 3.44 14 4.8 14C6.16 14 7.2 13.09 7.2 11.9C7.2 11.62 7.12 11.41 7.04 11.2H10.56C10.48 11.41 10.4 11.62 10.4 11.9C10.4 13.09 11.44 14 12.8 14C14.16 14 15.2 13.09 15.2 11.9C15.2 10.71 14.16 9.8 12.8 9.8H5.52L5.28 8.4H13.6C14 8.4 14.32 8.19 14.4 7.84ZM5.6 11.9C5.6 12.32 5.28 12.6 4.8 12.6C4.32 12.6 4 12.32 4 11.9C4 11.48 4.32 11.2 4.8 11.2C5.28 11.2 5.6 11.48 5.6 11.9ZM12.8 12.6C12.32 12.6 12 12.32 12 11.9C12 11.48 12.32 11.2 12.8 11.2C13.28 11.2 13.6 11.48 13.6 11.9C13.6 12.32 13.28 12.6 12.8 12.6Z"
          fill={`url(#${gradientId})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientId}
          x1="4.00785"
          y1="1.05"
          x2="11.8981"
          y2="14.9792"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAD0FF" />
          <stop offset="1" stopColor="#B297FF" />
        </linearGradient>
        <clipPath id={clipId}>
          <rect width="16" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
