import { Compass } from '@/Assets/Icons/Compass';
import { BASE_URL } from '@/Config/ApiConfig';
import { ROUTES } from '@/constants/routes.constants';
import { IOrder } from '@/types/apiTypes';
import { Link } from 'react-router-dom';
import './styles.css';

interface DAppBannerProps {
  network: IOrder['currency_out']['contract']['network'];
}

export function DAppBanner({ network }: DAppBannerProps) {
  return (
    <Link
      to={
        ROUTES.DAPP_EXPLORER_PAGE + '?network=' + network.name + '&category=All'
      }
      target="_blank"
      rel="noopener noreferrer"
      className="dapp-banner"
    >
      <span>
        Discover Dapps built on{' '}
        <span className="dapp-banner--icon">
          <img src={BASE_URL + network.network_image_url} alt="" />
        </span>{' '}
        {network.name}
      </span>
      <Compass className="ms-auto" />
    </Link>
  );
}
