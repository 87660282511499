import { AnimatePresence, motion } from 'framer-motion';
import { useId } from 'react';

interface FillInWithTextErrorProps {
  children?: React.ReactNode;
}

export function FillInWithTextError({ children }: FillInWithTextErrorProps) {
  const id = useId();

  return (
    <AnimatePresence>
      {children && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className="fillInDialog-error-container"
          key={id}
        >
          <div className="fillInDialog-error-label">
            The below address cannot be processed
          </div>
          <div className="fillInDialog-error">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
