import { PHASES_API_URL } from '@/Config/ApiConfig';
import { REFERRAL_COOKIE_KEY } from '@/constants/retroPoints.constants';
import { showReferralToast } from '@/layouts/QuestsLayout/hooks/useReferralToast';
import { NetworkTypes } from '@/providers/web3Provider';
import { authStore } from '@/stores/auth.store';
import { retroPointsStore } from '@/stores/retroPoints.store';
import { signStore } from '@/stores/sign.store';
import { ISimpleApiResp } from '@/types/apiTypes';
import { TableSortOrder } from '@/types/enums';
import {
  LeaderboardItem,
  LeaderboardSortCriteria,
  ReferralInfo,
  RetroPointsUser,
  RetroQuest,
} from '@/types/retroPoints';
import { logInGroup } from '@/utils';
import { getCookie, removeCookie } from '@/utils/cookies';
import { ApiService as Api } from './CoreApi';
import { AxiosError } from 'axios';

export class RetroPointsService {
  private _api: Api;
  constructor() {
    this._api = new Api(PHASES_API_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    this._api.instance.interceptors.request.use(config => {
      const { tokens } = authStore.getState();
      if (tokens?.[NetworkTypes.EVM] === undefined) {
        return config;
      }

      const networkTypes = Object.keys(tokens);

      config.headers.set('network-types', networkTypes);

      if (tokens[NetworkTypes.EVM]) {
        config.headers.set('network-type', NetworkTypes.EVM);
      }

      for (let i = 0; i < networkTypes.length; i++) {
        if (tokens[networkTypes[i]]) {
          config.headers.set(
            networkTypes[i].toLowerCase(),
            tokens[networkTypes[i]]
          );
        }
      }

      return config;
    });

    /* this._api.instance.interceptors.response.use(
      response => response,
      error => {
        if (error instanceof AxiosError && error.response?.status === 401) {
          const { setResign } = signStore.getState();
          setResign(NetworkTypes.EVM);
        }
        throw error;
      }
    ); */
  }

  async assignRefferer(referrer_key: string) {
    try {
      await this._api.put<ISimpleApiResp<{}>>(
        `/api/user/assign_referrer?referrer_key=${referrer_key}`
      );
    } catch (error) {
      logInGroup('[RetroPointsService]', 'assignRefferer', error);
      return;
    }
  }

  async userInfo(showLoader = false, withReferralInfo = false) {
    const { setIsGeneralLoading, setUser } = retroPointsStore.getState();
    try {
      if (showLoader) {
        setIsGeneralLoading(true);
      }

      const url = '/api/user';

      const referrer_key = getCookie(REFERRAL_COOKIE_KEY) || undefined;

      if (referrer_key) await this.assignRefferer(referrer_key);

      const { data } =
        await this._api.get<ISimpleApiResp<RetroPointsUser>>(url);

      setUser(data);

      if (withReferralInfo) {
        await this.getReferralInfo();
      }
      return data;
    } catch (error) {
      logInGroup('[RetroPointsService]', 'userInfo', error);
      throw error;
    } finally {
      if (showLoader) {
        setIsGeneralLoading(false);
      }
    }
  }

  async checkIsWalletAuthorized(wallet: string) {
    try {
      const url = `/api/user/check_auth?wallet=${wallet}`;
      return this._api.get<ISimpleApiResp<{ is_ok: boolean }>>(url);
    } catch (error) {
      const { setResign } = signStore.getState();
      setResign(NetworkTypes.EVM);
    }
  }

  async bindWallet(
    referrer_key?: string,
    wallet_address?: string,
    network_type?: NetworkTypes
  ) {
    const { setIsBindingWallet, setWelcomeDialogOpen, setWelcomePoints } =
      retroPointsStore.getState();
    try {
      const isEVM = network_type === NetworkTypes.EVM;
      if (isEVM) {
        setIsBindingWallet(true);
      }
      const url = '/api/user/bind_wallet';
      const { tokens } = authStore.getState();

      if (tokens?.[NetworkTypes.EVM] === undefined) {
        return;
      }

      if (!referrer_key) {
        referrer_key = getCookie(REFERRAL_COOKIE_KEY) || undefined;
      }

      const { data } = await this._api.post<ISimpleApiResp<RetroPointsUser>>(
        url,
        {
          referrer_key,
          wallet_address,
        }
      );

      const user = await this.userInfo(isEVM, isEVM);
      if (isEVM) {
        if (
          !user?.is_welcome_shown &&
          (user?.points ?? 0) > 0 &&
          (user?.total_bridges ?? 0) > 0
        ) {
          setWelcomeDialogOpen(true);
          setWelcomePoints(user?.points ?? 0);
        } else {
          retroPointsService.setWelcomeShowed().catch();
        }
      }

      if (
        !data?.is_welcome_shown &&
        user?.total_bridges === 0 &&
        user?.referrer_address &&
        referrer_key
      ) {
        showReferralToast(referrer_key);
        this.setWelcomeShowed();
      }

      if (referrer_key) {
        removeCookie(REFERRAL_COOKIE_KEY);
      }

      return data;
    } catch (error) {
      logInGroup('[RetroPointsService]', 'bindWallet', error);
      throw error;
    } finally {
      setIsBindingWallet(false);
    }
  }

  async getReferralInfo() {
    const {
      setRefereesPoints,
      setAmountOfReferees,
      setAmountOfRefereesBridges,
      setReferralKey,
    } = retroPointsStore.getState();

    try {
      const url = '/api/user/referral_info';
      const { data } = await this._api.get<ISimpleApiResp<ReferralInfo>>(url);

      setRefereesPoints(data.total_points_earned);
      setAmountOfReferees(data.referrals_count);
      setAmountOfRefereesBridges(data.referrals_total_count_bridges);
      setReferralKey(data.referral_key);

      return data;
    } catch (error) {
      logInGroup('[RetroPointsService]', 'getReferralInfo', error);
    }
  }

  async getAllQuests(walletAddress?: string) {
    try {
      const url = '/api/quest/all';
      const { data } = await this._api.get<ISimpleApiResp<RetroQuest[]>>(url, {
        params: { wallet_address: walletAddress },
      });

      return data;
    } catch (error) {
      logInGroup('[RetroPointsService]', 'getAllQuests', error);
    }
    return [];
  }

  async refreshQuestTaskById(taskId: number) {
    try {
      const url = `/api/quest/refresh_result_quest/${taskId}`;
      const { data } =
        await this._api.get<ISimpleApiResp<Pick<RetroQuest, 'is_completed'>>>(
          url
        );

      return data.is_completed;
    } catch (error) {
      logInGroup('[RetroPointsService]', 'refreshQuestTaskById', error);
    }
    return false;
  }

  async claimQuestReward(taskId: number) {
    try {
      const url = `/api/user/quest_reward_points/${taskId}`;

      const { data } = await this._api.put<ISimpleApiResp<RetroQuest>>(url);

      await this.userInfo();

      return data;
    } catch (error) {
      logInGroup('[RetroPointsService]', 'claimQuestReward', error);
      throw error;
    }
  }

  async claimGameReward() {
    const url = '/api/user/pacman_reward_points';
    await this._api.put<ISimpleApiResp<RetroPointsUser>>(url);

    await this.userInfo();
  }

  async getLeaderboard(
    walletAddress?: string,
    take = 10,
    sortBy?: LeaderboardSortCriteria,
    sortOrder?: TableSortOrder
  ) {
    try {
      const url = '/api/leaderboard/top_leaderboard_participants';
      const { data } = await this._api.get<ISimpleApiResp<LeaderboardItem[]>>(
        url,
        {
          params: {
            wallet_address: walletAddress,
            take,
            sorted_by: sortBy,
            sort_order: sortOrder,
          },
        }
      );

      return data;
    } catch (error) {
      logInGroup('[RetroPointsService]', 'getLeaderboard', error);
    }
    return [];
  }

  async setWelcomeShowed() {
    try {
      const url = '/api/user/showed_welcome';
      await this._api.put(url);
    } catch (error) {
      logInGroup('[RetroPointsService]', 'setWelcomeShowed', error);
    }
  }
}

export const retroPointsService = new RetroPointsService();
