import { mainnetNetworks } from '@/constants/mock_networks.constants';
import { appStore, useAppStore } from '@/stores/app.store';
import { useNetworksStore } from '@/stores/networks.store';
import { INetwork } from '@/types/apiTypes';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { ReactNode, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import './styles.css';

interface NewDestinationItemProps {
  children: ReactNode;
  chainId: INetwork['chainId'];
}

export function NewDestinationItem({
  children,
  chainId,
}: NewDestinationItemProps) {
  const networks = useNetworksStore(s => s.networks);
  const [isAdvancedOpen, setNetworkTo] = useAppStore(s => [
    s.isAdvancedOpen,
    s.setNetworkTo,
  ]);
  const setSearchParams = useSearchParams()[1];

  const currentNetwork = useMemo(() => {
    return networks.find(network => network.chainId === chainId);
  }, [networks, chainId]);

  const relativeDate = useMemo(() => {
    const value = dayjs(currentNetwork?.created_at);

    if (value.isAfter(dayjs())) {
      return 'Nearest week';
    }

    if (value.isToday()) {
      return 'Today';
    }
    if (value.isYesterday()) {
      return 'Yesterday';
    }

    return value.format('D MMM');
  }, [currentNetwork?.created_at]);

  const backgroundSrc = useMemo(() => {
    return mainnetNetworks.find(network => network.chainId === chainId)
      ?.new_destination_bg;
  }, [chainId]);

  const handleClick = () => {
    if (!isAdvancedOpen && currentNetwork && currentNetwork.active) {
      handleNetworkToChange(currentNetwork);
    }
  };

  const handleNetworkToChange = (network: INetwork) => {
    const { networkFrom, networkTo, setNetworkFrom } = appStore.getState();
    const from = networkFrom;
    const to = networkTo;
    if (network?.id === from?.id) {
      if (to) {
        setSearchParams(searchParams => {
          searchParams.set('source', to.name);
          return searchParams;
        });
        setNetworkFrom(to);
      }
    }

    if (network && !!networkTo) {
      setSearchParams(searchParams => {
        searchParams.set('destination', network.name);
        return searchParams;
      });
    }

    setNetworkTo(network);
  };

  return (
    <div
      className={clsx(
        'notifications-menu--new-destination',
        !currentNetwork?.active && 'cursor-default'
      )}
      style={{
        backgroundImage: `url(${backgroundSrc})`,
      }}
      onClick={handleClick}
    >
      <div className="notifications-menu--new-destination--heading">
        {children}
      </div>
      <div className="notifications-menu--new-destination--date">
        {relativeDate}
      </div>
    </div>
  );
}
