import { useENSStore } from '@/stores/ens.store';
import { useTonConnectModal } from '@tonconnect/ui-react';
import clsx from 'clsx';
import { useState } from 'react';
import { useConnectedIconByNetworkType } from '@/hooks/useNetworkConnectedIcon';
import { shortAddress } from '@/utils';
import { useAppStore, WalletToType } from '@/stores/app.store';
import { NetworkTypes } from '@/providers/web3Provider';
import WalletAddressInput, {
  WalletAddressInputProps,
} from '../WalletIAddressInput';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import '../../../SendPage.css';

type WalletButtonsProps = Omit<WalletAddressInputProps, 'isVisible'> & {
  isFromConnected?: boolean;
  isToConnected?: boolean;
  destinationName?: string;
  destinationType?: NetworkTypes;
  connectedAddress?: string;
};

export function WalletButtons({
  isFromConnected,
  isToConnected,
  destinationName,
  destinationType = NetworkTypes.EVM,
  connectedAddress,
  ...props
}: WalletButtonsProps) {
  const { setValue } = props;
  const [walletToType, setWalletToType] = useAppStore(s => [
    s.walletToType,
    s.setWalletToType,
  ]);
  const [openModal, setOpenModal] = useState(false);
  const tonConnectModal = useTonConnectModal();
  // Icons

  const isToEVM =
    destinationType === NetworkTypes.EVM ||
    destinationType === NetworkTypes.ZK_SYNC_ERA;

  const domain = useENSStore(s =>
    destinationName
      ? s.defaultDomain[destinationName] || s.domains[destinationName]?.[0]
      : undefined
  );

  const getWalletDataByType = (type: NetworkTypes) => {
    switch (type) {
      case NetworkTypes.TON:
        return 'TON';
      case NetworkTypes.STARKNET:
        return 'StarkNet';
      case NetworkTypes.SOLANA:
        return 'Solana';
      case NetworkTypes.TRON:
        return 'Tron';
      case NetworkTypes.FUEL:
        return 'Fuel';
      default:
        return 'EVM';
    }
  };

  const walletName = getWalletDataByType(destinationType);
  const connectedIcon = useConnectedIconByNetworkType(destinationType);

  const onClickConnect = () => {
    if (destinationType === NetworkTypes.TON) {
      tonConnectModal.open();
    } else {
      setOpenModal(true);
    }
    setWalletToType(WalletToType.CurrentWallet);
  };

  const onClickConnected = () => {
    setWalletToType(WalletToType.CurrentWallet);
    setValue?.(connectedAddress ?? '');
  };

  const onClickInputAddress = () => {
    setWalletToType(WalletToType.InputAddress);
    setValue?.('');
  };

  if (!isFromConnected) {
    return (
      <div className="d-none justify-content-between inputGroup mb-3 text-white w-100 text-nowrap" />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between inputGroup text-white w-100 text-nowrap select-receiving-wallet-wrapper">
        {isToConnected && connectedAddress ? (
          <button
            onClick={onClickConnected}
            className={clsx(
              'leftWalletBtnDiv d-flex align-items-center leftWalletBtnDiv_connected',
              walletToType === WalletToType.CurrentWallet && 'active'
            )}
          >
            <div className="d-flex coinBtn wallet-icon-size me-2 z-1">
              <img
                width="20px"
                height="20px"
                src={connectedIcon}
                alt="network icon networkSmallIcon"
              />
            </div>
            <div className="d-flex flex-column z-1">
              <div className="text-white fw-bold wallet-btns-text-size connected-wallet-text text-start">
                Connected Wallet
              </div>
              <div className="fw-medium address connected-address">
                {domain && isToEVM ? domain : shortAddress(connectedAddress)}
              </div>
            </div>
          </button>
        ) : (
          <button
            onClick={onClickConnect}
            className={clsx(
              'leftWalletBtnDiv wallet-btns-text-size connect-starknet-btn',
              walletToType === WalletToType.CurrentWallet && 'active'
            )}
          >
            <span className="position-relative z-1">
              Connect {walletName} Wallet
            </span>
          </button>
        )}
        <button
          onClick={onClickInputAddress}
          className={clsx(
            'rightWalletBtnDiv text-white wallet-btns-text-size',
            walletToType === WalletToType.InputAddress && 'active'
          )}
        >
          <span className="z-1">Select Other Address</span>
        </button>
      </div>
      <WalletAddressInput
        isVisible={walletToType === WalletToType.InputAddress}
        {...props}
      />
      <ConnectWalletModalWithDisabled
        openModal={openModal}
        setOpenModal={setOpenModal}
        networkType={destinationType}
        onlySelectedNetworkType
      />
    </>
  );
}
