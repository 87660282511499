import { Loader } from '../Loader';
import './styles.css';

export function FullPageLoader() {
  return (
    <div className="full-page-loader">
      <Loader />
    </div>
  );
}
