import { rpcService } from '@/services/rpcService';
import { useEffect } from 'react';

export function RPCProvider() {
  useEffect(() => {
    rpcService.getRPCs();
  }, []);

  return null;
}
