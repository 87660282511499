import { useConnectors } from '@fuels/react';
import { OptionsProps } from '..';
import { getFuelConnectorIcon } from '@/fuel/utils/getConnectorIcon';

export function FuelOptions({ onOpenChange }: OptionsProps) {
  const { connectors } = useConnectors();
  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">Fuel wallets</div>
      <div className="connect-wallet--group-grid">
        {connectors.map(connector => (
          <button
            key={connector.name}
            className="connect-wallet--group-item w-100"
            onClick={() => {
              onOpenChange(false);
              connector.connect();
            }}
          >
            <div style={{ borderRadius: '50%', backgroundColor: 'white' }}>
              <img
                src={getFuelConnectorIcon(connector.name)}
                style={{
                  height: '24px',
                  width: '24px',
                  borderRadius: '50%',
                }}
                alt={connector.name}
              />
            </div>

            {connector.name}
          </button>
        ))}
      </div>
    </div>
  );
}
