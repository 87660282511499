import { SVGAttributes } from 'react';

export function ArrowDown(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6704 11.793L8.62595 7.71736L6.07999 7.71708V3.9977V3.92711V0.499985H3.26067V3.92738V7.71708L0.714294 7.71736L4.6704 11.793Z"
        fill="white"
      />
    </svg>
  );
}
