import { NetworkTypes } from '@/providers/web3Provider';
import { INetwork } from '@/types/apiTypes';

export const mockTron: INetwork = {
  id: 'ac543856-8112-498d-ae46-a328072c6d34',
  name: 'Tron',
  chainId: '1000',
  network_image_url: '/static/networks/tron.png',
  category: 'L1 Non-EVM Networks',
  blockscan_url: 'https://tronscan.org/',
  token_symbol: 'TRX',
  token_decimals: 6,
  network_type: NetworkTypes.TRON,
  active: false,
  gasPrice: '20000000000',
  nativeTransferGas: '100000',
  created_at: '2024-09-12T16:00:00.000Z',
};
