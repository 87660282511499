export const Network = ({ url, name }: { url: string; name: string }) => {
  return (
    <div className="d-flex align-items-center tableFont marginXAuto">
      <div className="smallCircleBtn smallCircleBtnCoinIcon me-2 ">
        <img src={url} className="coinImg" alt="coin" />
      </div>
      <div className="ps-1  me-2">{name}</div>
    </div>
  );
};
