type BlockscanRoutes = {
  tx: string;
  address: string;
};

export const getBlockscanRoutes = (blockscanUrl: string) => {
  const routes: BlockscanRoutes = {
    tx: blockscanUrl + 'tx/',
    address: blockscanUrl + 'address/',
  };

  switch (true) {
    case blockscanUrl.includes('starkscan.co'):
      routes.address = blockscanUrl + 'contract/';
      break;
    case blockscanUrl.includes('tonviewer.com'):
      routes.tx = blockscanUrl + 'transaction/';
      routes.address = blockscanUrl;
      break;
    case blockscanUrl.includes('tronscan.org'):
      routes.tx = blockscanUrl + 'transaction/';
      break;
    case blockscanUrl.includes('fuel'):
      routes.address = blockscanUrl + 'account/';
      break;
    default:
      break;
  }

  return routes;
};
