import { Button } from 'react-bootstrap';
import copy from '@/Assets/Icons/copy.svg';
import share from '@/Assets/Icons/share.svg';
import { notify } from '@/services/toast';
import { shortAddress } from '@/utils';
import '../../Explorer.css';
import { getBlockscanRoutes } from '@/utils/getBlockscanRoutes';

export const ModalInfoField = ({
  networkUrl,
  netwAddress,
  netwName,
  txHash,
  scanLink,
}: {
  networkUrl: string;
  netwAddress: string;
  netwName: string;
  txHash: string;
  scanLink: string;
}) => {
  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(netwAddress);
    notify('Copied');
  };

  const blockscanRoutes = getBlockscanRoutes(scanLink);
  const linkHref = `${blockscanRoutes.tx}${txHash}`;

  return (
    <div className="networkInfoContainer networkInfoContainerBg">
      <div className="networkInfoLeftDiv">
        <div className="imgDiv20 me-1">
          <img className="coinImg" src={networkUrl} alt="network icon" />
        </div>

        <div className="d-flex flex-column ms-2">
          <div className="infoText">{netwName}</div>
          <div className="infoText secondarytext" onClick={copyToClipboard}>
            {shortAddress(netwAddress, 20)}{' '}
            <img src={copy} className="copyIcon cursor" alt="" />
          </div>
        </div>
      </div>
      {!!txHash?.length && (
        <a href={linkHref} target="_blank" rel="noreferrer">
          <Button variant="link" className="linkBtn">
            <img className="shareImg" src={share} alt="" />
          </Button>
        </a>
      )}
    </div>
  );
};
