import { SVGAttributes } from 'react';

export function Shield(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10 0L1.20752 2.50879V6.91676C1.20752 12.5791 4.57213 17.6788 9.76557 19.9062L10 20L10.2345 19.9062C15.4279 17.6788 18.7925 12.5791 18.7925 6.91676V2.50879L10 0Z"
        fill="#4C3DA6"
      />
      <path
        d="M18.7925 2.50879V6.91676C18.7925 12.5791 15.4279 17.6788 10.2345 19.9062L10 20V0L18.7925 2.50879Z"
        fill="#7A72B0"
      />
      <path
        d="M10.0001 2.43848L3.55225 4.27906V6.9168C3.55225 11.2544 5.92041 15.2169 9.71873 17.2802L10.0001 17.4326L10.2815 17.2802C14.0798 15.2169 16.4479 11.2544 16.4479 6.9168V4.27906L10.0001 2.43848Z"
        fill="white"
        fillOpacity="0.32"
      />
      <path
        d="M16.4478 4.27906V6.9168C16.4478 11.2544 14.0796 15.2169 10.2813 17.2802L10 17.4326V2.43848L16.4478 4.27906Z"
        fill="white"
        fillOpacity="0.2"
      />
      <g clipPath="url(#clip0_8572_6464)">
        <path
          d="M11.75 8.75H8.25C7.97386 8.75 7.75 8.97386 7.75 9.25V11C7.75 11.2761 7.97386 11.5 8.25 11.5H11.75C12.0261 11.5 12.25 11.2761 12.25 11V9.25C12.25 8.97386 12.0261 8.75 11.75 8.75Z"
          fill="#4130A9"
          stroke="#4130A9"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 8.75V7.75C8.75 7.41848 8.8817 7.10054 9.11612 6.86612C9.35054 6.6317 9.66848 6.5 10 6.5C10.3315 6.5 10.6495 6.6317 10.8839 6.86612C11.1183 7.10054 11.25 7.41848 11.25 7.75V8.75"
          stroke="#4130A9"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <mask
        id="mask0_8572_6464"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="10"
        y="2"
        width="7"
        height="16"
      >
        <path
          d="M16.4478 4.27906V6.9168C16.4478 11.2544 14.0796 15.2169 10.2813 17.2802L10 17.4326V2.43848L16.4478 4.27906Z"
          fill="white"
          fillOpacity="0.8"
        />
      </mask>
      <g mask="url(#mask0_8572_6464)">
        <g clipPath="url(#clip1_8572_6464)">
          <path
            d="M11.75 8.75H8.25C7.97386 8.75 7.75 8.97386 7.75 9.25V11C7.75 11.2761 7.97386 11.5 8.25 11.5H11.75C12.0261 11.5 12.25 11.2761 12.25 11V9.25C12.25 8.97386 12.0261 8.75 11.75 8.75Z"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.75 8.75V7.75C8.75 7.41848 8.8817 7.10054 9.11612 6.86612C9.35054 6.6317 9.66848 6.5 10 6.5C10.3315 6.5 10.6495 6.6317 10.8839 6.86612C11.1183 7.10054 11.25 7.41848 11.25 7.75V8.75"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8572_6464">
          <rect width="6" height="6" fill="white" transform="translate(7 6)" />
        </clipPath>
        <clipPath id="clip1_8572_6464">
          <rect width="6" height="6" fill="white" transform="translate(7 6)" />
        </clipPath>
      </defs>
    </svg>
  );
}
