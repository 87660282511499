import { UIWallet } from '@tonconnect/ui-react';

/**
 * @see https://github.com/ton-blockchain/wallets-list/pull/43
 */
export const tobiFunTON: UIWallet = {
  appName: 'tobi',
  name: 'Tobi',
  tondns: 'tobi.fun',
  imageUrl: 'https://app.tobiwallet.app/icons/logo.png',
  aboutUrl: 'https://tobi.fun',
  platforms: ['ios', 'android'],
  deepLink: 'https://t.me/TobiWalletBot/wallet',
  universalLink: '',
  bridgeUrl: 'https://bridge.tonapi.io/bridge',
};
