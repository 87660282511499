import { rpcStore } from '@/stores/rpc.store';
import { logInGroup } from '@/utils';
import { BASE_URL } from '../Config/ApiConfig';
import { ApiService as Api } from './CoreApi';
import { RPCItem } from '@/types/rpc';

export class RPCService {
  private _api: Api;
  constructor() {
    this._api = new Api(BASE_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    this._api.instance.defaults.headers.put['Content-Type'] =
      'application/json';
  }

  async getRPCs() {
    const { setIsLoading, setRPCs } = rpcStore.getState();
    setIsLoading(true);
    try {
      const url = '/api/network/rpcs_for_frontend';

      const data = await this._api.get<RPCItem[]>(url);

      setRPCs(data);
      return data;
    } catch (error) {
      logInGroup('getRPCs', error);
    } finally {
      setIsLoading(false);
    }
    return [];
  }
}

export const rpcService = new RPCService();
