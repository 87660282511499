import discord from '@/Assets/Icons/discord.svg';
import globe from '@/Assets/Icons/globe.svg';
import OutlinedPlusIcon from '@/Assets/Icons/outlinedPlusIcon.svg';
import ConfirmCheckIcon from '@/Assets/Icons/statusPage/confirmCheckIcon.svg';
import RejectCrossIcon from '@/Assets/Icons/statusPage/rejectCrossIcon.svg';
import { BASE_URL, stage } from '@/Config/ApiConfig';
import { GET_POPUP_PARAMS, GET_POPUP_TYPE } from '@/constants/popup.constants';
import { ROUTES } from '@/constants/routes.constants';
import { useNotify } from '@/hooks/useToast';
import { NetworkTypes } from '@/providers/web3Provider';
import { isDevelopment } from '@/services/api';
import { useAppStore } from '@/stores/app.store';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { useWalletStore } from '@/stores/wallet.store';
import { OrderStatuses } from '@/types/enums';
import { EthersError } from '@/types/ethers';
import { exponentNumberToString, toFixed } from '@/utils/numbers';
import { errors } from 'ethers';
import { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useAccount, useSwitchChain, useToken, useWalletClient } from 'wagmi';
import { NetworkLoaderItem } from '../NetworkLoaderItem';
import './StatusPage.css';
import { DAppBanner } from './components/DAppBanner';
import { PhaseBanner } from './components/PhaseBanner';
import { isOneDomain } from '@/Config';

const excludeNativeTokens = ['eth', 'btc', 'ape'];

function StatusForm() {
  const navigate = useNavigate();

  const { currentOrder } = useWalletStore();
  const [isCancelled, setIsCancelled] = useAppStore(s => [
    s.isCancelled,
    s.setIsCancelled,
  ]);

  const { prices } = usePriceUpdater();

  const { isConnected, chain } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const { data: walletClient } = useWalletClient();
  const { notify } = useNotify();
  const outChainId = currentOrder?.currency_out.contract.network.chainId;
  const { data: tokenOut } = useToken({
    address:
      (currentOrder?.currency_out.contract.address as `0x${string}`) ?? '0x',
    chainId: outChainId ? +outChainId : undefined,
  });

  const amountInUsd = useMemo(() => {
    if (!currentOrder) {
      return 0;
    }
    return (
      getPrice(currentOrder.currency_in.symbol, prices).usd *
      currentOrder.amount_in
    );
  }, [currentOrder, prices]);

  const amountOutUsd = useMemo(() => {
    if (!currentOrder) {
      return 0;
    }
    return (
      getPrice(currentOrder.currency_out.symbol, prices).usd *
      currentOrder.amount_out
    );
  }, [currentOrder, prices]);

  if (!currentOrder) {
    return (
      <div className=" formBg defaultRadius  ">
        <Form className="transitionHeight statusFormBody text-light bg-opacity-25 defaultRadius align-items-center ">
          <div className="transferInfoTitle">Waiting for order loading...</div>
        </Form>
      </div>
    );
  }
  const twoStepNavigate = () => {
    setIsCancelled(false);

    if (isDevelopment || isOneDomain) {
      navigate(
        `${ROUTES.EXPLORER_PAGE}?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.order}&${GET_POPUP_PARAMS.orderId}=${currentOrder.id}`
      );
    } else {
      window.open(
        `${ROUTES.EXPLORER_PAGE}?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.order}&${GET_POPUP_PARAMS.orderId}=${currentOrder.id}`,
        '_blank'
      );
    }
  };

  const rejected =
    isCancelled ||
    currentOrder.status === OrderStatuses.REJECTED ||
    currentOrder.status === OrderStatuses.EXPIRED ||
    currentOrder.status === OrderStatuses.DEPOSIT_FAILED;

  const shouldAddToken =
    isConnected &&
    currentOrder.status === OrderStatuses.COMPLETE &&
    [NetworkTypes.EVM, NetworkTypes.ZK_SYNC_ERA].includes(
      currentOrder.currency_out.contract.network.network_type
    ) &&
    !excludeNativeTokens.includes(
      currentOrder.currency_out.symbol.toLowerCase()
    );

  const handleAddToken = async () => {
    if (!chain || !switchChainAsync || !walletClient || !tokenOut) return;

    try {
      if (chain.id !== +currentOrder.currency_out.contract.network.chainId) {
        await switchChainAsync({
          chainId: +currentOrder.currency_out.contract.network.chainId,
        });
      }

      const success: boolean = await walletClient.watchAsset({
        type: 'ERC20',
        options: {
          address: currentOrder.currency_out.contract.address,
          symbol: tokenOut.symbol ?? '',
          decimals: currentOrder.currency_out.decimals,
          image: BASE_URL + currentOrder.currency_out.image_url,
        },
      });

      if (success) {
        notify({
          meassage: 'Token added successfully',
          type: 'success',
        });
      }
    } catch (error) {
      if ((error as EthersError).code === errors.ACTION_REJECTED) return;
      console.warn(error);
    }
  };

  const onBtnClick = async () => {
    if (rejected) {
      const params = new URLSearchParams();
      params.set('source', currentOrder.currency_in.contract.network.name);
      params.set(
        'destination',
        currentOrder.currency_out.contract.network.name
      );

      setIsCancelled(false);
      if (stage !== 'development') {
        window.open(ROUTES.SEND_PAGE + '?' + params.toString(), '_self');
      } else {
        navigate(ROUTES.SEND_PAGE + '?' + params.toString());
      }
      return;
    }

    setIsCancelled(false);
    navigate(ROUTES.SEND_PAGE);
  };

  return (
    <div className="formBg mt-0 defaultRadius gradient-border-mask status-success-form-margin status-success-width">
      <Form className="transitionHeight formBody text-light bg-opacity-25 d-flex h-100 flex-column   defaultRadius align-items-center statusForm">
        <div className="statusPageInfoTitle">
          {rejected ? 'Transfer Reject' : 'Transfer Complete'}
        </div>

        <p className="statusPageDescription">
          {rejected ? (
            <span>
              Unfortunately, your recent attempt to <br /> bridge has been
              declined
            </span>
          ) : (
            <span>
              Your bridging has been successfully completed. <br />
              {toFixed(currentOrder.amount_out ?? 0, 5)}{' '}
              {currentOrder.currency_out.symbol} has been sent to your address
            </span>
          )}
        </p>

        <div className="transferInfoWrapper">
          <div className="statusInfoContent">
            <div className="transferInfoItem">
              <NetworkLoaderItem
                status={rejected ? 'failed' : 'success'}
                networkSrc={
                  BASE_URL +
                  currentOrder.currency_in.contract.network.network_image_url
                }
                network={currentOrder.currency_in.contract.network.name ?? ''}
                wallet={currentOrder.wallet_sender!}
                amount={`${currentOrder.amount_in}`}
                amountUsd={`${amountInUsd}`}
                coinSymbol={currentOrder.currency_in.symbol ?? ''}
                imgSrc={BASE_URL + currentOrder.currency_in.image_url}
                withoutLoader={false}
                transaction={currentOrder.transaction_in}
                blockScan={
                  currentOrder.currency_in.contract.network.blockscan_url
                }
              />
            </div>

            <div className="statusPageIconWrapper">
              <div className="statusPageIcon">
                <img
                  src={rejected ? RejectCrossIcon : ConfirmCheckIcon}
                  alt=""
                />
              </div>
            </div>

            <div className="transferInfoItem">
              <NetworkLoaderItem
                status={rejected ? 'failed' : 'success'}
                networkSrc={
                  BASE_URL +
                  currentOrder.currency_out.contract.network.network_image_url
                }
                network={currentOrder.currency_out.contract.network.name ?? ''}
                wallet={currentOrder.wallet_receiver ?? ''}
                amount={exponentNumberToString(currentOrder.amount_out)}
                amountUsd={`${amountOutUsd}`}
                coinSymbol={currentOrder.currency_out.symbol}
                imgSrc={BASE_URL + currentOrder.currency_out.image_url}
                withoutLoader={false}
                transaction={currentOrder.transaction_out}
                blockScan={
                  currentOrder.currency_out.contract.network.blockscan_url
                }
              />
            </div>
          </div>
        </div>

        <div className="statusPageDappBanner">
          <DAppBanner network={currentOrder.currency_out.contract.network} />
          {/* {currentOrder.currency_out.symbol?.toLowerCase() === 'eth' && ( */}
          <PhaseBanner />
          {/* )} */}
        </div>

        <div className="transfer-btn-group">
          <Button
            onClick={onBtnClick}
            variant="outline-secondary"
            className={'create-another-transfer-button bold'}
          >
            <img src={OutlinedPlusIcon} alt="" /> Another Transfer
          </Button>
          {shouldAddToken && (
            <button
              type="button"
              className="btn py-2 add-token-button"
              onClick={handleAddToken}
            >
              Add{' '}
              <span className="add-token-button-icon">
                <img
                  src={`${BASE_URL}${currentOrder.currency_out.image_url}`}
                  alt=""
                />
              </span>{' '}
              {currentOrder.currency_out.symbol} to wallet
            </button>
          )}
        </div>
        {!rejected && (
          <div className="center">
            <Button
              variant="link"
              className="backBtn ms-auto me-auto view-in-explorer-btn"
              onClick={twoStepNavigate}
            >
              <div className="d-flex align-items-center">
                <img src={globe} className="globe" alt="back button" />
                <div className="ms-2 view-in-explorer-btn-text">
                  View In RetroBridge Explorer
                </div>
              </div>
            </Button>
          </div>
        )}
        {rejected && (
          <div className="d-flex justify-content-center align-items-center ms-auto me-auto ">
            <a
              href="http://discord.gg/retrobridge"
              target="_blank"
              rel="noopener noreferrer"
              className="progressAction"
            >
              <img src={discord} alt="" />
              Support
            </a>
          </div>
        )}
      </Form>
    </div>
  );
}

export default StatusForm;
