import { PopoverContent, PopoverPortal, Root } from '@radix-ui/react-popover';
import { ReactNode } from 'react';
import { useAdvancedProgressStore } from '../../../store';
import { ProgressOrderItem } from './ProgressOrderItem';
import SimpleBar from 'simplebar-react';
import { AnimatePresence, motion } from 'framer-motion';

interface ProgressOrdersPopoverProps {
  children: ReactNode;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ProgressOrdersPopover({
  children,
  isOpen,
  onOpenChange,
}: ProgressOrdersPopoverProps) {
  const currentOrder = useAdvancedProgressStore(s => s.currentOrder);

  return (
    <Root open={isOpen} onOpenChange={onOpenChange}>
      {children}
      <AnimatePresence>
        {isOpen && (
          <PopoverPortal forceMount>
            <PopoverContent avoidCollisions={false} sideOffset={10} asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="progress-orders-menu"
              >
                <SimpleBar
                  style={{
                    maxHeight: '284px',
                    paddingRight: '20px',
                    height: '100%',
                  }}
                >
                  <motion.div
                    variants={{
                      animate: {
                        transition: {
                          delayChildren: 0.15,
                          staggerChildren: 0.05,
                        },
                      },
                    }}
                    animate="animate"
                    className="d-flex flex-column gap-3"
                  >
                    {currentOrder?.receiving_data.map((order, index) => (
                      <motion.div
                        initial={{
                          opacity: 0,
                          filter: 'blur(10px)',
                        }}
                        variants={{
                          animate: {
                            opacity: 1,
                            filter: 'blur(0px)',
                          },
                        }}
                        key={index}
                      >
                        <ProgressOrderItem order={order} />
                      </motion.div>
                    ))}
                  </motion.div>
                </SimpleBar>
              </motion.div>
            </PopoverContent>
          </PopoverPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}
