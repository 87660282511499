import { FeeTooltip } from '@/components/tooltips/FeeTooltip';
import { ROUND_TOKENS } from '@/constants/tokens';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { OrderStatuses } from '@/types/enums';
import { getMinimalDecimalNumber, toFixed } from '@/utils/numbers';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';
import '../../Explorer.css';

interface IExplorerModalInfoBlockProps {
  status: OrderStatuses;
  fee: number;
  feeInUSD: number;
  swapFee: number;
  swapFeeUsd: number;
  destinationFee: number;
  time: string;
  currency: string;
  destinationCurrency: string;
  holderOfNFT?: string;
  originalPrice?: number;
}

function ExplorerModalInfoBlock({
  status,
  fee,
  feeInUSD,
  swapFee,
  swapFeeUsd,
  destinationFee,
  time,
  currency,
  destinationCurrency,
  holderOfNFT,
  originalPrice,
}: IExplorerModalInfoBlockProps) {
  const { width } = useWindowSize();
  const getStatusColor = useMemo(() => {
    switch (status) {
      case OrderStatuses.COMPLETE:
        return 'greenColor';
      case OrderStatuses.DEPOSITED:
      case OrderStatuses.PENDING:
      case OrderStatuses.WAIT_DEPOSIT:
      case OrderStatuses.SENDING:
      case OrderStatuses.SENT:
        return 'orangeColor';
      default:
        return 'redColor';
    }
  }, [status]);

  const shouldRoundToken = ROUND_TOKENS.includes(currency.toLowerCase());
  const shouldRoundTokenDestination = ROUND_TOKENS.includes(
    destinationCurrency.toLowerCase()
  );
  const { prices } = usePriceUpdater();

  const destinationFeeInUSD = useMemo(() => {
    const destinationPrice = getPrice(destinationCurrency, prices);

    const destinationFeePrice = getMinimalDecimalNumber(
      destinationPrice.usd * destinationFee
    );
    return destinationFeePrice;
  }, [prices, destinationFee, destinationCurrency]);

  const NFTName = useMemo(() => {
    const NFTName = holderOfNFT;
    if (width <= 480 && NFTName) {
      return NFTName.split(' ').slice(1).join(' ');
    }

    return NFTName;
  }, [width, holderOfNFT]);

  return (
    <div
      className={
        'overflow-hidden ps-4 p-3  mb-4 textSecondary fs-6 transitionHeight d-flex flex-column  pb-2  infoBlock infoBlockcontainer'
      }
    >
      <div className="d-flex flex-row justify-content-between  align-items-center mb-2 ">
        <div className="  textSecondaryExplorerModal"> Status</div>

        <div className={`statusText ${getStatusColor}`}>{status}</div>
      </div>
      <div className="d-flex flex-row align-items-center textSecondaryExplorerModal mb-2">
        RetroBridge Fee
        {holderOfNFT && (
          <div className="ms-2 fee-details--nft-badge late">{NFTName}</div>
        )}
        <div className="ms-auto text-light statusText">
          {holderOfNFT && +toFixed(feeInUSD, 2) !== originalPrice && (
            <span className="me-2 fee-details--value-usd text-decoration-line-through opacity-50">
              ${originalPrice}
            </span>
          )}
          <span
            className={clsx(
              'statusTextSecondary me-2',
              holderOfNFT &&
                +toFixed(feeInUSD, 2) !== originalPrice &&
                'text-gradient-sunrise'
            )}
          >
            ${toFixed(feeInUSD, 2)}
          </span>
          {`${
            shouldRoundToken
              ? Number(fee).toFixed(2)
              : getMinimalDecimalNumber(fee)
          } ${currency}`}
        </div>
      </div>
      {swapFee > 0 && (
        <div className="d-flex flex-row justify-content-between  align-items-center textSecondaryExplorerModal mb-2">
          Swap fee
          <div className=" text-light statusText">
            <span className="statusTextSecondary me-2">
              ${toFixed(swapFeeUsd, 2)}
            </span>
            {`${
              shouldRoundToken
                ? Number(swapFee).toFixed(2)
                : getMinimalDecimalNumber(swapFee)
            } ${currency}`}
          </div>
        </div>
      )}
      <div className="d-flex flex-row justify-content-between  align-items-center textSecondaryExplorerModal mb-2">
        Destination Chain Fee
        <FeeTooltip />
        <div className=" text-light statusText">
          <span className="statusTextSecondary me-2">
            ${destinationFeeInUSD}
          </span>
          {`${
            shouldRoundTokenDestination
              ? Number(destinationFee).toFixed(2)
              : getMinimalDecimalNumber(destinationFee)
          } ${destinationCurrency}`}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center textSecondaryExplorerModal mb-2">
        Time Spent
        <div className=" text-light statusText">{time}</div>
      </div>
    </div>
  );
}

export default ExplorerModalInfoBlock;
