import { create } from 'zustand';
import { Journey, Phase, PhaseTask } from '../../types/phases';

interface PhaseStore {
  activeTabId: Journey['id'] | null;
  selectedJourney: Journey | null;
  isLoading: boolean;

  tasks: {
    [key: Journey['id']]: PhaseTask[] | null;
  };

  phase: Phase;
  journeys: Journey[];

  isTwitterConnected: boolean;
  uniqueParticipants: number;
  isNFTEligible: boolean;
  isNFTClaimed: boolean;
  NFTClaimedNetwork: string | null;
  isNFTLoading: boolean;

  amountInPool?: number;
  setAmountInPool: (amount: number) => void;

  setPhase: (phase: Phase) => void;

  setActiveTab: (tab: Journey['id']) => void;

  setIsLoading: (isLoading: boolean) => void;

  setTasks: (tasks: PhaseTask[], journeyId: Journey['id']) => void;

  setTwitterConnected: (isConnected: boolean) => void;
  setIsNFTEligible: (canClaimNFT: boolean) => void;
  setIsNFTClaimed: (isNFTMinted: boolean) => void;
  setNFTClaimedNetwork: (network: string) => void;
  setIsNFTLoading: (isNFTLoading: boolean) => void;
  setTaskStatus: (taskId: number, status: boolean) => void;
  incrementAmountOfHolders: (journeyId: Journey['id']) => void;
  disableJourney: (journeyId: Journey['id']) => void;
  reset: (initialPhase: Phase) => void;
}

export const phaseStore = create<PhaseStore>((set, get) => ({
  activeTabId: null,
  selectedJourney: null,
  isLoading: false,

  tasks: {},

  phase: Phase.Sunrise,
  journeys: [],

  isTwitterConnected: false,
  uniqueParticipants: 0,

  isNFTEligible: false,
  isNFTClaimed: false,
  NFTClaimedNetwork: null,
  isNFTLoading: false,

  amountInPool: undefined,
  setAmountInPool: amount => set({ amountInPool: amount }),

  setPhase: phase => set({ phase }),

  setActiveTab: tab => {
    const selectedJourney = get().journeys.find(journey => journey.id === tab);

    if (!selectedJourney) return;

    set({ activeTabId: tab, selectedJourney });
  },
  setIsLoading: isLoading => set({ isLoading }),

  setTasks: (tasks, journeyId) => {
    const storedTasks = get().tasks;

    set({ tasks: { ...storedTasks, [journeyId]: tasks } });
  },

  setTwitterConnected: isConnected => set({ isTwitterConnected: isConnected }),

  setIsNFTEligible: canClaimNFT => set({ isNFTEligible: canClaimNFT }),
  setIsNFTClaimed: isNFTMinted => set({ isNFTClaimed: isNFTMinted }),
  setNFTClaimedNetwork: network => set({ NFTClaimedNetwork: network }),
  setIsNFTLoading: isNFTLoading => set({ isNFTLoading }),

  setTaskStatus: (taskId, status) => {
    const activeJourney = get().journeys.find(journey => journey.isActive);

    if (!activeJourney) return;

    const tasks = get().tasks[activeJourney.id];

    if (!tasks) return;

    const taskIndex = tasks.findIndex(task => task.id === taskId);
    tasks[taskIndex].isCompleted = status;
    get().setTasks(tasks, activeJourney.id);
  },

  incrementAmountOfHolders: journeyId => {
    const journey = get().journeys.find(j => j.id === journeyId);

    if (!journey) return;

    journey.countOfNftHolders += 1;

    set({
      journeys: get().journeys.map(j => (j.id === journeyId ? journey : j)),
    });
  },
  disableJourney: journeyId => {
    const newSelectedJourney = get().selectedJourney;

    if (newSelectedJourney?.id === journeyId) {
      newSelectedJourney.isActive = false;
    }

    set({
      selectedJourney: newSelectedJourney,
      journeys: get().journeys.map(j =>
        j.id === journeyId ? { ...j, isActive: false } : j
      ),
    });
  },
  reset: initialPhase =>
    set({
      activeTabId: null,
      selectedJourney: null,
      isLoading: true,
      tasks: {},
      phase: initialPhase,
      journeys: [],
      isTwitterConnected: false,
      uniqueParticipants: 0,
      isNFTEligible: false,
      isNFTClaimed: false,
      NFTClaimedNetwork: null,
      isNFTLoading: false,
    }),
}));

export const usePhaseStore = phaseStore;
