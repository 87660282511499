import { useAdvancedProgressStore } from '@/pages/ProgressAdvancedPage/store';
import { ensService } from '@/services/ensService';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import { useAppStore } from '@/stores/app.store';
import { useENSStore } from '@/stores/ens.store';
import { useWalletStore } from '@/stores/wallet.store';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount as useEVMAccount } from 'wagmi';
import { useShallow } from 'zustand/react/shallow';

export function ENSNamesProvider() {
  const { address: EVMAddress } = useEVMAccount();
  const { pathname } = useLocation();
  const { currentOrder } = useWalletStore();
  const [advancedCurrentOrder] = useAdvancedProgressStore(s => [
    s.currentOrder,
  ]);
  const [regularNetworkName, isAdvancedOpen] = useAppStore(s => [
    s.networkFrom?.name,
    s.isAdvancedOpen,
  ]);
  const [advancedNetworkName] = useAdvancedWalletStore(s => [
    s.networkFrom?.name,
  ]);
  const [resetStore] = useENSStore(useShallow(s => [s.resetStore]));

  useEffect(() => {
    resetStore();
  }, [EVMAddress]);

  useEffect(() => {
    let networkName = isAdvancedOpen ? advancedNetworkName : regularNetworkName;

    if (
      currentOrder?.currency_in.contract.network.name &&
      pathname.startsWith('/progress')
    ) {
      networkName = currentOrder?.currency_in.contract.network.name;
    }

    if (
      advancedCurrentOrder?.order.currency_in.contract.network.name &&
      pathname.startsWith('/progress')
    ) {
      networkName =
        advancedCurrentOrder.order.currency_in.contract.network.name;
    }

    if (!EVMAddress || !networkName) return;

    ensService.getENSNames(EVMAddress, networkName);
  }, [
    EVMAddress,
    isAdvancedOpen,
    regularNetworkName,
    advancedNetworkName,
    currentOrder?.currency_in.contract.network.name,
    advancedCurrentOrder?.order.currency_in.contract.network.name,
    pathname,
  ]);

  return null;
}
