import {
  GET_POPUP_PARAMS,
  GET_POPUP_TYPE,
} from '../../constants/popup.constants';
import useGetParams from '../../hooks/useGetParams';
import ExplorerModal from '../../pages/ExplorerPage/components/ExplorerModal';

const popups = {
  [GET_POPUP_TYPE.popup.order]: ExplorerModal,
};

const GetParameterPopups = () => {
  const popupName = useGetParams(GET_POPUP_PARAMS.popup);

  const Component = popupName ? popups[popupName] : null;

  return Component ? <Component show={true} /> : null;
};

export default GetParameterPopups;
