import { useRPCStore } from '@/stores/rpc.store';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import {
  FractalWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { useMemo } from 'react';

export type SolanaWalletProviderProps = {
  children: React.ReactNode;
};

export const SolanaWalletProvider = ({
  children,
}: SolanaWalletProviderProps) => {
  const RPCs = useRPCStore(s => s.RPCs);
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const endpoint =
    RPCs.find(n => n.id === 'b9881297-f8f7-4a92-a0de-5f474cb8e199')?.rpc ??
    'https://solana-mainnet.g.alchemy.com/v2/vzn95O1Flu6uqHGxltZo-Ir9sBUCe7Lc';

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new FractalWalletAdapter(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endpoint]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        {children}
      </WalletProvider>
    </ConnectionProvider>
  );
};
