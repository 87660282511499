import { Workbox } from 'workbox-window';

export default function registerServiceWorker() {
  if ('production' !== process.env.NODE_ENV) {
    return;
  }
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('service-worker.js');

    wb.register().then(reg => reg?.update());
  }
}
