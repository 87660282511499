import { RPCItem } from '@/types/rpc';
import { create } from 'zustand';

export interface RPCStore {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  /**
   * Array of available RPCs
   */
  RPCs: RPCItem[];
  setRPCs: (RPCs: RPCItem[]) => void;
}

export const rpcStore = create<RPCStore>(set => ({
  isLoading: true,
  setIsLoading: (isLoading: boolean) => set({ isLoading }),

  RPCs: [],
  setRPCs: RPCs => set({ RPCs }),
}));

export const useRPCStore = rpcStore;
