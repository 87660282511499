import { create } from 'zustand';

export interface PhaseBanner {
  title: string;
  images: string[];
  isLoading: boolean;

  setContent: (title: string, images: string[]) => void;
  setLoading: (isLoading: boolean) => void;
}

export const phaseBannerStore = create<PhaseBanner>(set => ({
  title: '',
  images: [],
  isLoading: false,

  setContent: (title, images) => set({ title, images }),
  setLoading: isLoading => set({ isLoading }),
}));

export const usePhaseBannerStore = phaseBannerStore;
