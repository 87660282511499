import { useImgPreloader } from './useImagePreload';
import { useEffect, useState } from 'react';

export const useIsPageLoaded = (image: string) => {
  const { imgsPreloaded } = useImgPreloader([image]);
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (imgsPreloaded) setPageLoaded(true);
  }, [imgsPreloaded]);

  return pageLoaded;
};
