import { SVGAttributes } from 'react';

export function Exchange(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3852 20L14 15.245L11.0297 15.2447V10.9054V10.8231V6.82476H7.74052V10.8234V15.2447L4.76974 15.245L9.3852 20ZM4.61529 0L9.22993 4.75497L6.25965 4.75529V9.09458V9.17677V13.1752H2.97061V9.17661V4.75529L0 4.75497L4.61529 0Z"
        fill="white"
      />
    </svg>
  );
}
