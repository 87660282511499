import { SVGAttributes } from 'react';

export function DocumentWithContentIcon({
  color = '#E5C4FF',
  ...props
}: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_7190_6659)">
        <path
          d="M12.6667 16.5H3.33333C2.8029 16.5 2.29419 16.2893 1.91911 15.9142C1.54404 15.5391 1.33333 15.0304 1.33333 14.5V2.5C1.33333 1.96956 1.54404 1.46086 1.91911 1.08578C2.29419 0.71071 2.8029 0.499996 3.33333 0.499996L9.44666 0.499996C9.97692 0.500463 10.4853 0.711483 10.86 1.08666L14.08 4.30666C14.4552 4.68138 14.6662 5.18974 14.6667 5.72V14.5C14.6667 15.0304 14.4559 15.5391 14.0809 15.9142C13.7058 16.2893 13.1971 16.5 12.6667 16.5ZM3.33333 1.83333C3.15652 1.83333 2.98695 1.90357 2.86192 2.02859C2.7369 2.15362 2.66666 2.32319 2.66666 2.5V14.5C2.66666 14.6768 2.7369 14.8464 2.86192 14.9714C2.98695 15.0964 3.15652 15.1667 3.33333 15.1667H12.6667C12.8435 15.1667 13.013 15.0964 13.1381 14.9714C13.2631 14.8464 13.3333 14.6768 13.3333 14.5V5.72C13.3338 5.63226 13.317 5.54528 13.2838 5.46406C13.2507 5.38283 13.2018 5.30896 13.14 5.24666L9.91999 2.02666C9.79224 1.90484 9.62317 1.83579 9.44666 1.83333H3.33333Z"
          fill={color}
        />
        <path
          d="M10 9.16667H5.99999C5.82318 9.16667 5.65361 9.09643 5.52859 8.97141C5.40357 8.84638 5.33333 8.67681 5.33333 8.5C5.33333 8.32319 5.40357 8.15362 5.52859 8.0286C5.65361 7.90357 5.82318 7.83334 5.99999 7.83334H10C10.1768 7.83334 10.3464 7.90357 10.4714 8.0286C10.5964 8.15362 10.6667 8.32319 10.6667 8.5C10.6667 8.67681 10.5964 8.84638 10.4714 8.97141C10.3464 9.09643 10.1768 9.16667 10 9.16667Z"
          fill={color}
        />
        <path
          d="M10 13.1667H5.99999C5.82318 13.1667 5.65361 13.0964 5.52859 12.9714C5.40357 12.8464 5.33333 12.6768 5.33333 12.5C5.33333 12.3232 5.40357 12.1536 5.52859 12.0286C5.65361 11.9036 5.82318 11.8333 5.99999 11.8333H10C10.1768 11.8333 10.3464 11.9036 10.4714 12.0286C10.5964 12.1536 10.6667 12.3232 10.6667 12.5C10.6667 12.6768 10.5964 12.8464 10.4714 12.9714C10.3464 13.0964 10.1768 13.1667 10 13.1667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7190_6659">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
