import { useMemo } from 'react';
import { PhaseItem } from '../JourneyItem';
import { Other } from '../Other';
import { PhasesBlock } from '../PhasesBlock';
import { Adventure } from '@/Config/adventures';

export function ActivitiesTab() {
  const activeAdventures = useMemo(() => {
    return [] as Adventure[];
  }, []);

  return (
    <div className="notifications-menu--tabs-content">
      <PhasesBlock />
      {activeAdventures.length > 0 && (
        <div className="notifications-menu--group">
          <div className="notifications-menu--group-heading">Adventures</div>
          {activeAdventures.map(adventure => (
            <PhaseItem
              key={adventure.phase}
              href={adventure.link}
              thumbnailSrc={adventure.nftImage}
              backgroundSrc={adventure.notificationBg}
              startDate={adventure.startDate}
              endDate={adventure.endDate}
            >
              {adventure.notificationText}
            </PhaseItem>
          ))}
        </div>
      )}
      <div className="notifications-menu--group">
        <div className="notifications-menu--group-heading">Scroll badges</div>
        <Other />
      </div>
    </div>
  );
}
