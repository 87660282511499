import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from 'axios';

export class ApiService {
  private _api: AxiosInstance;

  constructor(path: string, config?: Omit<CreateAxiosDefaults, 'url'>) {
    this._api = axios.create({ baseURL: path, ...config });
  }

  get instance() {
    return this._api;
  }

  async get<T>(url: string, config?: AxiosRequestConfig | undefined) {
    const result = await this._api.get<T>(url, config);
    return result.data;
  }

  async post<T, K extends {} = {}>(
    url: string,
    body?: K,
    config?: AxiosRequestConfig | undefined
  ) {
    const result = await this._api.post<T>(url, body, config);
    return result.data;
  }
  async put<T, K extends {} = {}>(
    url: string,
    body?: K,
    config?: AxiosRequestConfig | undefined
  ) {
    const result = await this._api.put<T>(url, body, config);
    return result.data;
  }
  async patch<T, K extends {} = {}>(
    url: string,
    body?: K,
    headers?: AxiosRequestConfig['headers']
  ) {
    const result = await this._api.patch<T>(url, body, { headers });
    return result.data;
  }

  async delete<T>(url: string) {
    const result = await this._api.delete<T>(url);
    return result.data;
  }
}
