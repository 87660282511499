import { create } from 'zustand';

interface TransactionTrackingNotificationState {
  isOpen: boolean;
  isHidden: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsHidden: (isHidden: boolean) => void;
}

export const transactionNotificationStore =
  create<TransactionTrackingNotificationState>(set => ({
    isOpen: false,
    isHidden: false,
    setIsOpen: isOpen => set({ isOpen }),
    setIsHidden: isHidden => set({ isHidden }),
  }));

export const useTransactionNotificationStore = transactionNotificationStore;
