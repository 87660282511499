import { BASE_URL } from '../../../../Config/ApiConfig';
import { IOrder } from '../../../../types/apiTypes';
import { OrderType } from '../../../../types/enums';
import { formatAmountBySymbol } from '../../../../utils/numbers';
import '../../Explorer.css';
import './styles.css';

export const CoinInfo = ({ order }: { order: IOrder }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="coin-info--tokens">
        {order.type === OrderType.SWAP && (
          <div className="coin-info--tokens-item">
            <img
              src={BASE_URL + order.currency_in.image_url}
              className="coinImg"
              alt="coin"
            />
          </div>
        )}
        <div className="coin-info--tokens-item">
          <img
            src={BASE_URL + order.currency_out.image_url}
            className="coinImg"
            alt="coin"
          />
        </div>
      </div>
      <div className="d-flex align-items-start flex-column">
        <div className="ps-2 me-2 tableFont">
          {formatAmountBySymbol(order.amount_in, order.currency_in.symbol)}{' '}
          {order.currency_in.symbol}
        </div>
        <div className="ps-2 textSecondaryTable me-2">
          {formatAmountBySymbol(order.amount_out, order.currency_out.symbol)}{' '}
          {order.currency_out.symbol}
        </div>
      </div>
    </div>
  );
};
