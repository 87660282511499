import CloseIcon from '@/Assets/Icons/close-icon.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { GET_POPUP_PARAMS, GET_POPUP_TYPE } from '@/constants/popup.constants';
import {
  ADVANCED_PROGRESS_PAGE,
  PROGRESS_PAGE,
  ROUTES,
} from '@/constants/routes.constants';
import { useWalletStore } from '@/stores/wallet.store';
import { IOrder } from '@/types/apiTypes';
import { OrderStatuses } from '@/types/enums';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce, useInterval } from 'usehooks-ts';
import { useMyHistoryPopoverStore } from '../Header/components/MyHistoryPopover/store';
import { Completed } from './icons/Completed';
import { Progress } from './icons/Progress';
import { RightArrow } from './icons/Rightarrow';
import { RoundProgress } from './icons/RoundProgress';
import eth from './icons/eth.png';
import usdc from './icons/usdc.png';
import usdt from './icons/usdt.png';

interface ITransactionItemProps {
  variant?: 'standalone' | 'popover';
  order: IOrder;
}

export function TransactionItem({
  order,
  variant = 'standalone',
}: ITransactionItemProps) {
  const { setOngoingOrder } = useWalletStore();
  const [ignoredOrders] = useMyHistoryPopoverStore(state => [
    state.ignoredOrders,
  ]);
  const shouldIgnore =
    ignoredOrders.includes(order.id) && variant !== 'popover';
  const [isOpen, setIsOpen] = useState(
    order.status !== OrderStatuses.REJECTED &&
      !shouldIgnore &&
      order.status !== OrderStatuses.EXPIRED &&
      order.status !== OrderStatuses.DEPOSIT_FAILED
  );

  const inOpenAnimated = useDebounce(
    isOpen &&
      order.status !== OrderStatuses.REJECTED &&
      order.status !== OrderStatuses.EXPIRED &&
      order.status !== OrderStatuses.DEPOSIT_FAILED &&
      !shouldIgnore,
    300
  );

  const status = useMemo(() => {
    switch (order?.status) {
      case OrderStatuses.COMPLETE:
        return 'completed';
      default:
        return 'in-progress';
    }
  }, [order?.status]);

  const [timer, setTimer] = useState(6);

  useInterval(
    () => {
      setTimer(prev => prev - 1);
      if (timer - 1 <= 0) {
        setIsOpen(false);
        setTimeout(() => {
          setOngoingOrder(order.id, undefined);
        }, 300);
      }
    },
    timer > 0 && status === 'completed' && variant === 'standalone'
      ? 1000
      : null
  );

  const symbolSrc = useMemo(() => {
    const symbol = order.currency_in.symbol.toLowerCase();

    switch (symbol) {
      case 'eth':
      case 'weth':
        return eth;
      case 'usdt':
        return usdt;
      case 'usdc':
        return usdc;

      default:
        return BASE_URL + '' + order?.currency_in.image_url;
    }
  }, [order.currency_in.symbol, order?.currency_in.image_url]);

  if (
    !inOpenAnimated ||
    (variant === 'standalone' &&
      (window.location.href.includes(PROGRESS_PAGE + '/' + order.id) ||
        window.location.href.includes(ADVANCED_PROGRESS_PAGE + '/' + order.id)))
  )
    return null;

  return (
    <div
      className={clsx(
        variant === 'standalone' && 'transaction-notification-item',
        variant === 'popover' && 'transaction-notification-item-popover',
        isOpen ? 'open' : 'close'
      )}
    >
      {status === 'in-progress' && variant === 'standalone' && (
        <button
          className="transaction-notification-item--close"
          onClick={() => setIsOpen(false)}
        >
          <img className="notification-img" alt="" src={CloseIcon} />
        </button>
      )}
      {status === 'completed' && variant === 'standalone' && (
        <div className="transaction-notification-item--close close-timer">
          <RoundProgress className="close-timer-progress" /> {timer}
        </div>
      )}
      <div className="transaction-notification-item--headline">
        <div className="token-icon">
          <img
            src={
              BASE_URL +
              '' +
              order?.currency_in.contract.network.network_image_url
            }
            alt=""
          />
        </div>
        {order?.currency_in.contract.network.name}
        <RightArrow />
        <div className="token-icon">
          <img
            src={`${BASE_URL}${order?.currency_out.contract.network.network_image_url}`}
            alt=""
          />
        </div>
        {order?.currency_out.contract.network.name}
      </div>
      <div className="transaction-notification-item--amount">
        <img src={symbolSrc} alt="" />
        {order?.amount_in ?? 0} {order?.currency_in.symbol}
      </div>
      <div className="transaction-notification-item--status">
        <div className={clsx('transaction-notification-badge', status)}>
          {status === 'in-progress' && (
            <>
              <Progress />
              Bridging in progress
            </>
          )}
          {status === 'completed' && (
            <>
              <Completed />
              Bridging completed
            </>
          )}
        </div>
        {status === 'completed' && (
          <Link
            to={`${ROUTES.EXPLORER_PAGE}?${GET_POPUP_PARAMS.popup}=${GET_POPUP_TYPE.popup.order}&${GET_POPUP_PARAMS.orderId}=${order.id}`}
            className="transaction-notification-item--action"
            target="_blank"
          >
            Details
            <RightArrow color="#E1C1FF" />
          </Link>
        )}
        {status === 'in-progress' && (
          <Link
            to={`${ROUTES.PROGRESS_PAGE}/${order?.id}`}
            className="transaction-notification-item--action"
          >
            Check Progress
            <RightArrow color="#E1C1FF" />
          </Link>
        )}
      </div>
    </div>
  );
}
