import { useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import discord from '@/Assets/Icons/discord.svg';
import redClose from '@/Assets/Icons/redClose.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { ROUTES } from '@/constants/routes.constants';
import { isDevelopment } from '@/services/api';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { NetworkInfo } from '../../../ProgressPage/components/NetworkInfo';
import { useAdvancedProgressStore } from '../../store';
import './styles.css';

export function ErrorView() {
  const [currentOrder, setIsCanceled] = useAdvancedProgressStore(s => [
    s.currentOrder,
    s.setIsCanceled,
  ]);
  const navigate = useNavigate();
  const { prices } = usePriceUpdater();

  const usdPrice = useMemo(() => {
    if (!currentOrder?.order.currency_in.symbol) {
      return 0;
    }

    return getPrice(currentOrder.order.currency_in.symbol, prices).usd;
  }, [currentOrder?.order.currency_in.symbol, prices]);

  const onBtnClick = async () => {
    if (isDevelopment) {
      navigate(`${ROUTES.SEND_PAGE}?mode=advanced`);
    } else {
      window.open(`${ROUTES.SEND_PAGE}?mode=advanced`, '_self');
    }
    setIsCanceled(false);
  };

  if (!currentOrder) return null;

  return (
    <div className="formBg defaultRadius formContainer  mt-0 send-advanced-form send-advanced-form-error send-advanced-progress-form h-100">
      <div className="d-flex justify-content-center align-items-center">
        <Form className="transitionHeight formBody text-lightbg-opacity-25 d-flex h-100 flex-column defaultRadius align-items-center statusForm w-100">
          <div className="rejectCircle center">
            <img src={redClose} alt="" className="redClose" />
          </div>

          <div className="statusTitle">Transfer Completed</div>
          <div className="statusSubtitle w-100">
            <span>
              Unfortunately, your recent attempt to bridge has been declined
            </span>
          </div>

          <div className="transactionInfoTableDiv transactions-list w-100">
            <div className="infoDiv-label">From</div>
            <div className="infoDiv">
              <NetworkInfo
                netwSrc={
                  BASE_URL +
                  currentOrder.order.currency_in.contract.network
                    .network_image_url
                }
                netw={
                  currentOrder.order.currency_in.contract.network.name ?? ''
                }
                wallet={currentOrder.wallet_sender!}
                amount={`${currentOrder.order.amount_in}`}
                amountUsd={`${+currentOrder.order.amount_in * usdPrice}`}
                coinSimbol={currentOrder.order.currency_in.symbol ?? ''}
              />
            </div>
          </div>

          <div className="transactionInfoTableDiv transactions-list w-100 mt-2">
            <div className="infoDiv-label">
              To{' '}
              <span className="text-white">
                ({currentOrder.receiving_data.length})
              </span>
            </div>
            <SimpleBar
              style={{
                maxHeight: '194px',
                height: '100%',
                marginRight: '-18px',
                paddingRight: '18px',
                marginBottom: 40,
              }}
            >
              <div className="d-flex flex-column gap-2">
                {currentOrder.receiving_data.map((data, i) => (
                  <div className="infoDiv" key={i}>
                    <NetworkInfo
                      netwSrc={
                        BASE_URL +
                        data.currency.contract.network.network_image_url
                      }
                      netw={data.currency.contract.network.name ?? ''}
                      wallet={data.wallet_receiver}
                      amount={`${data.amount_out}`}
                      amountUsd={`${+data.amount_out * usdPrice}`}
                      coinSimbol={data.currency.symbol ?? ''}
                    />
                  </div>
                ))}
              </div>
            </SimpleBar>
          </div>

          <div className="transfer-btn-group w-100 d-grid grid-cols-1 gap-2 m-0 mt-auto">
            <Button
              onClick={onBtnClick}
              variant="outline-secondary"
              className="btn btnSecondary w-100 m-0 btnGradient"
            >
              Create Another Advanced Transfer
            </Button>
            <div className="d-flex justify-content-center align-items-center ms-auto me-auto mt-2">
              <a
                href="http://discord.gg/retrobridge"
                target="_blank"
                rel="noopener noreferrer"
                className="progressAction"
              >
                <img src={discord} alt="" />
                Support
              </a>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
