import '@rainbow-me/rainbowkit/styles.css';
import { ToastContainer } from 'react-toastify';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './App.css';
import GetParameterPopups from './components/PopupManager';
import { HOME_PAGE } from './constants/routes.constants';
import { WalletConnectInteractorProvider } from './providers/web3Provider';
import { WagmiProvider } from 'wagmi';
import { AppRouter } from './Config/RouterConfig';
import { InitialUserApiKey } from './components/InitialUserApiKey';
import { TransactionTrackingNotification } from './components/TransactionTrackingNotification';
import { wagmiConfig } from './providers/rainbow';
import { StarknetConfig } from './starknet';
import { getRoutes } from './utils/getRoutes';
import { Suspense } from 'react';
import { FullPageLoader } from './components/FullPageLoader';
import { SolanaWalletProvider } from './solana/provider';
import { additionalTONWallets, tonManifestUrl } from './providers/ton';
import { ENSNamesProvider } from './providers/ENSNamesProvider';
import { RPCProvider } from './providers/RPCProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TronProvider } from './tron/providers/TronProvider';
import { ReferralIdInterceptor } from './components/ReferralIdInterceptor';
import { FuelProvider } from '@fuels/react';
import { FuelWalletConnector, FueletWalletConnector } from '@fuels/connectors';

const queryClient = new QueryClient();

export default function App() {
  return (
    <AppRouter>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={darkTheme()} locale="en">
            <StarknetConfig>
              <SolanaWalletProvider>
                <TonConnectUIProvider
                  walletsListConfiguration={{
                    includeWallets: additionalTONWallets,
                  }}
                  manifestUrl={tonManifestUrl}
                >
                  <TronProvider>
                    <FuelProvider
                      uiConfig={{ suggestBridge: false }}
                      fuelConfig={{
                        connectors: [
                          new FuelWalletConnector(),
                          new FueletWalletConnector(),
                        ],
                      }}
                    >
                      <WalletConnectInteractorProvider>
                        <ToastContainer className="toast-position" />
                        <InitialUserApiKey />
                        <ReferralIdInterceptor />
                        <TransactionTrackingNotification />
                        <ENSNamesProvider />
                        <RPCProvider />
                        <Suspense fallback={<FullPageLoader />}>
                          <Routes>
                            {getRoutes()}
                            <Route
                              path="*"
                              element={<Navigate replace to={HOME_PAGE} />}
                            />
                          </Routes>
                        </Suspense>
                        <GetParameterPopups />
                      </WalletConnectInteractorProvider>
                    </FuelProvider>
                  </TronProvider>
                </TonConnectUIProvider>
              </SolanaWalletProvider>
            </StarknetConfig>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </AppRouter>
  );
}
