import { SVGAttributes } from 'react';
import './styles.css';

export function LoaderSmall(props: SVGAttributes<SVGElement>) {
  return (
    <svg {...props} viewBox="0 0 110 110">
      <circle
        className="loader-ring"
        fill="transparent"
        strokeWidth="16px"
        stroke="#7C65EB"
        strokeDashoffset="276.460"
        strokeDasharray="276.460 276.460"
        strokeLinecap="round"
        cx="55"
        cy="55"
        r="44"
      />
    </svg>
  );
}
