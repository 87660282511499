import clsx from 'clsx';
import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { NetworkTypes } from '@/providers/web3Provider';
import { useAppStore } from '@/stores/app.store';
import { useWalletStore } from '@/stores/wallet.store';
import {
  getMinimalDecimalNumber,
  roundToMinNonZeroDecimals,
} from '@/utils/numbers';
import './styles.css';

export function FeeDetails() {
  const { width } = useWindowSize();
  const [transactionFee] = useAppStore(useShallow(s => [s.transactionFee]));

  const { NFTDiscount } = useWalletStore();
  const [networkFrom, networkTo, currencyTo] = useAppStore(
    useShallow(s => [s.networkFrom, s.networkTo, s.currencyTo])
  );

  const hasDiscount = !!NFTDiscount;

  const nftName = useMemo(() => {
    if (width <= 480 && NFTDiscount?.nftName) {
      return NFTDiscount.nftName.split(' ').slice(1).join(' ');
    }

    return NFTDiscount?.nftName;
  }, [NFTDiscount?.nftName, width]);

  const fee = useMemo(() => {
    return {
      total: roundToMinNonZeroDecimals(transactionFee?.full_fee ?? 0),
      platform: getMinimalDecimalNumber(transactionFee?.platform_fee ?? 0),
      swap: roundToMinNonZeroDecimals(transactionFee?.swap_fee ?? 0),
      swapFeeInUsd: roundToMinNonZeroDecimals(
        transactionFee?.swap_fee_in_usd ?? 0
      ),
      blockchain: roundToMinNonZeroDecimals(
        transactionFee?.blockchain_fee ?? 0
      ),
      blockhainFeeInUsd: roundToMinNonZeroDecimals(
        transactionFee?.blockchain_fee_in_usd ?? 0
      ),
      platformFeeInUSD: roundToMinNonZeroDecimals(
        transactionFee?.platform_fee_in_usd ?? 0
      ),
    };
  }, [transactionFee]);

  const isSourceEVM = useMemo(
    () =>
      networkFrom?.network_type === NetworkTypes.EVM ||
      networkFrom?.network_type === NetworkTypes.ZK_SYNC_ERA,
    [networkFrom?.network_type]
  );

  const isDestinationEVM = useMemo(
    () =>
      networkTo?.network_type === NetworkTypes.EVM ||
      networkTo?.network_type === NetworkTypes.ZK_SYNC_ERA,
    [networkTo?.network_type]
  );

  const shouldShowDiscount =
    hasDiscount &&
    isSourceEVM &&
    +(NFTDiscount?.networkFee ?? 0) !== +(fee?.platformFeeInUSD ?? 0);

  return (
    <div className="fee-details--container">
      <div className="fee-details--item">
        <div className="fee-details--label text-white">RetroBridge Fee</div>
        {hasDiscount && (isSourceEVM || isDestinationEVM) && (
          <div className="fee-details--nft-badge late">
            {nftName ?? 'Early Sunrise NFT'}
          </div>
        )}
        <div className="d-flex align-items-center gap-2 ms-auto">
          {shouldShowDiscount && (
            <span className="fee-details--value-usd text-decoration-line-through opacity-50">
              ${NFTDiscount?.networkFee}
            </span>
          )}
          <div
            className={clsx(
              'fee-details--value-usd',
              shouldShowDiscount && 'text-gradient-sunrise'
            )}
          >
            ${fee.platformFeeInUSD}
          </div>
          <div className="fee-details--value">
            {fee.platform} {currencyTo?.symbol}
          </div>
        </div>
      </div>

      {+fee.swap > 0 && (
        <div className="fee-details--item">
          <div className="fee-details--label">Swap Fee</div>
          <div className="d-flex align-items-center gap-2 ms-auto">
            <div className="fee-details--value-usd">${fee.swapFeeInUsd}</div>
            <div className="fee-details--value swap-fee-gradient">
              {fee.swap} {currencyTo?.symbol}
            </div>
          </div>
        </div>
      )}

      <div className="fee-details--item">
        <div className="fee-details--label">Destination Chain Fee</div>
        <div className="d-flex align-items-center gap-2 ms-auto">
          <div className="fee-details--value-usd">${fee.blockhainFeeInUsd}</div>
          <div className="fee-details--value">
            {fee.blockchain} {currencyTo?.symbol}
          </div>
        </div>
      </div>
    </div>
  );
}
