import {
  REFERRAL_COOKIE_KEY,
  REFERRAL_QUERY_KEY,
} from '@/constants/retroPoints.constants';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setCookie } from '../../utils/cookies';

export const ReferralIdInterceptor = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) return;

    const param = searchParams.get(REFERRAL_QUERY_KEY);

    if (param) {
      setCookie(REFERRAL_COOKIE_KEY, param);
      setSearchParams(params => {
        params.delete(REFERRAL_QUERY_KEY);
        return params;
      });
    }

    return () => {
      isFirstRender.current = false;
    };
  }, [searchParams]);

  return null;
};
