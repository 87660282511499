import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import { ArrowRight } from '@/Assets/Icons/ArrowRight';
import { TickIcon } from '@/Assets/Icons/Tick';
import { TickWithGradient } from '@/Assets/Icons/TickWithGradient';
import profileBackgroundPlaceholder from '@/Assets/images/quests/defaultLevel.png';
import profileBackgroundMobilePlaceholder from '@/Assets/images/quests/defaultLevelMobile.png';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import Footer from '@/components/Footer';
import { Header } from '@/components/Header';
import { RightArrow } from '@/components/TransactionTrackingNotification/icons/Rightarrow';
import { ROUTES } from '@/constants/routes.constants';
import { NetworkTypes } from '@/providers/web3Provider';
import { useRetroPointsStore } from '@/stores/retroPoints.store';
import { toFixed } from '@/utils/numbers';
import { calcCurrentLevel } from '@/utils/retroPoints';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useScrollLock, useWindowSize } from 'usehooks-ts';
import { useAccount } from 'wagmi';
import { RetroBridgerPointsIcon } from '../../../../Assets/Icons/RetroBrdigePoints';
import { levels } from '../../../../constants/quests/levels';
import { WalletPopover } from '../WalletPopover';
import profileImagePlaceholder from './avatar.png';
import './styles.css';

export function Sidebar() {
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(width <= 768);
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);
  const [isLevelsOpen, setIsLevelsOpen] = useState(true);
  const { isConnected, address } = useAccount();
  const [user] = useRetroPointsStore(s => [s.user]);

  const currentLevel = calcCurrentLevel(user?.total_bridges || 0);

  const profileBackground = useMemo(() => {
    if (!isConnected) {
      if (width < 768) {
        return profileBackgroundMobilePlaceholder;
      }
      return profileBackgroundPlaceholder;
    }
    if (width < 768) {
      return levels[currentLevel].profileBackgroundMobile;
    }
    return levels[currentLevel].profileBackground;
  }, [width, currentLevel, isConnected]);

  const profileImage = useMemo(() => {
    if (!isConnected) {
      return profileImagePlaceholder;
    }
    return levels[currentLevel].profileImage;
  }, [currentLevel, isConnected]);

  const { lock, unlock } = useScrollLock({
    autoLock: isOpen,
  });

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      lock();
      document.getElementById('quests-sidebar')?.scrollTo(0, 0);
    } else {
      unlock();
    }
  };

  return (
    <>
      {width <= 768 && (
        <button
          className="quests-sidebar--trigger"
          onClick={() => handleOpenChange(true)}
        >
          <ArrowRight style={{ transform: 'rotate(180deg)' }} />
          Overview
        </button>
      )}
      <motion.div
        animate={{
          opacity: isOpen || width >= 768 ? 1 : 0,
          pointerEvents: isOpen || width >= 768 ? 'auto' : 'none',
        }}
        transition={{ duration: 0.3 }}
        id="quests-sidebar"
        className={clsx('quests-sidebar', isConnected && 'wallet-connected')}
      >
        {width <= 768 && (
          <div className="position-relative z-1">
            <Header />
          </div>
        )}
        <div className="quests-profile">
          <div className="quests-profile--banner">
            <img src={profileBackground} draggable={false} alt="" />
          </div>
          <div className="quests-profile--image">
            <div className="quests-profile--image-bg left" />
            <div className="quests-profile--image-bg right" />
            <img src={profileImage} alt="" />
          </div>
        </div>
        <div>
          <div className="quests-profile--badge">
            {!isConnected && 'Multiply your points'}
            {isConnected && <>{user?.total_multiplicator || 1}x Multiplier</>}
          </div>
          <div className="quests-profile--level">
            {!isConnected && (
              <>
                Connect wallet to see
                <br /> your RetroBridger level
              </>
            )}
            {isConnected && address && levels[currentLevel].name} RetroBridger
          </div>
          <WalletPopover />
        </div>
        <div className="quests-profile--levels">
          {!isConnected && (
            <button
              className="btnGradient quests-profile--btn"
              onClick={() => setIsConnectDialogOpen(true)}
            >
              Connect wallet
            </button>
          )}
          {isConnected && currentLevel + 1 < levels.length && (
            <div className="quests-profile--progress">
              <div className="quests-profile--progress-bar">
                <div
                  className="quests-profile--progress-bar--filled"
                  style={{
                    width: `${((user?.total_bridges ?? 0) / levels[currentLevel].amountOfBridges) * 100}%`,
                  }}
                />
              </div>
              <div className="quests-profile--progress-hint">
                <span className="text-white">{user?.total_bridges ?? 0}</span>/
                {levels[currentLevel + 1]?.amountOfBridges} bridges to get
                additional{' '}
                <span className="text-gradient">
                  x{levels[currentLevel + 1]?.multiplier} Multiplier
                </span>
              </div>
            </div>
          )}
          {isConnected && currentLevel + 1 >= levels.length && (
            <div className="quests-profile--progress max-level">
              <div className="quests-profile--progress-bar">
                <div
                  className="quests-profile--progress-bar--filled"
                  style={{
                    width: `${100}%`,
                  }}
                />
              </div>
              <div className="quests-profile--progress-hint">
                <TickWithGradient />
                You’ve achieved maximum level
              </div>
            </div>
          )}
          {isConnected && (
            <div className="quests-profile--stats">
              <div className="quests-profile--stats-item">
                <div className="quests-profile--stats-label">Points Earned</div>
                <div className="quests-profile--stats-value">
                  <RetroBridgerPointsIcon />
                  {toFixed(user?.points || 0, 1)}
                </div>
              </div>
              <div className="quests-profile--stats-item">
                <div className="quests-profile--stats-label">
                  Bridges Completed
                </div>
                <div className="quests-profile--stats-value">
                  {toFixed(user?.total_bridges ?? 0, 2)}
                </div>
              </div>
            </div>
          )}
          {width <= 768 && (
            <button
              className="quests-profile--levels-trigger"
              onClick={() => setIsLevelsOpen(!isLevelsOpen)}
            >
              {!isLevelsOpen ? 'See' : 'Hide'} all levels{' '}
              <ArrowDownIcon
                color="#B4A7FF"
                style={{
                  transform: isLevelsOpen ? 'rotate(180deg)' : undefined,
                }}
              />
            </button>
          )}
          <AnimatePresence>
            {(isLevelsOpen || width > 768) &&
              levels.map((level, i) => (
                <motion.div
                  initial={{ opacity: 0, height: 0, marginTop: -24 }}
                  animate={{ opacity: 1, height: 'auto', marginTop: 0 }}
                  exit={{ opacity: 0, height: 0, marginTop: -24 }}
                  className="quests-profile--levels-item overflow-hidden"
                  key={i}
                >
                  <img
                    src={level.thumbnail}
                    alt={level.name}
                    className="quests-profile--levels-item-image"
                  />
                  <div className="d-flex flex-column justify-content-center gap-1">
                    <div className="quests-profile--levels-item-name">
                      {level.name}
                    </div>
                    {i === 0 ? (
                      <div className="quests-profile--levels-item-progress">
                        <span className="text-white">
                          less than {level.amountOfBridges} bridges
                        </span>
                      </div>
                    ) : (
                      <div className="quests-profile--levels-item-progress">
                        <span className="text-white">
                          {isConnected
                            ? Math.min(
                                user?.total_bridges ?? 0,
                                level.amountOfBridges
                              )
                            : 0}
                        </span>
                        /{level.amountOfBridges}+ bridges
                      </div>
                    )}
                  </div>
                  <div
                    className={clsx(
                      'quests-profile--levels-item-badge',
                      i <= currentLevel && isConnected && 'active'
                    )}
                  >
                    {i <= currentLevel && isConnected && (
                      <TickIcon color="#B2FFAB" />
                    )}
                    {level.multiplier}x Multiplier
                  </div>
                </motion.div>
              ))}
          </AnimatePresence>
        </div>
        <div className="quests-sidebar--links">
          <Link
            to={ROUTES.QUESTS_PAGE}
            className="quests-sidebar--links-item"
            onClick={() => handleOpenChange(false)}
          >
            Retro Points
            <RightArrow width={16} height={16} className="ms-auto" />
          </Link>
          <Link
            to={ROUTES.QUESTS_PAGE_PHASES}
            className="quests-sidebar--links-item"
            onClick={() => handleOpenChange(false)}
          >
            Phases
            <RightArrow width={16} height={16} className="ms-auto" />
          </Link>
          <Link
            to={ROUTES.QUESTS_PAGE_ADVENTURES}
            className="quests-sidebar--links-item"
            onClick={() => handleOpenChange(false)}
          >
            Adventures
            <RightArrow width={16} height={16} className="ms-auto" />
          </Link>
          <Link
            to={ROUTES.QUESTS_PAGE_ACTIVITIES}
            className="quests-sidebar--links-item"
            onClick={() => handleOpenChange(false)}
          >
            Activities{' '}
            <span className="quests-navbar--item-badge d-flex">
              free points
            </span>
            <RightArrow width={16} height={16} className="ms-auto" />
          </Link>
          <Link
            to={ROUTES.QUESTS_PAGE_MARKET}
            className="quests-sidebar--links-item"
            onClick={() => handleOpenChange(false)}
          >
            Retro Market
            <RightArrow width={16} height={16} className="ms-auto" />
          </Link>
        </div>
        <ConnectWalletModalWithDisabled
          openModal={isConnectDialogOpen}
          setOpenModal={() => setIsConnectDialogOpen(false)}
          networkType={!isConnected ? NetworkTypes.EVM : undefined}
          onlySelectedNetworkType={!isConnected}
          disabledStarknet={!!user?.starknet_address}
          disabledTON={!!user?.ton_address}
          disabledSolana={!!user?.solana_address}
          disabledTron={!!user?.tron_address}
        />
        {width <= 768 && (
          <div className="quests-layout-footer">
            <Footer />
          </div>
        )}
      </motion.div>
    </>
  );
}
