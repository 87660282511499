import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import './styles.css';
import { RightArrow } from '@/components/TransactionTrackingNotification/icons/Rightarrow';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/constants/routes.constants';
import { levels } from '@/constants/quests/levels';
import { useRetroPointsStore } from '@/stores/retroPoints.store';
import { calcCurrentLevel } from '@/utils/retroPoints';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import { useState } from 'react';
import { NetworkTypes } from '@/providers/web3Provider';
import { useAccount } from 'wagmi';
import RetroPointsLevels from '../assets/RetroPointsLevels.png';
import { RocketGradientIcon } from '@/Assets/Icons/RocketGradient';
import { TickWithGradient } from '@/Assets/Icons/TickWithGradient';

export function Profile() {
  const { isConnected } = useAccount();
  const [isOpen, setIsOpen] = useState(false);
  const [user] = useRetroPointsStore(s => [s.user]);
  const currentLevel = calcCurrentLevel(user?.total_bridges || 0);

  if (!user || !isConnected) {
    return (
      <>
        <div className="notifications-menu-profile--placeholder">
          <img
            src={RetroPointsLevels}
            className="notifications-menu-profile--placeholder-image"
            alt=""
          />
          <div className="notifications-menu-profile--placeholder-heading">
            Connect wallet to see your RetroBridge profile
          </div>
          <button
            className="btnGradient notifications-menu-profile--placeholder-action"
            onClick={() => setIsOpen(true)}
          >
            Connect Wallet
          </button>
        </div>
        <ConnectWalletModalWithDisabled
          openModal={isOpen}
          setOpenModal={setIsOpen}
          networkType={NetworkTypes.EVM}
          onlySelectedNetworkType
        />
      </>
    );
  }

  return (
    <>
      <div className="notifications-menu-profile">
        <div className="notifications-menu-profile--banner">
          <img src={levels[currentLevel].profileBackground} alt="" />
        </div>
        <div className="d-flex gap-3">
          <div className="notifications-menu-profile--image">
            <img
              src={levels[currentLevel].profileImage}
              width={86}
              height={86}
              alt=""
            />
          </div>
          <div>
            <div className="notifications-menu-profile--heading">
              {levels[currentLevel].name} Retrobridger
            </div>
            {currentLevel + 1 < levels.length && (
              <div className="d-flex flex-column gap-2">
                <div className="notifications-menu-profile--progress">
                  <div
                    className="notifications-menu-profile--progress-bar"
                    style={{
                      width: `${((user?.total_bridges ?? 0) / levels[currentLevel + 1].amountOfBridges) * 100}%`,
                    }}
                  />
                </div>
                <div className="notifications-menu-profile--hint">
                  <span className="text-white">{user?.total_bridges || 0}</span>
                  /{levels[currentLevel + 1]?.amountOfBridges} bridges to get
                  additional{' '}
                  <span className="text-gradient">
                    x{levels[currentLevel + 1]?.multiplier} Multiplier
                  </span>
                </div>
              </div>
            )}
            {currentLevel + 1 >= levels.length && (
              <div className="quests-profile--progress max-level">
                <div className="quests-profile--progress-bar">
                  <div
                    className="quests-profile--progress-bar--filled"
                    style={{
                      width: `${100}%`,
                    }}
                  />
                </div>
                <div className="quests-profile--progress-hint">
                  <TickWithGradient />
                  You’ve achieved maximum level
                </div>
              </div>
            )}
          </div>
        </div>
        <Link to={ROUTES.QUESTS_PAGE} className="ms-auto text-white">
          <RightArrow width={18} height={18} />
        </Link>
      </div>
      <div className="notifications-menu-stats">
        <div className="d-flex justify-content-center align-items-center gap-2">
          <RetroBridgerPointsIcon width={20} height={13} />
          <div className="d-flex flex-column align-items-center">
            <div className="notifications-menu-stats--value">
              {user?.points || 0}
            </div>
            <div className="notifications-menu-stats--label">points</div>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <RocketGradientIcon width={16} height={16} />
            <div className="d-flex flex-column align-items-center">
              <div className="notifications-menu-stats--value">
                {user?.total_multiplicator || 1}x
              </div>
              <div className="notifications-menu-stats--label">multiplier</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
