import clsx from 'clsx';
import styles from './styles.module.css';

interface AppModeSwitchProps {
  isAdvancedOpen: boolean;
  setIsAdvancedOpen: (isOpen: boolean) => void;
}

export function AppModeSwitch({
  isAdvancedOpen,
  setIsAdvancedOpen,
}: AppModeSwitchProps) {
  return (
    <div
      className={clsx(styles.container, isAdvancedOpen ? styles._2 : styles._1)}
    >
      <button
        className={styles.switchItem}
        onClick={() => setIsAdvancedOpen(false)}
      >
        Regular Bridge
      </button>
      <button
        className={styles.switchItem}
        onClick={() => setIsAdvancedOpen(true)}
      >
        Advanced Bridge
      </button>
    </div>
  );
}
