import scrollMasterBridgerBadge from '../assets/ScrollMasterBridger.png';
import scrollMasterSwapperBadge from '../assets/ScrollMasterSwapper.png';
import scrollBridgerBadge from '../assets/ScrollBridger.png';
import { OtherItem } from './OtherItem';
import './styles.css';

export function Other() {
  return (
    <div className="d-flex gap-3">
      <OtherItem
        backgroundSrc={require('../assets/ScrollBadgeBg1.jpg')}
        href="https://scroll.io/canvas/badge-contract/0x4F4d33693A0C454a87Dd39cf21171F0d585Af0bD"
        target="_blank"
      >
        <div className="notifications-menu--other-item--image">
          <img src={scrollMasterBridgerBadge} draggable={false} alt="" />
        </div>
      </OtherItem>
      <OtherItem
        backgroundSrc={require('../assets/ScrollBadgeBg2.jpg')}
        href="https://scroll.io/canvas/badge-contract/0x5cEDbA9376e447dd23f196bF66985c31fDFD8559"
        target="_blank"
      >
        <div className="notifications-menu--other-item--image">
          <img src={scrollMasterSwapperBadge} draggable={false} alt="" />
        </div>
      </OtherItem>
      <OtherItem
        backgroundSrc={require('../assets/ScrollBadgeBg3.jpg')}
        href="https://scroll.io/canvas/badge-contract/0x59700c6Ed282eD7611943129f226914ACBB3982b"
        target="_blank"
      >
        <div className="notifications-menu--other-item--image">
          <img src={scrollBridgerBadge} draggable={false} alt="" />
        </div>
      </OtherItem>
    </div>
  );
}
