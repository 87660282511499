import { isOneDomain } from '@/Config';
import { QuestsLayout } from '@/layouts/QuestsLayout';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { stage } from '../Config/ApiConfig';
import {
  ADVANCED_PROGRESS_PAGE,
  DAPP_EXPLORER_PAGE,
  DEV_API_PAGE,
  EXPLORER_PAGE,
  FAQ_PAGE,
  HOME_PAGE,
  PRIVACY_POLICY_PAGE,
  PROGRESS_PAGE,
  QUESTS_PAGE,
  QUESTS_PAGE_ACTIVITIES,
  QUESTS_PAGE_ADVENTURES,
  QUESTS_PAGE_MARKET,
  QUESTS_PAGE_PHASES,
  SEND_PAGE,
} from '../constants/routes.constants';
import { redirects } from '../constants/routes.redirects';
import ExplorerPage from '../pages/ExplorerPage';
import ProgressPage from '../pages/ProgressPage';
import SendPage from '../pages/SendPage';

const MainPage = lazy(() => import('@/pages/Main'));
const DappExplorer = lazy(() => import('@/pages/DappExplorerPage'));
const DevelopersApiPage = lazy(() => import('@/pages/DevelopersApiPage'));
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy'));
const FAQPage = lazy(() => import('@/pages/FAQ'));

const RetroPoints = lazy(() => import('@/pages/quests/RetroPoints'));
const QuestsPhases = lazy(() => import('@/pages/quests/QuestsPhases'));
const QuestsAdventure = lazy(() => import('@/pages/quests/QuestsAdventures'));
const QuestsActivities = lazy(() => import('@/pages/quests/QuestsActivities'));
const RetroMarket = lazy(() => import('@/pages/quests/QuestsRetroMarket'));

export const explorerRoutes = [
  'explorer.retrobridge.io',
  'explorer.retrobridge.dev',
];
export const bridgeRoutes = ['app.retrobridge.io', 'app.retrobridge.dev'];
export const landingRoutes = ['retrobridge.io', 'retrobridge.dev'];

export const getRoutes = () => {
  if (explorerRoutes.includes(window.location.host)) {
    return <Route path={HOME_PAGE} element={<ExplorerPage />} />;
  }

  if (bridgeRoutes.includes(window.location.host)) {
    return (
      <>
        <Route path={HOME_PAGE} element={<SendPage />} />
        <Route path={`${PROGRESS_PAGE}/:orderId`} element={<ProgressPage />} />
        <Route
          path={`${ADVANCED_PROGRESS_PAGE}/:orderId`}
          element={<ProgressPage isAdvancedTransfer />}
        />
      </>
    );
  }
  return (
    <>
      {(stage === 'development' || isOneDomain) && (
        <>
          <Route index path={SEND_PAGE} element={<SendPage />} />
          <Route path={EXPLORER_PAGE} element={<ExplorerPage />} />
          <Route
            path={`${PROGRESS_PAGE}/:orderId`}
            element={<ProgressPage />}
          />
          <Route
            path={`${ADVANCED_PROGRESS_PAGE}/:orderId`}
            element={<ProgressPage isAdvancedTransfer />}
          />
        </>
      )}

      <Route index path={HOME_PAGE} Component={MainPage} />

      <Route path={DAPP_EXPLORER_PAGE} Component={DappExplorer} />

      <Route path={DEV_API_PAGE} Component={DevelopersApiPage} />

      <Route path={PRIVACY_POLICY_PAGE} Component={PrivacyPolicy} />

      <Route path={FAQ_PAGE} Component={FAQPage} />

      <Route path={QUESTS_PAGE} element={<QuestsLayout />}>
        <Route index Component={RetroPoints} />
        <Route path={QUESTS_PAGE_PHASES} Component={QuestsPhases} />
        <Route path={QUESTS_PAGE_ADVENTURES} Component={QuestsAdventure} />
        <Route path={QUESTS_PAGE_ACTIVITIES} Component={QuestsActivities} />
        <Route path={QUESTS_PAGE_MARKET} Component={RetroMarket} />
      </Route>

      {redirects.map(({ from, to }) => (
        <Route key={from} path={from} element={<Navigate to={to} />} />
      ))}
    </>
  );
};
