import { SVGAttributes } from 'react';

export function ArrowUpIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 6L12.8971 10.5L5.10289 10.5L9 6Z" fill="white" />
    </svg>
  );
}
