import { create } from 'zustand';
import { INetwork } from '../types/apiTypes';

export interface NetworksStore {
  networks: INetwork[];
  setNetworks: (networks: INetwork[]) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const networksStore = create<NetworksStore>(set => ({
  networks: [],
  setNetworks: networks => set({ networks }),

  isLoading: false,
  setIsLoading: isLoading => set({ isLoading }),
}));

export const useNetworksStore = networksStore;
