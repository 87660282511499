import {
  UseAccountProps,
  UseAccountResult,
  useAccount,
} from '@starknet-react/core';
import { validateAndParseAddress } from 'starknet';

export function useStarknetAccount(args?: UseAccountProps): UseAccountResult {
  const account = useAccount(args);

  return {
    ...account,
    address: account?.address
      ? validateAndParseAddress(account?.address)
      : undefined,
  };
}
