import CloseIcon from '@/Assets/Icons/close-icon.svg';
import { NetworkTypes } from '@/providers/web3Provider';
import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  Root as DialogRoot,
} from '@radix-ui/react-dialog';
import {
  TabsContent,
  TabsList,
  Root as TabsRoot,
  TabsTrigger,
} from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import { useScrollLock, useWindowSize } from 'usehooks-ts';
import dialogBg from './assets/bg.png';
import { EVMOptions } from './options/EVMOptions';
import { SolanaOptions } from './options/SolanaOptions';
import { StarknetOptions } from './options/StarknetOptions';
import { TONOptions } from './options/TONOptions';
import { TronOptions } from './options/TronOptions';
import './styles.css';
import { useAccount as useEVMAccount } from 'wagmi';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { FuelOptions } from './options/FuelOptions';
import { useAccount as useFuelAccount } from '@fuels/react';

type ConnectWalletModalProps = {
  setOpenModal: (value: boolean) => void;
  openModal: boolean;
  networkType?: NetworkTypes | 'all';
  onlySelectedNetworkType?: boolean;
  onlyNonEVM?: boolean;
  onlyDisconnectedNetworkTypes?: boolean;

  disabledEVM?: boolean;
  disabledStarknet?: boolean;
  disabledSolana?: boolean;
  disabledTON?: boolean;
  disabledTron?: boolean;
  disabledFuel?: boolean;
};

export interface OptionsProps {
  onOpenChange: (value: boolean) => void;
}

export const ConnectWalletModalWithDisabled = ({
  openModal,
  setOpenModal,
  networkType = 'all',
  onlySelectedNetworkType,
  onlyNonEVM,
  onlyDisconnectedNetworkTypes,

  disabledEVM,
  disabledStarknet,
  disabledSolana,
  disabledTON,
  disabledTron,
  disabledFuel,
}: ConnectWalletModalProps) => {
  const { width } = useWindowSize();
  const targetRef = useRef<HTMLDivElement>(null);

  const { address: EVMAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const { wallet: solanaWallet } = useSolanaWallet();
  const tonAddress = useTonAddress();
  const { address: tronAddress } = useTronWallet();
  const { account: fuelAddress } = useFuelAccount();

  const { lock, unlock } = useScrollLock({
    autoLock: false,
  });

  const tab = useMemo(() => {
    if (networkType === NetworkTypes.ZK_SYNC_ERA) {
      return NetworkTypes.EVM;
    }
    return networkType;
  }, [networkType]);

  useEffect(() => {
    if (openModal) {
      lock();
    }
  }, [openModal]);

  const shouldBeVisible = (isConnected: boolean) => {
    return (
      (onlyDisconnectedNetworkTypes && !isConnected) ||
      !onlyDisconnectedNetworkTypes
    );
  };

  return (
    <DialogRoot open={openModal} onOpenChange={setOpenModal} modal={false}>
      <AnimatePresence
        onExitComplete={() => {
          unlock();
        }}
      >
        {openModal && (
          <DialogPortal forceMount>
            <DialogOverlay asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="connect-dialog--overlay"
              />
            </DialogOverlay>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="connect-dialog--overlay"
            >
              <DialogContent
                forceMount
                onOpenAutoFocus={e => e.preventDefault()}
                asChild
              >
                <motion.div
                  initial={width <= 768 ? { opacity: 0, y: '100%' } : undefined}
                  animate={width <= 768 ? { opacity: 1, y: '0%' } : undefined}
                  exit={width <= 768 ? { opacity: 1, y: '100%' } : undefined}
                  transition={{ duration: 0.2 }}
                  drag={width <= 768 ? 'y' : false}
                  dragSnapToOrigin
                  dragDirectionLock
                  dragConstraints={{ top: 0 }}
                  dragElastic={0.001}
                  dragPropagation
                  ref={targetRef}
                  onDragEnd={(_, info) => {
                    if (
                      info.offset.y >
                      (targetRef.current?.clientHeight ?? 0) / 3
                    ) {
                      setOpenModal(false);
                    }
                  }}
                  className="connect-dialog"
                >
                  <div className="connect-dialog--bg">
                    <img src={dialogBg} draggable={false} alt="" />
                  </div>
                  <DialogClose className="connect-dialog--close">
                    <img src={CloseIcon} alt="Close" draggable={false} />
                  </DialogClose>
                  <TabsRoot defaultValue={tab} className="connect-dialog--tabs">
                    <TabsList className="connect-dialog--tabs-list">
                      <TabsTrigger
                        value="all"
                        disabled={
                          onlySelectedNetworkType && networkType !== 'all'
                        }
                        className="connect-dialog--tabs-trigger"
                      >
                        All
                      </TabsTrigger>
                      {!onlyNonEVM && (
                        <TabsTrigger
                          value={NetworkTypes.EVM}
                          disabled={
                            (onlySelectedNetworkType &&
                              networkType !== NetworkTypes.EVM &&
                              networkType !== NetworkTypes.ZK_SYNC_ERA) ||
                            !shouldBeVisible(!!EVMAddress) ||
                            disabledEVM
                          }
                          className="connect-dialog--tabs-trigger"
                        >
                          EVM
                        </TabsTrigger>
                      )}
                      <TabsTrigger
                        value={NetworkTypes.FUEL}
                        disabled={
                          (onlySelectedNetworkType &&
                            networkType !== NetworkTypes.FUEL) ||
                          !shouldBeVisible(!!fuelAddress) ||
                          disabledFuel
                        }
                        className="connect-dialog--tabs-trigger"
                      >
                        Fuel
                      </TabsTrigger>
                      <TabsTrigger
                        value={NetworkTypes.STARKNET}
                        disabled={
                          (onlySelectedNetworkType &&
                            networkType !== NetworkTypes.STARKNET) ||
                          !shouldBeVisible(!!starknetAddress) ||
                          disabledStarknet
                        }
                        className="connect-dialog--tabs-trigger"
                      >
                        Starknet
                      </TabsTrigger>
                      <TabsTrigger
                        value={NetworkTypes.SOLANA}
                        disabled={
                          (onlySelectedNetworkType &&
                            networkType !== NetworkTypes.SOLANA) ||
                          !shouldBeVisible(!!solanaWallet) ||
                          disabledSolana
                        }
                        className="connect-dialog--tabs-trigger"
                      >
                        Solana
                      </TabsTrigger>
                      <TabsTrigger
                        value={NetworkTypes.TON}
                        disabled={
                          (onlySelectedNetworkType &&
                            networkType !== NetworkTypes.TON) ||
                          !shouldBeVisible(!!tonAddress) ||
                          disabledTON
                        }
                        className="connect-dialog--tabs-trigger"
                      >
                        TON
                      </TabsTrigger>
                      <TabsTrigger
                        value={NetworkTypes.TRON}
                        disabled={
                          (onlySelectedNetworkType &&
                            networkType !== NetworkTypes.TRON) ||
                          !shouldBeVisible(!!tronAddress) ||
                          disabledTron
                        }
                        className="connect-dialog--tabs-trigger"
                      >
                        Tron
                      </TabsTrigger>
                    </TabsList>
                    <SimpleBar
                      style={{
                        flexGrow: '1',
                        outline: 'none',
                      }}
                      className="connect-dialog--viewport"
                    >
                      <TabsContent
                        className="connect-dialog--tabs-content"
                        value={'all'}
                      >
                        {!onlyNonEVM &&
                          shouldBeVisible(!!EVMAddress) &&
                          !disabledEVM && (
                            <EVMOptions onOpenChange={setOpenModal} />
                          )}
                        {shouldBeVisible(!!fuelAddress) && !disabledFuel && (
                          <FuelOptions onOpenChange={setOpenModal} />
                        )}
                        {shouldBeVisible(!!starknetAddress) &&
                          !disabledStarknet && (
                            <StarknetOptions onOpenChange={setOpenModal} />
                          )}

                        {shouldBeVisible(!!solanaWallet) && !disabledSolana && (
                          <SolanaOptions onOpenChange={setOpenModal} />
                        )}
                        {shouldBeVisible(!!tonAddress) && !disabledTON && (
                          <TONOptions onOpenChange={setOpenModal} />
                        )}
                        {shouldBeVisible(!!tronAddress) && !disabledTron && (
                          <TronOptions onOpenChange={setOpenModal} />
                        )}
                      </TabsContent>
                      {!onlyNonEVM && (
                        <TabsContent
                          className="connect-dialog--tabs-content"
                          value={NetworkTypes.EVM}
                        >
                          <EVMOptions onOpenChange={setOpenModal} />
                        </TabsContent>
                      )}
                      <TabsContent
                        className="connect-dialog--tabs-content"
                        value={NetworkTypes.FUEL}
                      >
                        <FuelOptions onOpenChange={setOpenModal} />
                      </TabsContent>
                      <TabsContent
                        className="connect-dialog--tabs-content"
                        value={NetworkTypes.STARKNET}
                      >
                        <StarknetOptions onOpenChange={setOpenModal} />
                      </TabsContent>
                      <TabsContent
                        className="connect-dialog--tabs-content"
                        value={NetworkTypes.SOLANA}
                      >
                        <SolanaOptions onOpenChange={setOpenModal} />
                      </TabsContent>
                      <TabsContent
                        className="connect-dialog--tabs-content"
                        value={NetworkTypes.TON}
                      >
                        <TONOptions onOpenChange={setOpenModal} />
                      </TabsContent>
                      <TabsContent
                        className="connect-dialog--tabs-content"
                        value={NetworkTypes.TRON}
                      >
                        <TronOptions onOpenChange={setOpenModal} />
                      </TabsContent>
                    </SimpleBar>
                  </TabsRoot>
                </motion.div>
              </DialogContent>
            </motion.div>
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
};
