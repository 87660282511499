import { getChecksumAddress } from 'starknet';

export const isValidStarknetAddress = (address: string): boolean => {
  try {
    const checksum = getChecksumAddress(address);
    const isValidChecksum = checksum.toLowerCase() === address.toLowerCase();

    return isValidChecksum;
  } catch (e) {
    return false;
  }
};
