import { PublicKey } from '@solana/web3.js';

export const isValidSolAddress = (address: string) => {
  try {
    const publicKey = new PublicKey(address);
    return PublicKey.isOnCurve(publicKey.toBytes());
  } catch (error) {
    return false;
  }
};
