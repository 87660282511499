import { Wallet, getWalletConnectConnector } from '@rainbow-me/rainbowkit';

export interface MyWalletOptions {
  projectId: string;
}

export const tobiFunWallet = ({ projectId }: MyWalletOptions): Wallet => ({
  id: 'tobiFun',
  name: 'Tobi Fun',
  iconUrl: `https://explorer-api.walletconnect.com/v3/logo/md/3cd2d066-56f7-4272-4a98-b39b41ea8200?projectId=${projectId}`,
  iconBackground: '#fffff',
  downloadUrls: {
    android: 'hhttps://t.me/TobiWalletBot',
    ios: 'https://t.me/TobiWalletBot',
    qrCode: 'https://www.tobi.fun/',
  },
  qrCode: {
    getUri: (uri: string) => uri,
  },
  createConnector: getWalletConnectConnector({ projectId }),
});
