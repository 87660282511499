import { SVGAttributes } from 'react';

export function ArrowDownIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 9.33333L3.96891 5.83333L10.0311 5.83333L7 9.33333Z"
        fill="white"
      />
    </svg>
  );
}
