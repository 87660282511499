import { Root, SwitchProps, Thumb } from '@radix-ui/react-switch';
import clsx from 'clsx';
import { forwardRef } from 'react';
import './styles.css';

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ className, ...props }, ref) => {
    return (
      <Root className={clsx('switch', className)} ref={ref} {...props}>
        <Thumb className="switch-thumb" />
      </Root>
    );
  }
);
