import { ArrowRight } from '@/Assets/Icons/ArrowRight';
import { Tooltip } from '@/components/tooltips';
import { useBalance } from '@/hooks/useBalance';
import { useWalletFrom } from '@/hooks/useWalletFrom';
import { api } from '@/services/api';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import { useAppStore } from '@/stores/app.store';
import { UnsupportedContracts } from '@/types/apiTypes';
import { getBlockscanRoutes } from '@/utils/getBlockscanRoutes';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { memo, useEffect, useMemo, useState } from 'react';
import './styles.css';
import { formatAddress } from '@/utils';

export const USDCNativeBalance = memo(() => {
  const [unsupportedContracts, setUnsupportedContracts] = useState<
    UnsupportedContracts[]
  >([]);
  const [hasNativeBalance, setHasNativeBalance] = useState(false);

  const [currencyFrom, networkFrom, isAdvancedOpen] = useAppStore(s => [
    s.currencyFrom,
    s.networkFrom,
    s.isAdvancedOpen,
  ]);
  const [advancedCurrencyFrom, advancedNetworkFrom] = useAdvancedWalletStore(
    s => [s.currencyFrom, s.networkFrom]
  );

  const { isFromConnected } = useWalletFrom(
    isAdvancedOpen ? networkFrom : advancedNetworkFrom
  );

  const {
    balance: sourceBalance,
    loading: isSourceBalanceLoading,
    fetchBalanceCallback,
  } = useBalance(isAdvancedOpen ? advancedCurrencyFrom : currencyFrom);

  useEffect(() => {
    api.getUnsupportedContracts().then(async res => {
      setUnsupportedContracts(res);
      setHasNativeBalance(false);
    });
  }, []);

  useEffect(() => {
    setHasNativeBalance(false);
    if (!isFromConnected || !fetchBalanceCallback) return;

    const token = isAdvancedOpen ? advancedCurrencyFrom : currencyFrom;
    const network = token?.contract.network;
    const decimals = token?.decimals;

    if (
      !network ||
      !token ||
      unsupportedContracts.length <= 0 ||
      token.symbol !== 'USDC'
    )
      return;

    const selectedNetworkContracts = unsupportedContracts.find(
      item => item.id === network?.id
    );

    if (!selectedNetworkContracts) return;

    const checkNativeBalances = async () => {
      for (let i = 0; i < selectedNetworkContracts.contracts.length; i++) {
        const contractAddress = selectedNetworkContracts.contracts[i];

        console.log(network, contractAddress, currencyFrom);

        const balance = await fetchBalanceCallback(
          network,
          contractAddress,
          decimals,
          false,
          false
        );

        console.log('balance', balance);

        if (+balance > 0.001) {
          setHasNativeBalance(true);
          return;
        }
      }
    };

    checkNativeBalances();
  }, [
    isAdvancedOpen,
    isFromConnected,
    advancedCurrencyFrom,
    currencyFrom,
    unsupportedContracts,
    fetchBalanceCallback,
  ]);

  const isNativeBalanceDetected = useMemo(() => {
    if (unsupportedContracts.length <= 0) return false;

    if (!isAdvancedOpen && currencyFrom?.symbol !== 'USDC') return false;
    if (isAdvancedOpen && advancedCurrencyFrom?.symbol !== 'USDC') return false;

    const selectedNetworkContracts = unsupportedContracts.find(item =>
      isAdvancedOpen
        ? item.id === advancedNetworkFrom?.id
        : item.id === networkFrom?.id
    )?.contracts;

    if (!selectedNetworkContracts || selectedNetworkContracts.length <= 0)
      return false;

    if (
      (isFromConnected && !isSourceBalanceLoading && +sourceBalance > 0.001) ||
      (isFromConnected && isSourceBalanceLoading)
    )
      return false;

    return hasNativeBalance;
  }, [
    networkFrom,
    advancedNetworkFrom,
    currencyFrom,
    advancedCurrencyFrom,
    unsupportedContracts,
    isAdvancedOpen,
    isFromConnected,
    isSourceBalanceLoading,
    sourceBalance,
    hasNativeBalance,
  ]);

  const blockScanRoutes = getBlockscanRoutes(
    (isAdvancedOpen
      ? advancedNetworkFrom?.blockscan_url
      : networkFrom?.blockscan_url) || ''
  );

  const contractAddress = isAdvancedOpen
    ? advancedCurrencyFrom?.contract.address
    : currencyFrom?.contract.address;

  return (
    <AnimatePresence>
      {isNativeBalanceDetected && (
        <motion.div
          initial={{ opacity: 0, height: 0, marginTop: 0, padding: '0px 12px' }}
          animate={{
            opacity: 1,
            height: 27,
            marginTop: 8,
            padding: '4px 12px',
          }}
          exit={{ opacity: 0, height: 0, marginTop: 0, padding: '0px 12px' }}
          className="native-balance-badge"
        >
          USDC Native Balance Detected
          <Tooltip
            variant="dark-2"
            trigger="?"
            contentProps={{
              className: clsx(
                'native-balance-tooltip',
                isAdvancedOpen && 'advanced-open'
              ),
              avoidCollisions: true,
              collisionPadding: 16,
              alignOffset: -87,
            }}
          >
            Bridging available for (Native) USDC <br />
            Please ensure you have it on your balance
            <a
              href={blockScanRoutes.address + contractAddress}
              target="_blank"
              rel="noopener noreferrer"
              className="native-balance-tooltip--link"
            >
              Contract address: {formatAddress(contractAddress || '', 5, 3)}
            </a>
            <a
              href="https://docs.retrobridge.io/retrobridge-app/networks-and-tokens"
              target="_blank"
              rel="noopener noreferrer"
              className="native-balance-tooltip--action"
            >
              Read more
              <ArrowRight width={11} height={11} color="#E5C4FF" />
            </a>
          </Tooltip>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
