import { useNotify } from '@/hooks/useToast';
import {
  NetworkTypes,
  useWalletConnectInteractor,
} from '@/providers/web3Provider';
import { starknetWallets } from '@/starknet/constants/wallets.constant';
import { getStoreVersion } from '@/starknet/utils/getStoreVersion';
import { logInGroup } from '@/utils';
import { Connector, useConnect } from '@starknet-react/core';
import { getStarknet } from 'get-starknet-core';
import { OptionsProps } from '..';

const starknet = getStarknet();

export function StarknetOptions({ onOpenChange }: OptionsProps) {
  const { connectors } = useConnect();
  const { handleActivate } = useWalletConnectInteractor();
  const { notify } = useNotify();

  const connectStarknet = async (provider: Connector) => {
    const available = provider.available();

    if (!available) {
      const storeVer = getStoreVersion();
      if (storeVer) {
        const wallet = (
          await starknet.getDiscoveryWallets({
            include: [provider.id],
          })
        )[0];

        const download = (wallet.downloads as Record<string, string>)[storeVer];

        if (download) {
          window.open(download);
          return;
        } else {
          notify({
            meassage: `Your browser doesn't support ${provider.name} wallet extension`,
            type: 'error',
          });
          return;
        }
      }
      notify({
        meassage: `Please install ${provider.name} extension before use`,
        type: 'info',
      });
      return;
    }
    await handleActivate(NetworkTypes.STARKNET, provider as any);

    onOpenChange(false);
  };

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">Starknet wallets</div>
      <div className="connect-wallet--group-grid">
        {starknetWallets.map((item, i) => (
          <button
            className="connect-wallet--group-item"
            key={item.id}
            onClick={async () => {
              try {
                const connector = connectors.find(
                  connector => connector.id === item.id
                );

                if (!connector) return;

                await connectStarknet(connector);
              } catch (error) {
                if (error instanceof Error) {
                  logInGroup('[Connect wallet]', error.message);
                }
              }
            }}
          >
            <img
              src={item.icon}
              style={{ height: '24px', width: '24px' }}
              alt=""
            />
            {item.name}
          </button>
        ))}
      </div>
    </div>
  );
}
