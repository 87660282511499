import { CopyIcon } from '@/Assets/Icons/CopyIcon';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import { TickIcon } from '@/Assets/Icons/Tick';
import metaMask from '@/Assets/images/wallets/metaMask.png';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import './styles.css';
import { formatAddress } from '@/utils';

interface ReferralToastProps {
  referralAddress: string;
}

export function ReferralToast({ referralAddress }: ReferralToastProps) {
  const [copiedText, copy] = useCopyToClipboard(3000);

  return (
    <div className="quest-toast">
      <div className="quest-toast--heading">Welcome to RetroPoints</div>
      <div className="quest-toast--text">
        You received{' '}
        <RetroBridgerPointsIcon width={19} height={19} className="align-top" />{' '}
        15 free points for the referral link
      </div>
      <div className="quest-toast--referral-label">Your referral:</div>
      <div className="quest-toast--referral-value">
        <img src={metaMask} alt="" />
        {formatAddress(referralAddress, 3, 4)}
        <button
          className="quest-toast--referral-value-copy"
          disabled={copiedText === referralAddress}
          onClick={() => copy(referralAddress)}
        >
          {copiedText === referralAddress ? (
            <TickIcon width={14} height={14} color="#fff" />
          ) : (
            <CopyIcon width={14} height={14} color="#fff" />
          )}
        </button>
      </div>
    </div>
  );
}
