import { Contract, uint256 } from 'starknet';

import { getStarknetProvider } from './getStarknetProvider';
import { balanceSchema, decimalsSchema } from '../hooks/balanceSchema';
import { balanceABIFragment } from '../hooks';

export const getStarknetBalance = async (
  walletAddress: string,
  rpc_url: string,
  is_rpc: boolean,
  currencyAddress?: string,
  currencyDecimals?: number
) => {
  if (!rpc_url || !currencyAddress || !currencyDecimals) {
    return '0';
  }

  const provider = getStarknetProvider(rpc_url, is_rpc);
  const contract = new Contract(balanceABIFragment, currencyAddress, provider);
  const [balance, decimals] = await Promise.all([
    contract.call('balanceOf', [walletAddress], {
      parseResponse: true,
    }),
    contract.call('decimals', []),
  ]);

  const parsedBalance = balanceSchema.parse(balance);
  const parsedDecimals = decimalsSchema.parse(decimals);

  const balanceAsBN = uint256.uint256ToBN(parsedBalance.balance);
  const formatted = (
    Number(balanceAsBN.toString()) /
    10 ** (currencyDecimals || Number(parsedDecimals))
  ).toString();

  return formatted;
};
