import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import discord from '@/Assets/Icons/discord.svg';
import plusOutline from '@/Assets/Icons/plus-outline.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { ROUTES } from '@/constants/routes.constants';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { BulkOrderStatuses } from '@/types/enums';
import { PopoverAnchor, PopoverTrigger } from '@radix-ui/react-popover';
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NetworkInfo } from '../../../ProgressPage/components/NetworkInfo';
import { useAdvancedProgressStore } from '../../store';
import { AdvancedProgress } from './Progress';
import { ProgressOrdersPopover } from './ProgressOrderPopover';
import './styles.css';

export function ProgressView() {
  const { prices } = usePriceUpdater();
  const [isOpen, setIsOpen] = useState(false);
  const [currentOrder] = useAdvancedProgressStore(s => [s.currentOrder]);

  const completedAndCurrentOrders = useMemo(() => {
    return currentOrder?.bulk_order_schedules.filter(
      order =>
        order.status === BulkOrderStatuses.COMPLETE ||
        order.status === BulkOrderStatuses.PROCESSING
    );
  }, [currentOrder?.bulk_order_schedules]);

  const activeOrder = useMemo(() => {
    if (!currentOrder?.bulk_order_schedules.length) return null;

    let activeOrder = null;

    for (let i = 0; i < currentOrder?.bulk_order_schedules.length; i++) {
      const order = currentOrder?.bulk_order_schedules?.[i];

      if (order.status === BulkOrderStatuses.PROCESSING) {
        activeOrder = order;
        break;
      }
    }

    return activeOrder;
  }, [currentOrder?.bulk_order_schedules]);

  const amountUSDIn = useMemo(() => {
    if (!currentOrder?.order.currency_in.symbol) {
      return 0;
    }

    return (
      getPrice(currentOrder.order.currency_in.symbol, prices).usd *
      +currentOrder.order.amount_in
    );
  }, [
    currentOrder?.order.currency_in.symbol,
    currentOrder?.order.amount_in,
    prices,
  ]);

  const amountUSDOut = useMemo(() => {
    if (
      !currentOrder?.receiving_data[0].currency.symbol ||
      currentOrder?.receiving_data.length <= 0
    ) {
      return 0;
    }

    return (
      getPrice(currentOrder?.receiving_data[0].currency.symbol, prices).usd *
      +currentOrder?.receiving_data[0].amount_out
    );
  }, [currentOrder?.receiving_data, prices]);

  if (!currentOrder) return null;

  const activeOrderIndex = Math.min(
    Math.max(0, (completedAndCurrentOrders?.length ?? 0) - 1),
    currentOrder.receiving_data.length - 1
  );

  return (
    <div className="formBg defaultRadius formContainer mt-0 send-advanced-form send-advanced-form--progress send-advanced-progress-form">
      <div className="d-flex justify-content-center align-items-center w-100">
        <Form className="transitionHeight formBody text-lightbg-opacity-25 d-flex h-100 flex-column defaultRadius align-items-center statusForm w-100 text-white">
          <div className="transferInfoTitle">Transfer Status</div>
          <div className="transactionInfoDableDiv">
            <div className="infoDiv-label">From</div>
            <div className="infoDiv info-div-padding progress-orders-menu--anchor me-auto">
              <NetworkInfo
                netwSrc={
                  BASE_URL +
                  currentOrder?.order.currency_in.contract.network
                    .network_image_url
                }
                netw={
                  currentOrder.order.currency_in.contract.network.name ?? ''
                }
                wallet={currentOrder.wallet_sender!}
                amount={`${currentOrder.order.amount_in}`}
                amountUsd={`${amountUSDIn}`}
                coinSimbol={currentOrder.order.currency_in.symbol ?? ''}
                imgSrc={BASE_URL + currentOrder.order.currency_in.image_url}
              />
            </div>
          </div>
          <div className="transactionInfoDableDiv mt-2">
            <div className="infoDiv-label">
              To{' '}
              <span className="text-white">
                ({completedAndCurrentOrders?.length ?? 0}/
                {currentOrder.receiving_data.length})
              </span>
            </div>
            <ProgressOrdersPopover isOpen={isOpen} onOpenChange={setIsOpen}>
              <div className="d-flex align-items-center w-100 position-relative">
                <PopoverAnchor
                  className="infoDiv info-div-padding progress-orders-menu--anchor"
                  data-state={isOpen ? 'open' : 'closed'}
                >
                  {!!activeOrder && (
                    <NetworkInfo
                      netwSrc={
                        BASE_URL +
                        activeOrder.currency_out.contract.network
                          .network_image_url
                      }
                      netw={
                        activeOrder.currency_out.contract.network.name ?? ''
                      }
                      wallet={activeOrder.wallet_receiver!}
                      amount={`${activeOrder.amount_out}`}
                      amountUsd={`${amountUSDOut}`}
                      coinSimbol={activeOrder?.currency_out.symbol ?? ''}
                      imgSrc={BASE_URL + activeOrder.currency_out.image_url}
                    />
                  )}
                  {!activeOrder && (
                    <NetworkInfo
                      netwSrc={
                        BASE_URL +
                        currentOrder.receiving_data[activeOrderIndex].currency
                          .contract.network.network_image_url
                      }
                      netw={
                        currentOrder.receiving_data[activeOrderIndex].currency
                          .contract.network.name ?? ''
                      }
                      wallet={
                        currentOrder.receiving_data[activeOrderIndex]
                          .wallet_receiver!
                      }
                      amount={`${currentOrder.receiving_data[activeOrderIndex].amount_out}`}
                      amountUsd={`${amountUSDOut}`}
                      coinSimbol={
                        currentOrder.receiving_data[activeOrderIndex].currency
                          .symbol
                      }
                      imgSrc={
                        BASE_URL +
                        currentOrder.receiving_data[activeOrderIndex].currency
                          .image_url
                      }
                    />
                  )}
                </PopoverAnchor>
                <PopoverTrigger
                  type="button"
                  className="progress-orders-menu--trigger"
                  aria-label="See all transactions"
                >
                  <ArrowDownIcon />
                </PopoverTrigger>
              </div>
            </ProgressOrdersPopover>
          </div>
          <hr className="advanced-transfer-divider w-100" />
          <div className="w-100">
            <div className="progressStatusText">Advanced Transfer Status</div>
            <div className="progressStatusText progressStatusText-regular textSecondary">
              Assets will be sent as soon as advanced transfer is confirmed
            </div>
          </div>
          <AdvancedProgress order={currentOrder} />
          <div className="progressActionWrapper w-100">
            <Link
              to={ROUTES.SEND_PAGE + '?mode=advanced'}
              className="d-flex justify-content-center align-items-center btn btnGradient w-100"
              state={{
                shouldOpenAdvanced: true,
              }}
            >
              <img src={plusOutline} alt="" />
              Create Another Advanced Transfer
            </Link>
          </div>
          <div className="progressActionWrapper mt-3">
            <a
              href="http://discord.gg/retrobridge"
              target="_blank"
              rel="noopener noreferrer"
              className="progressAction"
            >
              <img src={discord} alt="" />
              Support
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
}
