import { ensStore } from '@/stores/ens.store';
import { INetwork, ISimpleApiResp } from '@/types/apiTypes';
import { logInGroup } from '@/utils';
import { BASE_URL } from '../Config/ApiConfig';
import { ApiService as Api } from './CoreApi';

export class ENSService {
  private _api: Api;
  constructor() {
    this._api = new Api(BASE_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    this._api.instance.defaults.headers.put['Content-Type'] =
      'application/json';
  }

  async getENSNames(address: string, networkName: INetwork['name']) {
    const { setIsLoading, setDomains, setDefaultDomain, defaultDomain } =
      ensStore.getState();
    setIsLoading(true);
    try {
      const url = `/api/wallet_auth/domain_names/${address}/${networkName}`;
      const data = await this._api.get<ISimpleApiResp<string[]>>(url);

      setDomains(networkName, data.data);
      const selectedDomain = defaultDomain[networkName];
      if (!selectedDomain || !data.data.includes(selectedDomain)) {
        setDefaultDomain(networkName, data.data[0]);
      }

      return data.data;
    } catch (error) {
      logInGroup('', error);
    } finally {
      setIsLoading(false);
    }
  }
}

export const ensService = new ENSService();
