import { bulkApi } from '../../../../services/BulkApi';
import { api } from '../../../../services/api';
import { walletStore } from '../../../../stores/wallet.store';
import { OrderSearchStatuses } from '../../../../types/enums';
import { myHistoryPopoverStore } from './store';

export async function fetchOrders(
  address?: string,
  isRefetching: boolean | undefined = false
) {
  if (!address) return;
  try {
    const { setRecentOrders, setActiveOrders } =
      myHistoryPopoverStore.getState();
    const { addOngoingOrder, setOngoingOrders } = walletStore.getState();

    const activeOrders = await api.getOrders(
      OrderSearchStatuses.IN_PROGRESS,
      '',
      [address],
      false,
      3
    );
    const activeBulkOrders = await bulkApi.getOrders(
      OrderSearchStatuses.IN_PROGRESS,
      '',
      address,
      3
    );
    const recentOrders = await api.getOrders(
      OrderSearchStatuses.COMPLETE,
      '',
      [address],
      false,
      3
    );
    const recentBulkOrders = await bulkApi.getOrders(
      OrderSearchStatuses.COMPLETE,
      '',
      address,
      3
    );

    const active = [...(activeOrders ?? []), ...(activeBulkOrders ?? [])].sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    const recent = [...(recentOrders ?? []), ...(recentBulkOrders ?? [])].sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    setRecentOrders(recent?.slice(0, 3) ?? []);
    setActiveOrders(active?.slice(0, 3) ?? []);
    if (isRefetching) {
      active?.forEach(order => {
        addOngoingOrder(order);
      });
    } else {
      setOngoingOrders(active ?? []);
    }
  } catch (error) {
    console.warn(error);
  }
}
