import { wagmiConfig } from '@/providers/rainbow';
import { WalletButton } from '@rainbow-me/rainbowkit';
import { OptionsProps } from '..';
import { useAccountEffect } from 'wagmi';
import { RainbowKitDetails } from 'node_modules/@rainbow-me/rainbowkit/dist/wallets/Wallet';
import { useMemo } from 'react';

export function EVMOptions({ onOpenChange }: OptionsProps) {
  useAccountEffect({
    onConnect: () => {
      onOpenChange(false);
    },
  });

  const wallets = useMemo(() => {
    return wagmiConfig?.connectors.filter(el => 'rkDetails' in el);
  }, []);

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">EVM wallets</div>
      <div className="connect-wallet--group-grid">
        {wallets?.slice(0, wallets.length - 1)?.map((item, i) => (
          <WalletButton.Custom
            key={i}
            wallet={(item?.rkDetails as RainbowKitDetails)?.id}
          >
            {({ connect, connector }) => {
              return (
                <button
                  onClick={
                    connector?.id === 'walletConnect'
                      ? connector?.showWalletConnectModal
                      : connect
                  }
                  className="connect-wallet--group-item"
                >
                  <img
                    src={require(
                      `@/Assets/images/wallets/${(item?.rkDetails as RainbowKitDetails)?.id}.png`
                    )}
                    style={{ height: '24px', width: '24px' }}
                    alt=""
                  />
                  {(item?.rkDetails as RainbowKitDetails).name}
                </button>
              );
            }}
          </WalletButton.Custom>
        ))}
      </div>
    </div>
  );
}
