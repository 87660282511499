import { OrderStatuses } from '@/types/enums';
import { useMemo } from 'react';
import '../../Explorer.css';

export const StatusBtn = ({ status }: { status: OrderStatuses }) => {
  const getStatusColor = useMemo(() => {
    switch (status) {
      case OrderStatuses.COMPLETE:
        return 'greenStatus';
      case OrderStatuses.REJECTED:
      case OrderStatuses.SEND_FAILED:
      case OrderStatuses.EXPIRED:
      case OrderStatuses.DEPOSIT_FAILED:
        return 'redStatus';

      default:
        return 'orangeStatus';
    }
  }, [status]);
  return (
    <div className={` my-auto statusbtnContainer ${getStatusColor}`}>
      {status === OrderStatuses.COMPLETE ? status.toLowerCase() : 'pending'}
    </div>
  );
};
