import { useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import checkGreen from '@/Assets/Icons/checkGreen.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { ROUTES } from '@/constants/routes.constants';
import { isDevelopment } from '@/services/api';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { toFixed } from '@/utils/numbers';
import { NetworkInfo } from '../../../ProgressPage/components/NetworkInfo';
import { useAdvancedProgressStore } from '../../store';
import { OrderItem } from './TransactionItem';
import './styles.css';
import { useWindowSize } from 'usehooks-ts';

export function SuccessView() {
  const currentOrder = useAdvancedProgressStore(s => s.currentOrder);
  const navigate = useNavigate();
  const { prices } = usePriceUpdater();
  const { width } = useWindowSize();

  const usdPrice = useMemo(() => {
    if (!currentOrder?.order.currency_in.symbol) {
      return 0;
    }

    return getPrice(currentOrder.order.currency_in.symbol, prices).usd;
  }, [currentOrder?.order.currency_in.symbol, prices]);

  if (!currentOrder) return null;

  const onBtnClick = async () => {
    if (isDevelopment) {
      navigate(`${ROUTES.SEND_PAGE}?mode=advanced`);
    } else {
      window.open(`${ROUTES.SEND_PAGE}?mode=advanced`, '_self');
    }
  };

  return (
    <div className="formBg defaultRadius formContainer  mt-0 send-advanced-form send-advanced-progress-form">
      <div className="d-flex justify-content-center align-items-center">
        <Form className="transitionHeight formBody text-lightbg-opacity-25 d-flex h-100 flex-column defaultRadius align-items-center statusForm w-100">
          <div className="greenCircle">
            <img src={checkGreen} alt="" className="greenCheck" />
          </div>

          <div className="statusTitle">Transfer Completed</div>
          <div className="statusSubtitle">
            <span>Your bridge was successfully completed.</span>
            <br />
            <span>
              {toFixed(+currentOrder.order.amount_in ?? 0, 5)}{' '}
              {currentOrder.order.currency_in.symbol} has been sent to your
              addresses
            </span>
          </div>

          <div className="transactionInfoTableDiv w-100">
            <div className="infoDiv-label">From</div>
            <div className="infoDiv">
              <NetworkInfo
                netwSrc={
                  BASE_URL +
                  currentOrder.order.currency_in.contract.network
                    .network_image_url
                }
                netw={
                  currentOrder.order.currency_in.contract.network.name ?? ''
                }
                wallet={currentOrder.wallet_sender!}
                amount={`${currentOrder.order.amount_in}`}
                amountUsd={`${+currentOrder.order.amount_in * usdPrice}`}
                coinSimbol={currentOrder.order.currency_in.symbol ?? ''}
                imgSrc={BASE_URL + currentOrder.order.currency_in.image_url}
              />
            </div>
          </div>

          <div className="advanced-transfer-table">
            {width > 746 && (
              <div className="advanced-transfer-table--header">
                <div />
                <div className="advanced-transfer-table--header-item">
                  Source Network
                </div>
                <div className="advanced-transfer-table--header-item">
                  Destination Network
                </div>
                <div className="advanced-transfer-table--header-item">
                  Status
                </div>
                <div className="advanced-transfer-table--header-item">
                  Amount to Receive
                </div>
              </div>
            )}

            <SimpleBar
              style={{
                maxHeight: '290px',
                marginRight: '-24px',
                paddingRight: '24px',
                height: '100%',
              }}
              id="receiving-viewport"
            >
              <div className="advanced-transfer-table--body">
                {currentOrder.bulk_order_schedules.map((order, i) => (
                  <OrderItem
                    key={i}
                    i={i}
                    order={order}
                    currencyIn={currentOrder.order.currency_in}
                    walletSender={currentOrder.wallet_sender}
                  />
                ))}
              </div>
            </SimpleBar>
          </div>
          <div className="transfer-btn-group w-100 d-grid grid-cols-1 gap-2 m-0">
            <Button
              onClick={onBtnClick}
              variant="outline-secondary"
              className="btn btnSecondary w-100 m-0 btnGradient"
            >
              Create Another Advanced Transfer
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
